import {preload} from '@autocut/types/ElectronPreload';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import axios from 'axios';

import json from '../../package.json';
import {AutoCutApiError, TAutoCutApiError} from './errors/AutoCutApiError';

export let accessTokensModule: {
  accessToken: string;
  refreshToken: string;
};
let areInterceptorsInitialized = false;

const autocutApi = axios.create({
  headers: {
    'AutoCut-Client': `Extension-${json.version}`,
  },
});

export const initApi = () => {
  const apiURL =
    preload().electron.getSharedVariables?.()?.API_URL ||
    'https://api.autocut.fr'; //TEMP fix to handle the update of AEA when getSharedVariables has been added

  autocutApi.defaults.baseURL = apiURL;
};

export const initInterceptors = () => {
  if (areInterceptorsInitialized) return;

  autocutApi.interceptors.request.use((config: any) => {
    // Modify the request config
    config.headers.Authorization = `Bearer ${accessTokensModule.accessToken}`;
    return config;
  });

  autocutApi.interceptors.response.use(
    response => response,
    async (error: TAutoCutApiError) => {
      const originalRequest = error.config as any;
      const apiError = new AutoCutApiError(error);

      if (
        apiError.status === 403 &&
        originalRequest &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        const newTokens = await refreshAccessToken(
          accessTokensModule.refreshToken,
        );

        originalRequest.headers.Authorization = `Bearer ${newTokens.accessToken}`;
        setTokens(newTokens);

        return axios.request(originalRequest);
      }

      const pathname = originalRequest.url ?? '?';

      return Promise.reject(
        new IncrementalError(apiError, `autocutApi-${pathname}`),
      );
    },
  );

  areInterceptorsInitialized = true;
};

export const setTokens = (accessTokens: {
  accessToken: string;
  refreshToken: string;
}) => {
  accessTokensModule = accessTokens;
};

const refreshAccessToken = async (
  refreshToken: string,
): Promise<{
  accessToken: string;
  refreshToken: string;
}> => {
  try {
    const response = await autocutApi.post(`/auth/refresh`, {
      refreshToken,
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const http = axios;

export {autocutApi, http};

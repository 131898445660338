import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {
  FileInput,
  FileWithPath,
} from '@autocut/designSystem/components/molecules/FileInput/FileInput';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useIntl} from 'react-intl';

export const WatermarkStep = () => {
  const intl = useIntl();

  const watermark = useAutoCutStore(
    state => state.ui.parameters.resize.watermark,
  );

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'modes_resize_steps_watermark_title',
        defaultMessage: 'Watermark',
      })}
      description={intl.formatMessage({
        id: 'modes_resize_steps_watermark_description',
        defaultMessage:
          'You can add a watermark to your sequence. It will be added to the bottom right corner of your sequence.',
      })}
    >
      <FileInput
        defaultFiles={
          watermark && (watermark as FileWithPath).path
            ? [{...watermark, path: (watermark as FileWithPath).path}]
            : undefined
        }
        onChange={files => {
          setAutocutStore(
            'ui.parameters.resize.watermark',
            files.length > 0 ? files[0] : undefined,
          );
        }}
        buttonText={intl.formatMessage({
          id: 'modes_resize_steps_watermark_items_choose',
          defaultMessage: 'Choose watermark',
        })}
        noFileText={intl.formatMessage({
          id: 'modes_resize_steps_watermark_items_noFile',
          defaultMessage: 'No watermark chosen',
        })}
      />
    </FormSection>
  );
};

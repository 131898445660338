import {CAPTION_MOGRT_HEIGHT, CAPTION_MOGRT_WIDTH} from './_backgrounds';

export const getFactors = async ({
  sequenceWidth,
  sequenceHeight,
  optimalScale,
}: {
  sequenceWidth: number;
  sequenceHeight: number;
  optimalScale: number;
}) => {
  const optimalFactor = optimalScale / 100;
  const finalXFactor = 1 / sequenceWidth;
  const finalYFactor = 1 / sequenceHeight;
  const finalMogrtXFactor = 1 / (optimalFactor * CAPTION_MOGRT_WIDTH);
  const finalMogrtYFactor = 1 / (optimalFactor * CAPTION_MOGRT_HEIGHT);

  return {
    optimalScale,
    optimalFactor,
    finalXFactor,
    finalYFactor,
    finalMogrtXFactor,
    finalMogrtYFactor,
  };
};

import {colors} from '@autocut/designSystem/colors';
import {IconXClose} from '@autocut/designSystem/components/atoms/Icon/general/IconXClose';
import {IconMicrophone01} from '@autocut/designSystem/components/atoms/Icon/media/IconMicrophone01';
import {Input} from '@autocut/designSystem/components/atoms/Input/Input';
import {
  Select,
  SelectOption,
} from '@autocut/designSystem/components/atoms/Select/Select';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {Divider} from '@autocut/designSystem/components/atoms/Divider/Divider';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {IconPlus} from '@autocut/designSystem/components/atoms/Icon/general/IconPlus';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {Speaker} from '@autocut/validationSchema/podcast.validationSchema';
import {useEffect, useMemo, useState} from 'react';
import {debounce} from '@autocut/utils/debounce';

import css from './SpeakersStep.module.scss';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import {useIntl} from 'react-intl';
import {
  handleAddSpeaker,
  handleAudioTrackUpdate,
  handleNameUpdate,
  handleRemoveSpeakerByIndex,
} from './utils';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';

const SELECT_COLORS = [
  '#16a2c4c8', // Previously 7f for the opacity
  '#f13ad7c8',
  '#FF8B00c8',
  '#0066ffc8',
  '#41c45bc8',
  '#8b4becc8',
  '#FFC400c8',
  '#c7292ac8',
  '#ffffffc8',
];

export type SpeakerStepProps = {
  maxAudioTrackId: number;
};

export const SpeakersStep = ({maxAudioTrackId}: SpeakerStepProps) => {
  const intl = useIntl();
  const {speakers} = useAutoCutStore(state => state.ui.parameters.podcast);

  const usedAudioTracksIds = useMemo(() => {
    return speakers
      .map(speaker => speaker.audioTrack)
      .filter(audioTrackId => audioTrackId !== undefined);
  }, [speakers]);

  const availableAudioTracksIds: number[] = useMemo(() => {
    return Array.from({length: maxAudioTrackId}, (_, i) => i + 1) as number[];
  }, [maxAudioTrackId]);

  return (
    <FormSection
      className={css.root}
      title={intl.formatMessage({
        id: 'modes_podcast_steps_customization_steps_speakers_title',
        defaultMessage: 'Speakers',
      })}
      description={intl.formatMessage({
        id: 'modes_podcast_steps_customization_steps_speakers_description',
        defaultMessage:
          'Add your speakers by assigning each an audio track and entering their respective names. Only one audio track can be selected per speaker. If multiple speakers share a single audio track, enter both names (e.g., "Alice & Bob").',
      })}
    >
      <PaddedSection>
        <FlexContainer
          flexDirection="column"
          gap={Spacing.s2}
        >
          <FlexContainer
            alignItems="flex-end"
            gap={Spacing.s4}
            className={css.header}
          >
            <Text
              className={css.audioTrack}
              variant={'textXs'}
              color={colors.gray300}
            >
              <TranslatedMessage
                id="modes_podcast_steps_customization_steps_speakers_header_audio"
                defaultMessage="Audio track"
              />
            </Text>
            <Text
              variant={'textXs'}
              color={colors.gray300}
            >
              <TranslatedMessage
                id="modes_podcast_steps_customization_steps_speakers_header_name"
                defaultMessage="Speaker’s name"
              />
            </Text>
          </FlexContainer>
          <FlexContainer
            flexDirection="column"
            gap={Spacing.s4}
          >
            <FlexContainer
              flexDirection="column"
              justifyContent="center"
              gap={Spacing.s2}
            >
              {speakers.map((speaker, index) => (
                <>
                  <SpeakerItem
                    key={`speakerItem-${index}`}
                    index={index}
                    speaker={speaker}
                    onRemove={() => handleRemoveSpeakerByIndex(index)}
                    onAudioTrackUpdate={audioTrackId =>
                      handleAudioTrackUpdate(index, audioTrackId)
                    }
                    onNameUpdate={name => handleNameUpdate(index, name)}
                    availableAudioTracks={availableAudioTracksIds
                      .filter(
                        audioTrackId =>
                          !usedAudioTracksIds.includes(audioTrackId) ||
                          (speaker.audioTrack !== undefined &&
                            speaker.audioTrack === audioTrackId),
                      )
                      .map(audioTrackId => ({
                        value: audioTrackId.toString(),
                        label: intl.formatMessage(
                          {
                            id: 'modes_podcast_steps_customization_steps_speakers_trackTemplate',
                            defaultMessage: 'Track A{index}',
                          },
                          {index: audioTrackId},
                        ),
                      }))}
                  />
                  {index !== speakers.length - 1 && <Divider />}
                </>
              ))}
            </FlexContainer>
            <Button
              variant="secondary.dashed"
              onClick={handleAddSpeaker}
            >
              <FlexContainer
                alignItems="center"
                justifyContent="center"
                gap={Spacing.s1}
              >
                <IconPlus
                  size={18}
                  color="white"
                />
                <TranslatedMessage
                  id="modes_podcast_steps_customization_steps_speakers_cta"
                  defaultMessage="Add a speaker"
                />
              </FlexContainer>
            </Button>
          </FlexContainer>
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};

export type SpeakerItemProps = {
  index: number;
  speaker: Speaker;
  onRemove: () => void;
  onAudioTrackUpdate: (audioTrack: number | undefined) => void;
  onNameUpdate: (name: string) => void;
  availableAudioTracks: SelectOption[];
};

const SpeakerItem = ({
  index,
  speaker,
  onRemove,
  onAudioTrackUpdate,
  onNameUpdate,
  availableAudioTracks,
}: SpeakerItemProps) => {
  const intl = useIntl();

  const [tempName, setTempName] = useState<string>(speaker.name);

  const debouncedOnNameUpdate = useMemo(
    () => debounce(onNameUpdate, 500),
    [onNameUpdate],
  );

  useEffect(() => {
    setTempName(speaker.name);
  }, [speaker.name]);

  return (
    <FlexContainer
      className={css.item}
      alignItems="center"
      gap={Spacing.s2}
      flexGrow={true}
      flexShrink={false}
    >
      <IconXClose
        className={`${css.remove} ${css.noShrink}`}
        onClick={onRemove}
        size={18}
        color="white"
      />
      <IconMicrophone01
        className={css.noShrink}
        size={18}
        color={colors.gray500}
      />
      <div
        className={`${css.bubble} ${css.noShrink}`}
        style={{
          backgroundColor: SELECT_COLORS[index % SELECT_COLORS.length],
        }}
      />
      <Select
        variant="track"
        className={css.noShrink}
        options={availableAudioTracks}
        selected={
          typeof speaker.audioTrack === 'number'
            ? speaker.audioTrack.toString()
            : speaker.audioTrack
        }
        onChange={value =>
          onAudioTrackUpdate(
            typeof value === 'string' ? parseInt(value) : undefined,
          )
        }
        placeholder={intl.formatMessage(
          {
            id: 'modes_podcast_steps_customization_steps_speakers_trackTemplate',
            defaultMessage: 'Track AX',
          },
          {index: 'X'},
        )}
      />
      <Input
        placeholder={intl.formatMessage({
          id: 'modes_podcast_steps_customization_steps_speakers_textInputPlaceholder',
          defaultMessage: "Enter speaker's name...",
        })}
        variant="secondary"
        value={tempName}
        onChange={value => {
          setTempName(value);
          void debouncedOnNameUpdate(value);
        }}
      />
    </FlexContainer>
  );
};

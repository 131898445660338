import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {CustomCard, PresetCard} from './PresetCard/PresetCard';
import {RiTiktokLine} from 'react-icons/ri';
import {AiOutlineYoutube} from 'react-icons/ai';
import {FaInstagram} from 'react-icons/fa6';
import {FiTwitter} from 'react-icons/fi';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import React from 'react';
import {useIntl} from 'react-intl';
import {CiCirclePlus} from 'react-icons/ci';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand';
import {TiPencil} from 'react-icons/ti';
import {ModalType} from '@autocut/enums/modals.enum';
import {ResizePreset} from '@autocut/types/Resize';

const defaultPresets: ResizePreset[] = [
  {
    name: 'TikTok, Shorts & Reels',
    width: 1080,
    height: 1920,
    icons: [
      <RiTiktokLine
        key={'tiktok'}
        size={16}
        color="white"
      />,
      <AiOutlineYoutube
        key={'youtube'}
        size={16}
        color="white"
      />,
      <FaInstagram
        key={'reels'}
        size={16}
        color="white"
      />,
    ],
  },
  {
    name: 'Youtube FHD',
    width: 1920,
    height: 1080,
    icons: [
      <AiOutlineYoutube
        key={'youtube'}
        size={16}
        color="white"
      />,
    ],
  },
  {
    name: 'Twitter Square',
    width: 1920,
    height: 1920,
    icons: [
      <FiTwitter
        key={'twitter'}
        size={16}
        color="white"
      />,
    ],
  },
];

export const ResizePresetStep = () => {
  const intl = useIntl();

  const {preset, customPresets} = useAutoCutStore(state => ({
    preset: state.ui.parameters.resize.preset,
    customPresets: state.ui.resize.customPresets,
  }));

  const handleDeleteCustomPreset = (name: string) => {
    setAutocutStore(
      'ui.resize.customPresets',
      customPresets.filter(preset => preset.name !== name),
    );

    if (preset?.name === name) {
      setAutocutStore(
        'ui.parameters.resize.preset',
        undefined as unknown as ResizePreset,
      );
    }
  };

  return (
    <>
      <FormSection
        title={intl.formatMessage({
          id: 'modes_resize_steps_presets_title',
          defaultMessage: 'Presets',
        })}
        description={intl.formatMessage({
          id: 'modes_resize_steps_presets_description',
          defaultMessage:
            'Select a preset to resize your sequence. You can also create your own preset. The dimensions of your new sequence are displayed below.',
        })}
      >
        <FlexContainer
          gap={Spacing.s2}
          flexWrap="wrap"
        >
          {[
            ...defaultPresets,
            ...customPresets.map(preset => ({
              ...preset,
              deletable: true,
              icons: [
                <TiPencil
                  key={`${preset.name}-icon`}
                  size={16}
                  color="white"
                />,
              ],
            })),
          ].map(currentPreset => (
            <PresetCard
              key={currentPreset.name}
              title={currentPreset.name}
              width={currentPreset.width}
              height={currentPreset.height}
              icon={currentPreset.icons as React.ReactNode[]}
              selected={currentPreset.name === preset?.name}
              onClick={() => {
                const {icons, ...newPreset} = currentPreset;
                setAutocutStore('ui.parameters.resize.preset', newPreset);
              }}
              {...((currentPreset as any).deletable && {
                onDelete: () => handleDeleteCustomPreset(currentPreset.name),
              })}
            />
          ))}
          <CustomCard
            selected={false}
            onClick={() =>
              setAutocutStore(
                'ui.openedModalName',
                ModalType.CreateResizePreset,
              )
            }
          >
            <FlexContainer
              style={{
                width: '100%',
                height: '100%',
              }}
              alignItems="center"
              justifyContent="center"
            >
              <CiCirclePlus
                color="white"
                size={48}
              />
            </FlexContainer>
          </CustomCard>
        </FlexContainer>
      </FormSection>
    </>
  );
};

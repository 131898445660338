export enum ModalType {
  'CancelCut' = 'Undo',
  'ContactUs' = 'Contact',
  'FeatureRequest' = 'Feature',
  'ReportIssue' = 'Logfile',
  'Review' = 'Review',
  'ExchangeRate' = 'Exchange',
  'Feedback' = 'Feedback',
  'TrialExpired' = 'TrialExpired',
  'EndProcess' = 'EndProcess',
  'EndCaptionsProcess' = 'EndCaptionsProcess',
  'Error' = 'Error',
  'BRollCredits' = 'BRollCredits',
  'CreateResizePreset' = 'CreateResizePreset',
  'StartUp' = 'StartUp',
  'UsageLimit' = 'UsageLimit',
  'Processing' = 'ProcessModal',
  'Onboarding' = 'Onboarding',
  'KeyExpired' = 'KeyExpired',
  'GamingTrial' = 'GamingTrial',
  'UnsupportedVersion' = 'UnsupportedVersion',
  'FormattedNumber' = 'FormattedNumber',
  'DeepgramError' = 'DeepgramError',
  'CaptionsCustomization' = 'CaptionsCustomization',
  'CaptionsPreview' = 'CaptionsPreview',
  'SearchPreset' = 'SearchPreset',
  'DeletePresets' = 'DeletePresets',
  'EditTranscript' = 'EditTranscript',
  'GenerateEmojis' = 'GenerateEmojis',
  'CreatePresetModal' = 'CreatePresetModal',
  'UsageStats' = 'UsageStats',
  'FontNotFound' = 'FontNotFound',
  'BetaOnboarding' = 'BetaOnboarding',
}

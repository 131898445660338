import {AutocutModes} from '@autocut/enums/modes.enum';
import {ModeWrapper} from '../ModeWrapper';
import {useLocation, useNavigate} from 'react-router-dom';
import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import {useIntl} from 'react-intl';
import {SelectionScreen} from '@autocut/components/SelectionScreen/SelectionScreen';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {colors} from '@autocut/designSystem/colors';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import chaptersThumbnail from '@autocut/assets/images/modes/chapter.png?url';
import {ChaptersLanguage} from './language/ChaptersLanguage';
import {ChaptersEditor} from './editor/ChaptersEditor';

export const ChaptersMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={AutocutModes.Chapters.icon}
      text={AutocutModes.Chapters.name}
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);

        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/chapters"
        steps={[
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_select_title',
              defaultMessage: 'Set In/Out points',
            }),
            index: true,
            clickable: true,
            path: '',
            element: (
              <SelectionScreen
                image={{
                  src: chaptersThumbnail,
                  alt: AutocutModes.Chapters.name,
                }}
                title={{
                  id: 'modes_general_steps_select_title',
                  defaultMessage: 'Set In/Out points',
                }}
                texts={[
                  {
                    id: 'modes_general_steps_select_descriptions_1',
                    defaultMessage:
                      'Without setting In/Out Points, AutoCut will apply to the entire timeline.',
                  },
                  {
                    id: 'modes_general_steps_select_descriptions_2',
                    defaultMessage:
                      'Manually mute clips or tracks to exclude them from audio analysis.',
                  },
                ]}
                cta={
                  <Button
                    color={colors.primary600}
                    onClick={() => {
                      navigate('./language');
                    }}
                  >
                    <TranslatedMessage
                      id="modes_general_steps_select_cta"
                      defaultMessage="Confirm In/Out points"
                    />
                  </Button>
                }
              />
            ),
          },
          {
            clickable: true,
            label: 'Select clips & language',
            path: 'language',
            element: <ChaptersLanguage />,
          },
          {
            clickable: false,
            label: 'Edit Chapters',
            path: 'editor',
            element: <ChaptersEditor />,
          },
        ]}
      />
    </ModeWrapper>
  );
};

import {preload} from '@autocut/types/ElectronPreload';
import {cloneDeep, merge} from 'lodash';

const defaultValue: PproSourceTextParam = {
  mShadowFontMapHash: null,
  mTextParam: {
    mAlignment: 2.0,
    mBackFillColor: 0.0,
    mBackFillOpacity: 0.0,
    mBackFillSize: 0.0,
    mBackFillVisible: false,
    mDefaultRun: [],
    mHeight: 0.0,
    mHindiDigits: false,
    mIndic: false,
    mIsMask: false,
    mIsMaskInverted: false,
    mIsVerticalText: false,
    mLeading: 0.0,
    mLigatures: false,
    mLineCapType: 0.0,
    mLineJoinType: 0.0,
    mMiterLimit: 0.0,
    mNumStrokes: 1.0,
    mRTL: false,
    mShadowAngle: 135.0,
    mShadowBlur: 40.0,
    mShadowColor: 4144959.0,
    mShadowOffset: 7.0,
    mShadowOpacity: 75.0,
    mShadowSize: 0.0,
    mShadowVisible: false,
    mStyleSheet: {
      mAdditionalStrokeColor: [],
      mAdditionalStrokeVisible: [],
      mAdditionalStrokeWidth: [],
      mBaselineOption: {mParamValues: [[0.0, 0.0]]},
      mBaselineShift: {mParamValues: [[0.0, 0.0]]},
      mCapsOption: {mParamValues: [[0.0, 0.0]]},
      mFauxBold: {mParamValues: [[0, false]]},
      mFauxItalic: {mParamValues: [[0, false]]},
      mFillColor: {
        mParamValues: [[0, 16777215]],
      },
      mFillOverStroke: {mParamValues: [[0, true]]},
      mFillVisible: {mParamValues: [[0, true]]},
      mFontName: {mParamValues: [[0, 'ArialMT']]},
      mFontSize: {mParamValues: [[0.0, 60.0]]},
      mKerning: {mParamValues: [[0.0, 0.0]]},
      mStrokeColor: {mParamValues: [[0.0, 0.0]]},
      mStrokeVisible: {mParamValues: [[0, true]]},
      mStrokeWidth: {mParamValues: [[0.0, 10.0]]},
      mText: 'COUCOU FROM ARTHUR',
      mTracking: {mParamValues: [[0.0, 0.0]]},
      mTsumi: {mParamValues: [[0.0, 0.0]]},
      mUnderline: null,
    },
    mTabWidth: 400.0,
    mVerticalAlignment: 0.0,
    mWidth: 0.0,
  },
  mUseLegacyTextBox: false,
  mVersion: 1.0,
};

export enum PproSourceTextCapsOptionsEnum {
  NORMAL = 0,
  SMALL_CAPS = 1,
  ALL_CAPS = 2,
}

export type PproSourceTextParam = {
  mShadowFontMapHash?: null;
  mTextParam?: {
    mAlignment?: number;
    mBackFillColor?: number;
    mBackFillOpacity?: number;
    mBackFillSize?: number;
    mBackFillVisible?: boolean;
    mDefaultRun?: any[];
    mHeight?: number;
    mHindiDigits?: boolean;
    mIndic?: boolean;
    mIsMask?: boolean;
    mIsMaskInverted?: boolean;
    mIsVerticalText?: boolean;
    mLeading?: number;
    mLigatures?: boolean;
    mLineCapType?: number;
    mLineJoinType?: number;
    mMiterLimit?: number;
    mNumStrokes?: number;
    mRTL?: boolean;
    mShadowAngle?: number;
    mShadowBlur?: number;
    mShadowColor?: number;
    mShadowOffset?: number;
    mShadowOpacity?: number;
    mShadowSize?: number;
    mShadowVisible?: boolean;
    mStyleSheet?: {
      mAdditionalStrokeColor?: any[];
      mAdditionalStrokeVisible?: any[];
      mAdditionalStrokeWidth?: any[];
      mBaselineOption?: {mParamValues?: number[][]};
      mBaselineShift?: {mParamValues?: number[][]};
      mCapsOption?: {
        mParamValues?: [[number, PproSourceTextCapsOptionsEnum]];
      };
      mFauxBold?: {mParamValues?: [[number, boolean]]};
      mFauxItalic?: {mParamValues?: [[number, boolean]]};
      mFillColor?: {mParamValues?: [number, number][]};
      mFillOverStroke?: {mParamValues?: [[number, boolean]]};
      mFillVisible?: {mParamValues?: [[number, boolean]]};
      mFontName?: {mParamValues?: [[number, string]]};
      mFontSize?: {mParamValues?: number[][]};
      mKerning?: {mParamValues?: number[][]};
      mStrokeColor?: {mParamValues?: number[][]};
      mStrokeVisible?: {mParamValues?: [[number, boolean]]};
      mStrokeWidth?: {mParamValues?: number[][]};
      mText?: string;
      mTracking?: {mParamValues?: number[][]};
      mTsumi?: {mParamValues?: number[][]};
      mUnderline?: null;
    };
    mTabWidth?: number;
    mVerticalAlignment?: number;
    mWidth?: number;
  };
  mUseLegacyTextBox?: boolean;
  mVersion?: number;
};

export const formatSourceTextData = (textData?: PproSourceTextParam) => {
  const defaultValueClone = cloneDeep(defaultValue);
  const mergedParams = merge(defaultValueClone, textData);

  const jsonString = JSON.stringify(mergedParams);

  const b = preload().buffer.from([
    0x0f, 0x0f, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
  ]);

  const encodedText = preload().buffer.encodeToBase64([
    new Uint8Array(b),
    new Uint8Array(preload().buffer.from(jsonString, 'utf16le')),
  ]);

  return encodedText;
};

import {measureTextOnCanvas} from '@autocut/pages/modes/captions/utils/measureTextOnCanvas';
import type {CaptionChunk} from '@autocut/types/Captions';
import {getWordValue} from './getWordValue';
import {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import {getFontParameters} from './getFontParameters';
import {getIsUppercase} from './getIsUppercase';

export const calculateLineMetrics = ({
  chunks,
  parameters,
}: {
  chunks: CaptionChunk[];
  parameters: CaptionsParameters;
}) => {
  const isUppercase = getIsUppercase(parameters);
  const updatedChunks = chunks.map(chunk => {
    let maxLineWidth = 0;
    chunk.lines.forEach(line => {
      const fullText = line.map(word => getWordValue(word)).join(' ');
      const metrics = measureTextOnCanvas(
        fullText,
        getFontParameters(parameters),
        isUppercase,
      ).metrics;

      maxLineWidth = Math.max(maxLineWidth, metrics.width);

      return line;
    });

    return {
      ...chunk,
      maxLineWidth,
    };
  });

  return {
    chunks: updatedChunks,
  };
};

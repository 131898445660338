import {setAutocutStore} from '@autocut/utils/zustand';
import React from 'react';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {rgbToHex} from '@autocut/utils/color.utils';
import chroma from 'chroma-js';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {Switch} from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';
import {Slider} from '@autocut/designSystem/components/atoms/Slider/Slider';
import ColorPicker from '@autocut/components/ColorPicker/ColorPicker';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';

const TextFormatCustomization = () => {
  const {
    uppercase,
    italic,
    removePunctuation,
    glow: {enabled: glowEnabled, intensity: glowIntensity, color: glowColor},
  } = useAutoCutStore(state => state.ui.parameters.caption.formating);

  return (
    <FormSection
      title={
        <TranslatedMessage
          id={
            'modes_caption_steps_customization_parts_style_modal_formating_title'
          }
          defaultMessage="Text formating"
        />
      }
    >
      <ParametersGroupLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_formating_removePunctuation'
              }
              defaultMessage="Remove punctuation"
            />
          }
        >
          <Switch
            checked={removePunctuation}
            onChange={(checked: boolean) =>
              setAutocutStore(
                'ui.parameters.caption.formating.removePunctuation',
                checked,
              )
            }
            size={16}
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_formating_uppercase'
              }
              defaultMessage="Uppercase"
            />
          }
        >
          <Switch
            checked={uppercase}
            onChange={(checked: boolean) =>
              setAutocutStore(
                'ui.parameters.caption.formating.uppercase',
                checked,
              )
            }
            size={16}
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_formating_italic'
              }
              defaultMessage="Italic"
            />
          }
        >
          <Switch
            checked={italic}
            onChange={(checked: boolean) =>
              setAutocutStore('ui.parameters.caption.formating.italic', checked)
            }
            size={16}
          />
        </ParameterLayout>

        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_formating_glow_title'
              }
              defaultMessage="Glow"
              valuePamareters={{
                color: chroma(rgbToHex(glowColor)).luminance(0.3).hex(),
              }}
            />
          }
        >
          <Switch
            checked={glowEnabled}
            onChange={(checked: boolean) =>
              setAutocutStore(
                'ui.parameters.caption.formating.glow.enabled',
                checked,
              )
            }
            size={16}
          />
          <ParametersGroupLayout
            status={glowEnabled}
            hiddenContent={null}
          >
            <ParameterLayout
              title={
                <TranslatedMessage
                  id={
                    'modes_caption_steps_customization_parts_style_modal_formating_glow_color'
                  }
                  defaultMessage="Color"
                />
              }
            >
              <ColorPicker
                value={glowColor}
                onChange={color =>
                  setAutocutStore(
                    'ui.parameters.caption.formating.glow.color',
                    color,
                  )
                }
              />
            </ParameterLayout>
            <ParameterLayout
              title={
                <TranslatedMessage
                  id={
                    'modes_caption_steps_customization_parts_style_modal_formating_glow_intensity'
                  }
                  defaultMessage="Intensity"
                />
              }
              wrap={true}
            >
              <Slider
                value={
                  isNaN(glowIntensity) || !glowIntensity
                    ? '0'
                    : `${glowIntensity}`
                }
                onChange={value =>
                  setAutocutStore(
                    'ui.parameters.caption.formating.glow.intensity',
                    value,
                  )
                }
                min={0}
                max={250}
                variant="valueOnLeft"
              />
            </ParameterLayout>
          </ParametersGroupLayout>
        </ParameterLayout>
      </ParametersGroupLayout>
    </FormSection>
  );
};

export default TextFormatCustomization;

import {measureTextOnCanvas} from '@autocut/pages/modes/captions/utils/measureTextOnCanvas';
import {CanvasFontParams} from '@autocut/types/Captions';
import {autocutStoreVanilla} from '@autocut/utils/zustand';

export const getHighlightPosition = async ({
  highlightLineText,
  highlightWord,
  lineBeforeWord,
  indexLine,
  properties,
}: {
  highlightLineText: string;
  highlightWord: string;
  lineBeforeWord: string;
  indexLine: number;
  properties: {
    fontParameters: CanvasFontParams;
    uppercase: boolean;
  };
}) => {
  const lineText = properties.uppercase
    ? highlightLineText.toUpperCase()
    : highlightLineText;
  const word = properties.uppercase
    ? highlightWord.toUpperCase()
    : highlightWord;

  const {metrics: lineMeasure} = measureTextOnCanvas(
    lineText,
    properties.fontParameters,
    properties.uppercase,
  );

  const {metrics: lineBeforeWordMeasure} = measureTextOnCanvas(
    lineBeforeWord,
    properties.fontParameters,
    properties.uppercase,
  );

  const {metrics: wordMeasure} = measureTextOnCanvas(
    word,
    properties.fontParameters,
    properties.uppercase,
  );

  let x =
    (lineMeasure.width - wordMeasure.width) / 2 - lineBeforeWordMeasure.width;
  const textDirection =
    autocutStoreVanilla().ui.parameters.caption.languageOfTranscription
      .textDirection;
  if (textDirection === 'rtl') {
    x = 1 - x;
  }

  const lineHeight = (120 * properties.fontParameters.fontSize) / 100;
  const y = -lineHeight * (1 / 2 + indexLine);

  return {
    x,
    y,
  };
};

import {AutocutModeIds, AutocutModes} from '@autocut/enums/modes.enum';
import {parametersValidationSchema} from '@autocut/validationSchemas/parameters.validationSchema';
import {z as zod} from 'zod';

import {autocutStoreVanilla} from './zustand';

export const getCurrentModeId = (): AutocutModeIds => {
  const mode = autocutStoreVanilla().ui.process.mode;
  return mode.id as AutocutModeIds;
};

export const getParametersForMode = <K extends AutocutModeIds>(
  modeId: K = 'current' as K,
): zod.infer<(typeof parametersValidationSchema)[K]> => {
  const parameters = autocutStoreVanilla().ui.parameters;

  modeId = modeId === ('current' as K) ? (getCurrentModeId() as K) : modeId;

  return parameters[modeId];
};

import {StepLayout} from '@autocut/components/StepLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Alert} from '@autocut/designSystem/components/atoms/Alert/Alert';
import {IconInfoCircle} from '@autocut/designSystem/components/atoms/Icon/general/IconInfoCircle';
import {Select} from '@autocut/designSystem/components/atoms/Select/Select';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';

export const REPEAT_ALGORITHM = {
  FAST: 'FAST',
  PRECISE: 'PRECISE',
};

export const AlgorithmSelectStep = () => {
  const intl = useIntl();
  const algorithms = useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: 'modes_repeat_steps_language_algorithm_options_precise',
          defaultMessage: 'Precise',
        }),
        value: REPEAT_ALGORITHM.PRECISE,
      },
      {
        label: intl.formatMessage({
          id: 'modes_repeat_steps_language_algorithm_options_fast',
          defaultMessage: 'Fast',
        }),
        value: REPEAT_ALGORITHM.FAST,
      },
    ],
    [intl],
  );
  const {selectedAlgorithm} = useAutoCutStore(
    state => state.ui.parameters.repeat,
  );

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'modes_repeat_steps_language_algorithm_select',
        defaultMessage: 'Select algorithm',
      })}
    >
      <Select
        fullWidth
        options={algorithms}
        selected={
          algorithms.find(algorithm => algorithm.value === selectedAlgorithm)
            ?.value
        }
        onChange={value => {
          if (!value) return;

          setAutocutStore('ui.parameters.repeat.selectedAlgorithm', value);
        }}
      />
      <div style={{marginTop: 16}}>
        <Alert
          icon={
            <IconInfoCircle
              size={18}
              color="gray.300"
            />
          }
        >
          <TranslatedMessage
            id="modes_repeat_steps_language_algorithm_info"
            defaultMessage={
              'The precise algorithm is our most recent and efficient one but require approximately 1 minute of processing per 6 minutes of video.'
            }
          />
        </Alert>
      </div>
    </FormSection>
  );
};

import * as React from 'react';

import sentenceCss from '../TranscriptSentence/TranscriptSentence.module.css';
import css from './TranscriptWord.module.css';
import {WordBase} from '@autocut/types/Deepgram';
import {autocutStoreVanilla} from '@autocut/utils/zustand';

type TranscriptWordProps = {
  word: WordBase;
  groupIndex: number;
  utteranceIndex: number;
  wordIndex: number;
};

export const getValue = (word: WordBase) => {
  const removePunctuation =
    autocutStoreVanilla().ui.parameters.caption.formating.removePunctuation;
  return removePunctuation ? word.word : (word.punctuated_word ?? word.word);
};

export const TranscriptWord = ({
  word,
  groupIndex,
  utteranceIndex,
  wordIndex,
}: TranscriptWordProps) => {
  return (
    <span
      className={`${css.word} ${word.isCut ? sentenceCss.marked : ''} ${
        sentenceCss.overrideSelectionStyle
      }`}
      data-group-index={groupIndex}
      data-utterance-index={utteranceIndex}
      data-word-index={wordIndex}
    >
      {word.punctuated_word || word.word}
    </span>
  );
};

import logLevel from '@autocut/enums/logLevel.enum';
import {preload} from '@autocut/types/ElectronPreload';
import {
  ResourceManagerElementConfig,
  ResourceScope,
} from '@autocut/types/ResourceManager';
import {getResourceFolderPath} from '@autocut/utils/electron/app.electron.utils';

import {downloadFile} from './files.utils';
import {autocutApi} from './http.utils';
import {logger} from './logger';

const logMessage = async (
  elementName: string,
  level: logLevel,
  message = 'log',
  objects = {},
) => {
  logger(
    `defaultResourceManagerState [${elementName}]`,
    level,
    message,
    objects,
  );
};

export const getRessourceSignedUrl = async (fileName: string) => {
  const signedUrlResponse = await autocutApi.post(
    `/downloadableRessources/fileUrl`,
    {
      fileName,
    },
  );
  const signedUrl = signedUrlResponse.data;

  return signedUrl as string;
};

// ressourceName should correspond to a key in resourcesConfigs
export const downloadRessource = async ({
  bucketFileName,
  outputFilePath,
}: {
  bucketFileName: string;
  outputFilePath: string;
}) => {
  const signedUrl = await getRessourceSignedUrl(bucketFileName);

  if (!preload().fs.existsSync(outputFilePath)) {
    preload().fs.mkdirSync(preload().path.dirname(outputFilePath), {
      recursive: true,
    });
  }

  await downloadFile(signedUrl, outputFilePath, true);
  await logMessage(
    bucketFileName,
    logLevel.info,
    `${bucketFileName} downloaded`,
  );

  return outputFilePath;
};

export const getResourceConfig = ({
  fileName,
  folderPath = 'global',
  scope = 'global',
  additionalData = {
    requestOnInit: true,
  },
}: {
  fileName: string;
  folderPath?: string;
  scope?: ResourceScope;
  additionalData?: any;
}): ResourceManagerElementConfig => ({
  fileName,
  downloadMethod: async () => {
    const filePath = preload().path.join(
      getResourceFolderPath(),
      folderPath,
      fileName,
    );
    return downloadRessource({
      bucketFileName: fileName,
      outputFilePath: filePath,
    });
  },
  existCheck: async () => {
    const filePath = preload().path.join(
      getResourceFolderPath(),
      folderPath,
      fileName,
    );

    return {
      isExist: preload().fs.existsSync(filePath),
      outputFilePath: filePath,
    };
  },
  scope,
  ...additionalData,
});

export const requestResourceOnInit = (
  resourceConfig: ResourceManagerElementConfig,
): ResourceManagerElementConfig => ({
  ...resourceConfig,
  requestOnInit: true,
});

type PlatformResourcePathsConfig = {
  folderPath: string;
  extractSubfolder?: string;
  versionFilename?: string;
  downloadUrl: string;
};
export const getInstallResourceAtSpecificPathConfig = ({
  mac,
  windows,
  resourceName,
  createFolderIfNotExist = true,
  checkIfUpToDate,
}: {
  mac: PlatformResourcePathsConfig;
  windows: PlatformResourcePathsConfig;
  resourceName: string;
  createFolderIfNotExist?: boolean;
  checkIfUpToDate?: (versionFileContent: string) => Promise<boolean>;
}) => {
  const platform = preload().os.platform() === 'win32' ? 'windows' : 'mac';

  const platforms = {
    mac,
    windows,
  };

  return requestResourceOnInit({
    fileName: resourceName,
    downloadMethod: async () => {
      const zipFilePath =
        (await preload().resources.download(platforms[platform].downloadUrl)) ||
        '';

      if (platforms[platform].extractSubfolder) {
        const extractPath = preload().path.join(
          platforms[platform].folderPath,
          platforms[platform].extractSubfolder || '',
        );
        if (preload().fs.existsSync(extractPath)) {
          await preload().fs.rmSync(extractPath, {recursive: true});
        }
      }
      await preload().resources.unzip(
        zipFilePath,
        preload().path.join(
          platforms[platform].folderPath,
          platforms[platform].extractSubfolder || '',
        ),
      );

      return platforms[platform].folderPath;
    },
    existCheck: async () => {
      console.log(resourceName, 'existCheck');
      if (
        !preload().fs.existsSync(platforms[platform].folderPath) &&
        !createFolderIfNotExist
      ) {
        //We return true to avoid downloading the script if the folder doesn't exist (which mean that DV is not installed)
        console.log(
          resourceName,
          'folder',
          platforms[platform].folderPath,
          'does not exist',
        );
        return {isExist: true, outputFilePath: platforms[platform].folderPath};
      }
      if (!platforms[platform].versionFilename || !checkIfUpToDate)
        return {isExist: false, outputFilePath: platforms[platform].folderPath};

      const versionFilePath = preload().path.join(
        platforms[platform].folderPath,
        platforms[platform].versionFilename || '',
      );
      if (!preload().fs.existsSync(versionFilePath))
        return {isExist: false, outputFilePath: versionFilePath};
      const versionFileContent = preload()
        .fs.readFileSync(versionFilePath, 'utf-8')
        .trim();

      const outdated = await checkIfUpToDate(versionFileContent);
      if (outdated) {
        console.log(resourceName, 'outdated');
        return {isExist: false, outputFilePath: versionFilePath};
      }
      return {isExist: true, outputFilePath: versionFilePath};
    },
    scope: 'global',
  });
};

import {BiCopy} from 'react-icons/bi';

import './ContactUsModal.css';

import letterIconUrl from '@autocut/assets/images/Letter.svg';
const letterIcon = new URL(letterIconUrl, import.meta.url).href;
import {AUTOCUT_CONTACT} from '@autocut/constants/constants';
import {useLogger} from '@autocut/hooks/useLogger';
import {setAutocutStore} from '@autocut/utils/zustand';
import {Button} from '@autocut/components/Button/Button';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import logLevel from '@autocut/enums/logLevel.enum';
import {ModalType} from '@autocut/enums/modals.enum';
import {clipboard} from '@autocut/utils/clipboard.util';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useIntl} from 'react-intl';

const ContactUsModal = () => {
  const intl = useIntl();
  const {logMessage} = useLogger('ContactUsModal');

  const showToolTip = async () => {
    await clipboard.copy(AUTOCUT_CONTACT);

    const tooltip = document.getElementById('contact-us-modal-copy-tooltip');
    if (!tooltip) {
      return;
    }
    tooltip.classList.replace(
      'contact-us-modal-copy-tooltip',
      'contact-us-modal-copy-tooltip-active',
    );

    setTimeout(() => {
      tooltip.classList.replace(
        'contact-us-modal-copy-tooltip-active',
        'contact-us-modal-copy-tooltip',
      );
    }, 2000);
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'modals_contactUs_title',
        defaultMessage: 'We are sorry that you encounter a bug',
      })}
    >
      <FlexContainer
        className="contact-us-modal"
        alignItems="center"
        justifyContent="space-around"
      >
        <FlexContainer flexDirection="column">
          <FlexContainer className="contact-us-modal-content">
            <FlexContainer flexDirection="column">
              <p className="contact-us-modal-text">
                <TranslatedMessage
                  id="modals_contactUs_text"
                  defaultMessage="If AutoCut doesn't works perfectly with your project and you want to report the issue :"
                />
              </p>
              <br />
              <Button
                onClickFunction={() => {
                  logMessage(logLevel.info, 'Open SendDailyLog modal.');
                  setAutocutStore('ui.openedModalName', ModalType.ReportIssue);
                }}
              >
                <span className="contact-us-modal-button">
                  <TranslatedMessage
                    id="modals_contactUs_cta"
                    defaultMessage="Report an issue"
                  />
                </span>
              </Button>
              <p className="contact-us-modal-text">
                <TranslatedMessage
                  id="modals_contactUs_non_editing"
                  defaultMessage="If you have an issue with your account, your key, your payment or anything else not link to your video editing project :"
                />
              </p>
            </FlexContainer>
            <img
              src={letterIcon}
              alt="letter-icon"
              className="letter"
            />
          </FlexContainer>
          <FlexContainer
            flexDirection="row"
            className="contact-us-modal-contact"
          >
            <FlexContainer
              className="contact-us-modal-contact-us"
              alignItems="center"
            >
              <TranslatedMessage
                id="modals_contactUs_contact"
                defaultMessage="Contact us :"
              />
              <span className="contact-us-modal-mail">{AUTOCUT_CONTACT}</span>
              <BiCopy
                color="white"
                size={24}
                title="Copy to clipboard"
                className="contact-us-modal-copy-icon"
                onClick={showToolTip}
              />
              <div className="animation-container">
                <div
                  id="contact-us-modal-copy-tooltip"
                  className="contact-us-modal-copy-tooltip"
                >
                  <TranslatedMessage
                    id="modals_contactUs_contactClicked"
                    defaultMessage="Contact copy to clipboard !"
                  />
                </div>
              </div>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

export default ContactUsModal;

import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {
  RadioForm,
  RadioFormProps,
} from '@autocut/designSystem/components/molecules/RadioForm/RadioForm';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand';
import {IntlShape, useIntl} from 'react-intl';

const getReframeOptions = (intl: IntlShape) => {
  const options: RadioFormProps<
    'slower' | 'default' | 'faster' | 'none'
  >['options'] = [
    {
      value: 'slower',
      name: 'slower',
      title: intl.formatMessage({
        id: 'modes_resize_steps_reframe_items_slower',
        defaultMessage: 'Slow',
      }),
    },
    {
      value: 'default',
      name: 'default',
      title: intl.formatMessage({
        id: 'modes_resize_steps_reframe_items_default',
        defaultMessage: 'Normal',
      }),
      defaultChecked: true,
    },
    {
      value: 'faster',
      name: 'faster',
      title: intl.formatMessage({
        id: 'modes_resize_steps_reframe_items_faster',
        defaultMessage: 'Fast',
      }),
    },
    {
      value: 'none',
      name: 'none',
      title: intl.formatMessage({
        id: 'modes_resize_steps_reframe_items_none',
        defaultMessage: 'None',
      }),
    },
  ];

  return options;
};

const ReframeOptions: RadioFormProps<
  'slower' | 'default' | 'faster'
>['options'] = [
  {
    value: 'slower',
    name: 'slower',
    title: 'Slower',
    description: 'Follows the subject slowly.',
  },
];

export const ReframeStep = () => {
  const intl = useIntl();
  const reframePreset = useAutoCutStore(
    state => state.ui.parameters.resize.autoReframePreset,
  );

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'modes_resize_steps_reframe_title',
        defaultMessage: 'Follow Subject',
      })}
      description={intl.formatMessage({
        id: 'modes_resize_steps_reframe_description',
        defaultMessage:
          'You can choose the speed at which the camera will follow the subject.',
      })}
    >
      <RadioForm
        flexDirection="row"
        justifyContent="space-between"
        options={getReframeOptions(intl).map(option => ({
          ...option,
          checked: option.value === reframePreset,
        }))}
        onChange={value =>
          setAutocutStore('ui.parameters.resize.autoReframePreset', value)
        }
      />
    </FormSection>
  );
};

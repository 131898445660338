import {CAPTIONS_XML_PROJECT_LOCALE} from '@autocut/contexts/LocaleContext';
import {
  localTransformEffectName,
  localMotionEffectName,
} from '@autocut/enums/videoEffect.enum';
import {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import {XMLTrackItem} from '../class/_XMLTrackItem';
import {secondToTick} from '../temp/_ticks.temp';
import {ResizedXMLTemplates} from './_resizeTemplate';

const FLOATING_TEXT_EFFECT_CLIP_VIDEO_FILTER_COMPONENTS = [
  localTransformEffectName[CAPTIONS_XML_PROJECT_LOCALE],
  localMotionEffectName[CAPTIONS_XML_PROJECT_LOCALE],
];

export const handleFloatingText = ({
  templates,
  parameters,
  sequenceSecondsEnd,
}: {
  templates: ResizedXMLTemplates;
  parameters: CaptionsParameters;
  sequenceSecondsEnd: number;
}) => {
  const floatingTextEffectClip = templates.floatingTextEffectClip;
  if (parameters.animations.floatingText) {
    addFloatingTextEffect({
      floatingTextEffectClip,
      captionsSequenceSecondsEnd: sequenceSecondsEnd,
    });
  } else {
    floatingTextEffectClip.remove();
  }
};

const FLOATING_TEXT_EFFECT_CLIP_SECONDS_DURATION = 5;

const addFloatingTextEffect = ({
  floatingTextEffectClip,
  captionsSequenceSecondsEnd,
}: {
  floatingTextEffectClip?: XMLTrackItem;
  captionsSequenceSecondsEnd: number;
}) => {
  if (!floatingTextEffectClip) {
    console.error(
      'The floating text effect could not be added as the floatingTextEffectClip was undefined.',
    );
    return false;
  }

  let floatingTextEffectClipSecondsStart =
    FLOATING_TEXT_EFFECT_CLIP_SECONDS_DURATION;
  let index = 1;
  while (floatingTextEffectClipSecondsStart < captionsSequenceSecondsEnd) {
    let endSeconds =
      floatingTextEffectClipSecondsStart +
      FLOATING_TEXT_EFFECT_CLIP_SECONDS_DURATION;
    if (endSeconds > captionsSequenceSecondsEnd) {
      endSeconds = captionsSequenceSecondsEnd;
    }

    floatingTextEffectClip.clone({
      startTick: secondToTick(floatingTextEffectClipSecondsStart).toString(),
      endTick: secondToTick(endSeconds).toString(),
      copiedVideoFilterComponents:
        FLOATING_TEXT_EFFECT_CLIP_VIDEO_FILTER_COMPONENTS,
      index,
    });

    index++;
    floatingTextEffectClipSecondsStart = endSeconds;
  }

  return true;
};

import {CaptionChunk} from '@autocut/types/Captions';
import {getPosition} from './_getPosition';
import {getSelectedTransitions} from './_getSelectedTransitions';
import {handleCleanClips} from './_handleCleanClips';
import {handleFloatingText} from './_handleFloatingText';
import {handleHighlights} from './_handleHighlight';
import {handleRename} from './_handleRename';
import {handleResizeSequence} from './_handleResizeSequence';
import {handleTextBackground} from './_handleTextBackground';
import {handleTextPosition} from './_handleTextPosition';
import {handleTextTransitions} from './_handleTextTransitions';
import {resizeXmlTemplates} from './_resizeTemplate';
import {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import {initCaptionXMLTemplate} from '../_initXml';
import {TimelinePpro} from '../temp/_pproTimeline.temp';
import {handleEmojis} from './_handleEmojis';

type PrepareCaptionsXmlResult = {
  prprojFilePath: string;
  xmlName: string;
  trackIndex: number;
  secondTimes: {start: number; end: number};
};

export const prepareCaptionsXml = async ({
  timeline,
  chunks,
  parameters,
  timeOffset,
  xmlProjectPath,
  trackIndex,
  mogrtOptimalScale,
}: {
  timeline: TimelinePpro;
  chunks: CaptionChunk[];
  parameters: CaptionsParameters;
  timeOffset: number;
  xmlProjectPath: string;
  trackIndex: number;
  mogrtOptimalScale: number;
}): Promise<PrepareCaptionsXmlResult> => {
  const xmlName = 'captions_' + Date.now();

  const xmlDoc = await initCaptionXMLTemplate(xmlProjectPath);

  const templates = await resizeXmlTemplates(
    xmlDoc,
    timeline,
    mogrtOptimalScale,
  );
  const selectedTransitions = getSelectedTransitions(parameters);
  const position = getPosition({parameters, timeline});

  handleFloatingText({
    templates,
    parameters,
    sequenceSecondsEnd: chunks[chunks.length - 1].end - timeOffset,
  });

  const {addEmojiChunk, removeOriginalEmojiClip} = await handleEmojis({
    xmlDocument: xmlDoc,
  });

  let wordIndex = 0;

  for (const [chunkIndex, chunk] of chunks.entries()) {
    if (chunk.deleted) continue;

    const secondTimes = {
      start: chunk.start - timeOffset,
      end: chunk.end - timeOffset,
    };

    await addEmojiChunk(chunk, {
      emojiProperties: {
        position: {
          x: parameters.position.emojiXPercentage,
          y: parameters.position.emojiYPercentage,
        },
        fontSize: parameters.text.fontSize,
        size: chunk.emojiSize,
      },
    });

    handleTextPosition({
      chunk,
      parameters,
      templates,
      position,
    });

    handleTextTransitions({
      secondTimes,
      chunkIndex,
      templates,
      selectedTransitions,
    });

    await handleTextBackground({
      chunk,
      chunkIndex,
      secondTimes,
      parameters,
      templates,
      timeline,
    });

    const addedHighlights = await handleHighlights({
      chunk,
      parameters,
      timeOffset,
      templates,
      timeline,
      wordIndex,
    });

    wordIndex += addedHighlights;
  }

  handleCleanClips(templates);
  removeOriginalEmojiClip();

  const xmlSequence = handleResizeSequence({xmlDoc, timeline});

  handleRename({xmlDoc, xmlSequence, xmlName: xmlName});

  const lastChunk = chunks[chunks.length - 1];

  const prprojFilePath = await xmlDoc.exportToPrproj(xmlName);

  return {
    prprojFilePath,
    xmlName: xmlName.split('.')[0],
    trackIndex,
    secondTimes: {
      start: timeOffset,
      end: lastChunk.end,
    },
  };
};

import {IconComponent, IconComponentProps} from '../Icon';

export const IconTrash01 = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M16 6V3l-1-1H9L8 3v3m2 6v5m4-5v5M3 6h18m-2 0v14l-2 2H7l-2-2V6"
      />
    </IconComponent>
  );
};

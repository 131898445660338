import type {XMLDocument} from './_XMLDocument';
import type {XMLVideoMediaSource} from './_XMLMediaSource';

import {XMLProjectItem} from './_XMLProjectItem';

export class XMLBinProjectItem {
  private document: XMLDocument;
  public element: Element;
  public items: XMLProjectItem[];
  public lastIndex = 0;
  public name: string;
  public objectUID: string;

  constructor(element: Element, document: XMLDocument) {
    this.element = element;
    this.document = document;
    this.objectUID = element.getAttribute('ObjectUID') ?? '';
    this.name = element.querySelector('Name')?.textContent ?? '';

    this.items = this.getItems();
    this.lastIndex = this.items.length - 1;
  }

  private getItems() {
    const items = this.element.querySelectorAll('Items>Item');

    const projectItems = Array.from(items).map(item => {
      return new XMLProjectItem({
        refElement: item,
        document: this.document,
      });
    });

    return projectItems;
  }

  public cloneItem({
    newName,
    toCopyIndex = 0,
    newSource,
  }: {
    newName: string;
    toCopyIndex?: number;
    newSource?: XMLVideoMediaSource;
  }) {
    const toCopyItem = this.items[toCopyIndex];
    const newProjectItem = toCopyItem.clone({
      newObjectUID: this.document.getNewObjectUID(),
      newName,
      index: this.lastIndex + 1,
      newSource,
    });

    this.items.push(newProjectItem);
    this.lastIndex++;

    return newProjectItem;
  }
}

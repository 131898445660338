import {CaptionChunk, CaptionChunkHighlight} from '@autocut/types/Captions';
import {getHighlightColor} from './_highlights';
import {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import {PproSourceTextParam} from '../_formatSourceTextData';
import {rgbToInteger} from '@autocut/utils/color.utils';

export const getHighlightTextProperties = ({
  chunk,
  highlight,
  parameters,
}: {
  chunk: CaptionChunk;
  highlight: CaptionChunkHighlight;
  parameters: CaptionsParameters;
}) => {
  const highlightColor =
    parameters.animations.enabled && parameters.animations.highlight.enabled
      ? getHighlightColor({
          textColor: parameters.text.color,
          highlightColor: parameters.animations.highlight.color,
          unrevealedTextColor: parameters.animations.highlight.revealText
            .enabled
            ? parameters.animations.highlight.revealText.color
            : undefined,
          indexStart: highlight.indexStart,
          indexEnd: highlight.indexEnd,
        })
      : ([[0, rgbToInteger(parameters.text.color)]] as [number, number][]);

  const textProperties: PproSourceTextParam = {
    mTextParam: {
      mStyleSheet: {
        mText: chunk.text,
        mFillColor: {mParamValues: highlightColor},
      },
    },
  };

  return textProperties;
};

import {
  CAPTIONS_EMOJIS_FOLDER_PATH,
  CAPTIONS_FOLDER_PATH,
  CAPTIONS_RESOURCES_FOLDER_PATH,
} from '@autocut/enums/resources.enum';
import {CaptionChunk} from '@autocut/types/Captions';
import {preload} from '@autocut/types/ElectronPreload';
import {getResourceFolderPath} from '@autocut/utils/electron/app.electron.utils';
import {downloadFile, isFileExist} from '@autocut/utils/files.utils';
import {host} from '@autocut/utils/host';

/**
 * Download emojis from the given chunks to the resources folder.
 */
export const downloadEmojis = async (chunks: CaptionChunk[]) => {
  let hasCreatedFolders = false;
  const chunksWithEmojis = await Promise.all(
    chunks.map(async chunk => {
      if (!chunk.emoji) return chunk;
      if (!hasCreatedFolders) {
        await host.mediaPool.createFolders(['CAPTIONS', 'emojis']);
        hasCreatedFolders = true;
      }

      const emojiName = chunk.emoji;
      const emojiPath = preload().path.join(
        getResourceFolderPath(),
        CAPTIONS_FOLDER_PATH,
        CAPTIONS_RESOURCES_FOLDER_PATH,
        CAPTIONS_EMOJIS_FOLDER_PATH,
        emojiName,
      );
      if (!isFileExist(emojiPath)) {
        const emojiUrl = chunk.emojiUrl;
        if (!emojiUrl) return chunk;

        await downloadFile(emojiUrl, emojiPath);
      }
      await host.mediaPool.importFiles(['CAPTIONS', 'emojis'], [emojiPath]);

      return {...chunk, emojiPath};
    }),
  );

  return chunksWithEmojis;
};

import {Select} from '@autocut/designSystem/components/atoms/Select/Select';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import logLevel from '@autocut/enums/logLevel.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useLogger} from '@autocut/hooks/useLogger';
import {NeedingTranscriptionModes} from '@autocut/types/Transcription';
import {getLanguages, TranscriptLanguage} from '@autocut/utils/languages.utils';
import {setAutocutStore} from '@autocut/utils/zustand';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

export type TranscriptLanguageStepProps = {
  modeId?: NeedingTranscriptionModes;
};

export const TranscriptLanguageStep = ({
  modeId = 'caption',
}: TranscriptLanguageStepProps) => {
  const {logMessage} = useLogger('TranscriptLanguageStep');
  const intl = useIntl();

  const languageValues = React.useMemo(() => getLanguages(intl), [intl]);

  const {languageOfTranscription} = useAutoCutStore(state => ({
    languageOfTranscription: state.ui.parameters[modeId]
      .languageOfTranscription as TranscriptLanguage,
  }));

  useEffect(() => {
    if (languageOfTranscription) {
      const foundLanguage = languageValues.find(
        language => language.value === languageOfTranscription.value,
      );

      if (foundLanguage) {
        setAutocutStore(
          `ui.parameters.${modeId}.languageOfTranscription`,
          foundLanguage as any,
        );
      }
    }
  }, []);

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'modes_general_steps_transcription_title',
        defaultMessage: 'Select Audio Language',
      })}
      description={intl.formatMessage({
        id: 'modes_general_steps_transcription_description',
        defaultMessage: '',
      })}
    >
      <Select
        fullWidth
        options={languageValues}
        selected={languageOfTranscription.value}
        onChange={value => {
          logMessage(logLevel.notice, `audio language changed to ${value}`);
          if (value) {
            const option = languageValues.find(
              language => language.value === value,
            );
            if (option)
              setAutocutStore(
                `ui.parameters.${modeId}.languageOfTranscription`,
                option as any,
              );
          }
        }}
      />
    </FormSection>
  );
};

import React, {CSSProperties, HTMLAttributes} from 'react';

import css from './RadioButton.module.scss';
import FlexContainer from '../../molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {InputLabel} from '../../molecules/InputLabel/InputLabel';

export namespace RadioButton {
  export type Props<T> = {
    name: string;
    value: T;
    checked?: boolean;
    size?: number;
    disabled?: boolean;
  } & Omit<
    HTMLAttributes<HTMLInputElement>,
    'checked' | 'id' | 'value' | 'title'
  > &
    InputLabel.Props;
}

export const RadioButton = <T,>({
  name,
  title,
  description,
  size = 20,
  checked = false,
  value,
  onClick,
  disabled = false,
  ...props
}: RadioButton.Props<T>) => {
  return (
    <FlexContainer
      gap={Spacing.s1}
      alignItems="center"
      onClick={disabled ? undefined : onClick}
      data-disabled={disabled}
      className={css.container}
    >
      <div
        style={
          {
            '--size': `${size}px`,
          } as CSSProperties
        }
        className={css.inputContainer}
      >
        <input
          id={name}
          name={name}
          className={css.root}
          type="radio"
          checked={checked}
          {...props}
        />
        <div className={css.label}>
          <label
            className={css.dot}
            htmlFor={name}
          />
        </div>
      </div>
      {(title || description) && (
        <InputLabel
          title={title}
          description={description}
        />
      )}
    </FlexContainer>
  );
};

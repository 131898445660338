import bronzeBadgeUrl from '@autocut/assets/images/levelBadges/bronze.png';
import {match} from 'ts-pattern';
const bronzeBadge = new URL(bronzeBadgeUrl, import.meta.url).href;
import goldBadgeUrl from '@autocut/assets/images/levelBadges/gold.png';
const goldBadge = new URL(goldBadgeUrl, import.meta.url).href;
import ironBadgeUrl from '@autocut/assets/images/levelBadges/iron.png';
const ironBadge = new URL(ironBadgeUrl, import.meta.url).href;
import legendaryBadgeUrl from '@autocut/assets/images/levelBadges/legendary.png';
const legendaryBadge = new URL(legendaryBadgeUrl, import.meta.url).href;
import masterBadgeUrl from '@autocut/assets/images/levelBadges/master.png';
const masterBadge = new URL(masterBadgeUrl, import.meta.url).href;
import platinumBadgeUrl from '@autocut/assets/images/levelBadges/platinium.png';
const platinumBadge = new URL(platinumBadgeUrl, import.meta.url).href;
import silverBadgeUrl from '@autocut/assets/images/levelBadges/silver.png';
const silverBadge = new URL(silverBadgeUrl, import.meta.url).href;
import woodenBadgeUrl from '@autocut/assets/images/levelBadges/wood.png';
const woodenBadge = new URL(woodenBadgeUrl, import.meta.url).href;
import {AutocutMode, AutocutModes} from '@autocut/enums/modes.enum';

const HOURS_PER_WEEK = 168;

export const getBadgeForLevel = (level: number) => {
  const badge = match(level / HOURS_PER_WEEK)
    .when(
      weekGained => weekGained < 1 && level >= 3,
      () => ironBadge,
    )
    .when(
      weekGained => weekGained < 3,
      () => bronzeBadge,
    )
    .when(
      weekGained => weekGained < 12,
      () => silverBadge,
    )
    .when(
      weekGained => weekGained < 36,
      () => goldBadge,
    )
    .when(
      weekGained => weekGained < 108,
      () => platinumBadge,
    )
    .when(
      weekGained => weekGained < 324,
      () => masterBadge,
    )
    .when(
      weekGained => weekGained >= 324,
      () => legendaryBadge,
    )
    .otherwise(() => woodenBadge);

  return badge;
};

export const calculateXpGained = (
  timeTakenMS: number,
  mode: AutocutMode['id'] | string,
  nbTracks: number,
) => {
  const trackRatio = 1.5 * (nbTracks - 1) + 1;
  const modeRatio = match(mode)
    .with(AutocutModes.Legacy.id, () => 15)
    .otherwise(() => 0);

  if (modeRatio === 0) return 0;

  const calculatedTime = timeTakenMS * modeRatio * trackRatio;

  const hoursGained = (calculatedTime - timeTakenMS) / 3600000;

  const xpGained = Math.ceil(hoursGained * 100);

  return xpGained;
};

import {useState} from 'react';
import {getErrorInfos} from '../../utils/errors/errors.util';
import {IncrementalError} from '../../utils/errors/IncrementalError';
import {manageError} from '../../utils/manageError';
import {setAutocutStore} from '../../utils/zustand';
import {ModalType} from '@autocut/enums/modals.enum';
// import { handleBrollProcessTranscript } from '@autocut/modes/broll/Steps/Language/handleBrollProcessTranscript';
import {useNavigate} from 'react-router-dom';
import {
  NeedingTranscriptionModes,
  ProcessTranscriptFunctions,
} from '@autocut/types/Transcription';
import {useIntl} from 'react-intl';
import {handleCaptionProcessTranscript} from '@autocut/hooks/useHandleProcessTranscript/captions/handleCaptionsProcessTranscript';
import {handleChaptersProcessTranscript} from './chapters/handleChaptersProcessTranscript';
import {handleRepeatProcessTranscript} from '@autocut/pages/modes/repeat/Steps/Language/handleRepeatProcessTranscript';
import {handleViralClipsProcessTranscript} from '@autocut/hooks/useHandleProcessTranscript/viralClips/handleViralClipsProcessTranscript';
import {handleBRollsProcessTranscript} from '@autocut/hooks/useHandleProcessTranscript/brolls/handleBRollsProcessTranscript';

// TODO : transcriptions & other modes
export const processTranscriptFunctions: ProcessTranscriptFunctions = {
  caption: {
    process: handleCaptionProcessTranscript,
    navigateTo: '/caption/customization',
  },
  broll: {
    process: handleBRollsProcessTranscript,
    navigateTo: '/broll/choice',
  },
  chapters: {
    process: handleChaptersProcessTranscript,
    navigateTo: '/chapters/editor',
  },
  repeat: {
    process: handleRepeatProcessTranscript,
    navigateTo: '/repeat/transcript',
  },
  swear_word: {
    process: async () => {
      return;
    },
    navigateTo: '',
  },
  viral_clips: {
    process: handleViralClipsProcessTranscript,
    navigateTo: '/viral_clips/selector',
  },
};

export const useHandleProcessTranscript = <T extends NeedingTranscriptionModes>(
  mode: T,
  onSuccess?: () => void,
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const intl = useIntl();
  const navigate = useNavigate();

  const handleProcessTranscript = async (usedModel = 0) => {
    setAutocutStore('ui.openedModalName', ModalType.Processing);
    setAutocutStore('ui.process.isPaused', true);
    setAutocutStore('ui.currentTranscription.modeId', mode);
    setLoading(true);
    setError(null);

    try {
      const {process, navigateTo} = processTranscriptFunctions[mode];
      await process(intl, usedModel);

      if (navigateTo) {
        navigate(navigateTo);
      }

      setAutocutStore('ui.openedModalName', '');
      onSuccess?.();
    } catch (e: any) {
      const errorInfos = getErrorInfos(e);

      const formattedError = new IncrementalError(
        e,
        `handleTranscript ${mode}`,
      );

      if (errorInfos.id === 'EXPORT_CANCELLED') {
        //Recognized error
        setError('error_modal_text_exportCancelled');
      } else {
        //API returned error
        setError(formattedError.message || JSON.stringify(e));
      }

      manageError({
        error: formattedError,
      });
    } finally {
      setAutocutStore('ui.process.isPaused', false);
      setLoading(false);
    }
  };

  return {handleProcessTranscript, loading, error};
};

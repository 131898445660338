import {preload} from '@autocut/types/ElectronPreload';
import {ResourceManagerElementConfig} from '@autocut/types/ResourceManager';
import {
  getInstallResourceAtSpecificPathConfig,
  getResourceConfig,
} from '@autocut/utils/downloadableRessources.utils';
import {getResourceFolderPath} from '@autocut/utils/electron/app.electron.utils';
import axios from 'axios';

export const CAPTIONS_FOLDER_PATH = 'captions';
export const CAPTIONS_RESOURCES_FOLDER_PATH = 'resources';
export const CAPTIONS_EMOJIS_FOLDER_PATH = 'emojis';

export const resourcesConfigs = () =>
  ({
    ppro_extension: getInstallResourceAtSpecificPathConfig({
      resourceName: 'ppro_extension',
      mac: {
        extractSubfolder: 'com.autocut.ppro',
        folderPath: preload().path.join(
          preload()
            .electron.getPath('appData')
            .replace(/\/AutoCut[^\/]*(\/)?$/, ''),
          'Adobe',
          'CEP',
          'extensions',
        ),
        downloadUrl: preload().path.joinUrl(
          preload().electron.getSharedVariables().PPRO_DOWNLOAD_URL,
          'latest',
        ),
      },
      windows: {
        extractSubfolder: 'com.autocut.ppro',
        folderPath: preload().path.join(
          preload().os.homedir(),
          'AppData',
          'Roaming',
          'Adobe',
          'CEP',
          'extensions',
        ),
        downloadUrl: preload().path.joinUrl(
          preload().electron.getSharedVariables().PPRO_DOWNLOAD_URL,
          'latest',
        ),
      },
      createFolderIfNotExist: false,
      checkIfUpToDate: async versionFileContent => {
        const response = await axios.get(
          preload().electron.getSharedVariables().DAVINCI_DOWNLOAD_URL +
            '/version',
        );
        const data = response.data;
        const outdated = data.toString() !== versionFileContent.toString();
        return outdated;
      },
    }),
    lua_scripts: getInstallResourceAtSpecificPathConfig({
      resourceName: 'lua_scripts',
      mac: {
        versionFilename: 'version',
        folderPath: preload().path.join(getResourceFolderPath(), 'lua'),
        downloadUrl: preload().path.joinUrl(
          preload().electron.getSharedVariables().DAVINCI_DOWNLOAD_URL +
            'autocut-lua-remoteContent.zip',
        ),
      },
      windows: {
        versionFilename: 'version',
        folderPath: preload().path.join(getResourceFolderPath(), 'lua'),
        downloadUrl: preload().path.joinUrl(
          preload().electron.getSharedVariables().DAVINCI_DOWNLOAD_URL +
            '/autocut-lua-remoteContent.zip',
        ),
      },
      checkIfUpToDate: async versionFileContent => {
        const response = await axios.get(
          preload().path.joinUrl(
            preload().electron.getSharedVariables().DAVINCI_DOWNLOAD_URL +
              'version',
          ),
        );
        const data = response.data;
        const outdated = data.toString() !== versionFileContent.toString();
        return outdated;
      },
    }),
    da_vinci_lua_script: getInstallResourceAtSpecificPathConfig({
      resourceName: 'da_vinci_lua_script',
      mac: {
        versionFilename: 'AutoCut.lua',
        folderPath: preload().path.join(
          '/',
          'Library',
          'Application Support',
          'Blackmagic Design',
          'DaVinci Resolve',
          'Fusion',
          'Scripts',
          'Utility',
        ),
        downloadUrl: preload().path.joinUrl(
          preload().electron.getSharedVariables().DAVINCI_DOWNLOAD_URL +
            'autocut-lua-script.zip',
        ),
      },
      windows: {
        versionFilename: 'AutoCut.lua',
        folderPath: preload().path.join(
          'C:',
          'ProgramData',
          'Blackmagic Design',
          'DaVinci Resolve',
          'Fusion',
          'Scripts',
          'Utility',
        ),
        downloadUrl: preload().path.joinUrl(
          preload().electron.getSharedVariables().DAVINCI_DOWNLOAD_URL +
            'autocut-lua-script.zip',
        ),
      },
      createFolderIfNotExist: false,
      checkIfUpToDate: async versionFileContent => {
        const version = versionFileContent
          .split(/\r?\n/)[0]
          .match(/# version:(\d*)/)?.[1];

        if (!version) return true;

        const response = await axios.get(
          preload().path.joinUrl(
            preload().electron.getSharedVariables().DAVINCI_DOWNLOAD_URL +
              'version',
          ),
        );
        const data = response.data;

        const outdated = data.toString() !== version.toString();
        return outdated;
      },
    }),
    bleep_sound_1_wav: getResourceConfig({
      fileName: 'bleep_sound_1.wav',
      folderPath: 'audio',
    }),
    bleep_sound_2_wav: getResourceConfig({
      fileName: 'bleep_sound_2.wav',
      folderPath: 'audio',
    }),
    adjustment_layer_prproj: getResourceConfig({
      fileName: 'AutoCutBin.prproj',
    }),
    captions_xml_project: getResourceConfig({
      fileName: 'captions_xml_project_2.73.xml',
      folderPath: CAPTIONS_FOLDER_PATH,
    }),
    caption_resources: {
      ...getResourceConfig({
        fileName: 'captions_resources_2.71.1.zip',
        folderPath: CAPTIONS_FOLDER_PATH,
      }),
      existCheck: async () => {
        const {existCheck} = getResourceConfig({
          fileName: CAPTIONS_RESOURCES_FOLDER_PATH,
          folderPath: CAPTIONS_FOLDER_PATH,
        });

        return existCheck();
      },
      downloadMethod: async () => {
        const {downloadMethod} = getResourceConfig({
          fileName: 'captions_resources_2.71.1.zip',
          folderPath: CAPTIONS_FOLDER_PATH,
        });
        const zipFilePath = await downloadMethod();
        const unzipPath = preload().path.join(
          getResourceFolderPath(),
          CAPTIONS_FOLDER_PATH,
          CAPTIONS_RESOURCES_FOLDER_PATH,
        );
        await preload().resources.unzip(zipFilePath, unzipPath);

        return unzipPath;
      },
    },
    wav_epr: getResourceConfig({
      fileName: 'WAV_48_kHz_16_bits.epr',
    }),
  }) as const satisfies Record<string, ResourceManagerElementConfig>;

export type ResourceManagerKeys = keyof ReturnType<typeof resourcesConfigs>;

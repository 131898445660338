import {Locale} from '@autocut/contexts/LocaleContext';
import {Font} from '@autocut/types/font';
import {IntlShape} from 'react-intl';
import {autocutStoreVanilla} from './zustand';

const ARABIC_MAX_WORDS_PER_CHUNK = 15;

export type TranscriptLanguage = {
  label: string;
  value: string;
  modelOrder: number;
  textDirection?: string;
  unsupportedFeatures?: (
    font?: Font,
  ) => Record<string, {disabled: boolean; showWarning: boolean}>;
  maxWordsPerChunk?: number;
};

export const getLanguages = (intl: IntlShape): TranscriptLanguage[] =>
  [
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_french' as any,
        defaultMessage: 'French',
      }),
      value: 'fr',
      modelOrder: 0,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_english' as any,
        defaultMessage: 'English',
      }),
      value: 'en',
      modelOrder: 0,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_english_united_states' as any,
        defaultMessage: 'English - United States',
      }),
      value: 'en-US',
      modelOrder: 0,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_algeria' as any,
        defaultMessage: 'Arabic - Algeria',
      }),
      value: 'ar-DZ',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_bahrain' as any,
        defaultMessage: 'Arabic - Bahrain',
      }),
      value: 'ar-BH',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_egypt' as any,
        defaultMessage: 'Arabic - Egypt',
      }),
      value: 'ar-EG',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_iraq' as any,
        defaultMessage: 'Arabic - Iraq',
      }),
      value: 'ar-IQ',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_israel' as any,
        defaultMessage: 'Arabic - Israel',
      }),
      value: 'ar-IL',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_jordan' as any,
        defaultMessage: 'Arabic - Jordan',
      }),
      value: 'ar-JO',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_kuwait' as any,
        defaultMessage: 'Arabic - Kuwait',
      }),
      value: 'ar-KW',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_lebanon' as any,
        defaultMessage: 'Arabic - Lebanon',
      }),
      value: 'ar-LB',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_mauritania' as any,
        defaultMessage: 'Arabic - Mauritania',
      }),
      value: 'ar-MR',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_morocco' as any,
        defaultMessage: 'Arabic - Morocco',
      }),
      value: 'ar-MA',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_oman' as any,
        defaultMessage: 'Arabic - Oman',
      }),
      value: 'ar-OM',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_qatar' as any,
        defaultMessage: 'Arabic - Qatar',
      }),
      value: 'ar-QA',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_saudi_arabia' as any,
        defaultMessage: 'Arabic - Saudi Arabia',
      }),
      value: 'ar-SA',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_palestine' as any,
        defaultMessage: 'Arabic - Palestine',
      }),
      value: 'ar-PS',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_tunisia' as any,
        defaultMessage: 'Arabic - Tunisia',
      }),
      value: 'ar-TN',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_united_arab_emirates' as any,
        defaultMessage: 'Arabic - United Arab Emirates',
      }),
      value: 'ar-AE',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_yemen' as any,
        defaultMessage: 'Arabic - Yemen',
      }),
      value: 'ar-YE',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsArabic, showWarning: true},
        chapters: {disabled: true, showWarning: false},
        viralClips: {disabled: true, showWarning: false},
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_german' as any,
        defaultMessage: 'German',
      }),
      value: 'de',
      modelOrder: 1,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_hindi' as any,
        defaultMessage: 'Hindi',
      }),
      value: 'hi',
      modelOrder: 1,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_portuguese' as any,
        defaultMessage: 'language_selection_portuguese',
      }),
      value: 'pt',
      modelOrder: 1,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_portuguese_brazil' as any,
        defaultMessage: 'Portuguese - Brazil',
      }),
      value: 'pt-BR',
      modelOrder: 1,
    },

    {
      label: intl.formatMessage({
        id: 'language_selection_spanish' as any,
        defaultMessage: 'Spanish',
      }),
      value: 'es',
      modelOrder: 1,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_spanish_latin_america' as any,
        defaultMessage: 'Spanish - Latin America',
      }),
      value: 'es-419',
      modelOrder: 1,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_danish' as any,
        defaultMessage: 'Danish',
      }),
      value: 'da',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_dutch' as any,
        defaultMessage: 'Dutch',
      }),
      value: 'nl',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_flemish' as any,
        defaultMessage: 'Flemish',
      }),
      value: 'nl',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_italian' as any,
        defaultMessage: 'Italian',
      }),
      value: 'it',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_japanese' as any,
        defaultMessage: 'Japanese',
      }),
      value: 'ja',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_korean' as any,
        defaultMessage: 'Korean',
      }),
      value: 'ko',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_norwegian' as any,
        defaultMessage: 'Norwegian',
      }),
      value: 'no',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_polish' as any,
        defaultMessage: 'Polish',
      }),
      value: 'pl',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_swedish' as any,
        defaultMessage: 'Swedish',
      }),
      value: 'sv',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_tamil' as any,
        defaultMessage: 'Tamil',
      }),
      value: 'ta',
      modelOrder: 2,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_chinese' as any,
        defaultMessage: 'Chinese',
      }),
      value: 'zh',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_russian' as any,
        defaultMessage: 'Russian',
      }),
      value: 'ru',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_turkish' as any,
        defaultMessage: 'Turkish',
      }),
      value: 'tr',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_catalan' as any,
        defaultMessage: 'Catalan',
      }),
      value: 'ca',
      modelOrder: 3,
    },
    // {
    //   label: intl.formatMessage({
    //     id: 'language_selection_arabic'as any,
    //     defaultMessage: 'Arabic',
    //   }),
    //   value: 'ar',
    //   modelOrder: 3,
    // },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_indonesian' as any,
        defaultMessage: 'Indonesian',
      }),
      value: 'id',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_finnish' as any,
        defaultMessage: 'Finnish',
      }),
      value: 'fi',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_vietnamese' as any,
        defaultMessage: 'Vietnamese',
      }),
      value: 'vi',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_hebrew' as any,
        defaultMessage: 'Hebrew',
      }),
      value: 'he',
      modelOrder: 3,
      textDirection: 'rtl',
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: {disabled: !font?.supportsHebrew, showWarning: true},
      }),
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_ukrainian' as any,
        defaultMessage: 'Ukrainian',
      }),
      value: 'uk',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_greek' as any,
        defaultMessage: 'Greek',
      }),
      value: 'el',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_malay' as any,
        defaultMessage: 'Malay',
      }),
      value: 'ms',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_czech' as any,
        defaultMessage: 'Czech',
      }),
      value: 'cs',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_romanian' as any,
        defaultMessage: 'Romanian',
      }),
      value: 'ro',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_hungarian' as any,
        defaultMessage: 'Hungarian',
      }),
      value: 'hu',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_thai' as any,
        defaultMessage: 'Thai',
      }),
      value: 'th',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_urdu' as any,
        defaultMessage: 'Urdu',
      }),
      value: 'ur',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_croatian' as any,
        defaultMessage: 'Croatian',
      }),
      value: 'hr',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_bulgarian' as any,
        defaultMessage: 'Bulgarian',
      }),
      value: 'bg',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_lithuanian' as any,
        defaultMessage: 'Lithuanian',
      }),
      value: 'lt',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_maori' as any,
        defaultMessage: 'Maori',
      }),
      value: 'mi',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_malayalam' as any,
        defaultMessage: 'Malayalam',
      }),
      value: 'ml',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_welsh' as any,
        defaultMessage: 'Welsh',
      }),
      value: 'cy',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_slovak' as any,
        defaultMessage: 'Slovak',
      }),
      value: 'sk',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_telugu' as any,
        defaultMessage: 'Telugu',
      }),
      value: 'te',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_persian' as any,
        defaultMessage: 'Persian',
      }),
      value: 'fa',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_latvian' as any,
        defaultMessage: 'Latvian',
      }),
      value: 'lv',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_bengali' as any,
        defaultMessage: 'Bengali',
      }),
      value: 'bn',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_serbian' as any,
        defaultMessage: 'Serbian',
      }),
      value: 'sr',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_azerbaijani' as any,
        defaultMessage: 'Azerbaijani',
      }),
      value: 'az',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_slovenian' as any,
        defaultMessage: 'Slovenian',
      }),
      value: 'sl',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_kannada' as any,
        defaultMessage: 'Kannada',
      }),
      value: 'kn',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_estonian' as any,
        defaultMessage: 'Estonian',
      }),
      value: 'et',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_macedonian' as any,
        defaultMessage: 'Macedonian',
      }),
      value: 'mk',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_breton' as any,
        defaultMessage: 'Breton',
      }),
      value: 'br',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_basque' as any,
        defaultMessage: 'Basque',
      }),
      value: 'eu',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_icelandic' as any,
        defaultMessage: 'Icelandic',
      }),
      value: 'is',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_armenian' as any,
        defaultMessage: 'Armenian',
      }),
      value: 'hy',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_nepali' as any,
        defaultMessage: 'Nepali',
      }),
      value: 'ne',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_mongolian' as any,
        defaultMessage: 'Mongolian',
      }),
      value: 'mn',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_bosnian' as any,
        defaultMessage: 'Bosnian',
      }),
      value: 'bs',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_kazakh' as any,
        defaultMessage: 'Kazakh',
      }),
      value: 'kk',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_albanian' as any,
        defaultMessage: 'Albanian',
      }),
      value: 'sq',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_swahili' as any,
        defaultMessage: 'Swahili',
      }),
      value: 'sw',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_galician' as any,
        defaultMessage: 'Galician',
      }),
      value: 'gl',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_marathi' as any,
        defaultMessage: 'Marathi',
      }),
      value: 'mr',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_punjabi' as any,
        defaultMessage: 'Punjabi',
      }),
      value: 'pa',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_sinhala' as any,
        defaultMessage: 'Sinhala',
      }),
      value: 'si',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_khmer' as any,
        defaultMessage: 'Khmer',
      }),
      value: 'km',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_shona' as any,
        defaultMessage: 'Shona',
      }),
      value: 'sn',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_yoruba' as any,
        defaultMessage: 'Yoruba',
      }),
      value: 'yo',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_somali' as any,
        defaultMessage: 'Somali',
      }),
      value: 'so',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_afrikaans' as any,
        defaultMessage: 'Afrikaans',
      }),
      value: 'af',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_occitan' as any,
        defaultMessage: 'Occitan',
      }),
      value: 'oc',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_georgian' as any,
        defaultMessage: 'Georgian',
      }),
      value: 'ka',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_belarusian' as any,
        defaultMessage: 'Belarusian',
      }),
      value: 'be',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_tajik' as any,
        defaultMessage: 'Tajik',
      }),
      value: 'tg',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_sindhi' as any,
        defaultMessage: 'Sindhi',
      }),
      value: 'sd',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_gujarati' as any,
        defaultMessage: 'Gujarati',
      }),
      value: 'gu',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_amharic' as any,
        defaultMessage: 'Amharic',
      }),
      value: 'am',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_yiddish' as any,
        defaultMessage: 'Yiddish',
      }),
      value: 'yi',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_lao' as any,
        defaultMessage: 'Lao',
      }),
      value: 'lo',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_uzbek' as any,
        defaultMessage: 'Uzbek',
      }),
      value: 'uz',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_faroese' as any,
        defaultMessage: 'Faroese',
      }),
      value: 'fo',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_haitian_creole' as any,
        defaultMessage: 'Haitian Creole',
      }),
      value: 'ht',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_pashto' as any,
        defaultMessage: 'Pashto',
      }),
      value: 'ps',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_turkmen' as any,
        defaultMessage: 'Turkmen',
      }),
      value: 'tk',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_norwegian_nynorsk' as any,
        defaultMessage: 'Norwegian Nynorsk',
      }),
      value: 'nn',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_maltese' as any,
        defaultMessage: 'Maltese',
      }),
      value: 'mt',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_sanskrit' as any,
        defaultMessage: 'Sanskrit',
      }),
      value: 'sa',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_luxembourgish' as any,
        defaultMessage: 'Luxembourgish',
      }),
      value: 'lb',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_burmese' as any,
        defaultMessage: 'Burmese',
      }),
      value: 'my',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_tibetan' as any,
        defaultMessage: 'Tibetan',
      }),
      value: 'bo',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_tagalog' as any,
        defaultMessage: 'Tagalog',
      }),
      value: 'tl',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_malagasy' as any,
        defaultMessage: 'Malagasy',
      }),
      value: 'mg',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_assamese' as any,
        defaultMessage: 'Assamese',
      }),
      value: 'as',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_tatar' as any,
        defaultMessage: 'Tatar',
      }),
      value: 'tt',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_hawaiian' as any,
        defaultMessage: 'Hawaiian',
      }),
      value: 'haw',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_lingala' as any,
        defaultMessage: 'Lingala',
      }),
      value: 'ln',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_hausa' as any,
        defaultMessage: 'Hausa',
      }),
      value: 'ha',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_bashkir' as any,
        defaultMessage: 'Bashkir',
      }),
      value: 'ba',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_javanese' as any,
        defaultMessage: 'Javanese',
      }),
      value: 'jw',
      modelOrder: 3,
    },
    {
      textDirection: 'ltr',
      label: intl.formatMessage({
        id: 'language_selection_sundanese' as any,
        defaultMessage: 'Sundanese',
      }),
      value: 'su',
      modelOrder: 3,
    },
  ].sort((a, b) =>
    a.modelOrder > b.modelOrder
      ? 1
      : a.modelOrder < b.modelOrder
        ? -1
        : a.label.localeCompare(b.label),
  );

export const getPProLanguageCode = (language: Locale) => {
  switch (language) {
    case 'ES':
      return 'es_ES';
    case 'FR':
      return 'fr_FR';
    case 'JP':
      return 'ja_JP';
    case 'KR':
      return 'ko_KR';
    case 'PT':
      return 'pt_BR';
    default:
      return 'en_US';
  }
};

export const getHostNameForTranslation = () => {
  const hostName = autocutStoreVanilla().ui.host;
  switch (hostName) {
    case 'davinci':
      return 'DaVinci';
      break;
    case 'ppro':
      return 'Premiere Pro';
      break;
    default:
      return 'Unknown Host';
      break;
  }
};

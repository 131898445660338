import {resourcesConfigs} from '@autocut/enums/resources.enum';
import {measureTextOnCanvas} from '@autocut/pages/modes/captions/utils/measureTextOnCanvas';
import {CanvasFontParams, CaptionChunk} from '@autocut/types/Captions';
import {CaptionsParameters} from '@autocut/types/CaptionsParameters';

import {calculateLineMetrics} from './calculateLineMetrics';
import {calculateWordMetrics} from './calculateWordMetrics';
import {getFontParameters} from './getFontParameters';
import {getIsUppercase} from './getIsUppercase';

type CaptionsGlobalData = {
  parameters: Partial<CaptionsParameters> & Pick<CaptionsParameters, 'text'>;
  chunks: CaptionChunk[];
};

export type CaptionsDavinciData = {
  fontReferenceWidth: number;
};

export type CaptionsPremiereData = {
  xmlFilePath: string;
  resourceFilePath: string;
};

const fontReferenceText = 'AutoCut';

export const prepareCaptionsDavinci = ({
  parameters,
  chunks,
}: {
  parameters: Partial<CaptionsParameters> & Pick<CaptionsParameters, 'text'>;
  chunks: CaptionChunk[];
}): CaptionsGlobalData & CaptionsDavinciData => {
  const fontFamily = parameters.text.font.fontFamily;
  const fontSize = parameters.text.fontSize;
  const fontParameters: CanvasFontParams = {
    italic: false,
    fontSize: fontSize,
    fontFamily,
  };

  const fontReferenceData = measureTextOnCanvas(
    fontReferenceText,
    fontParameters,
    false,
  );

  return {
    parameters,
    chunks,
    fontReferenceWidth: fontReferenceData.metrics.width,
  };
};

export const prepareCaptionsPremiere = async ({
  parameters,
  chunks,
}: {
  parameters: CaptionsParameters;
  chunks: CaptionChunk[];
}): Promise<CaptionsGlobalData & CaptionsPremiereData> => {
  /**
   * Download resources
   */
  let xmlFilePath = '';
  const xmlConfig = resourcesConfigs().captions_xml_project;
  const {isExist: isXmlExist, outputFilePath: xmlOutputFilePath} =
    await xmlConfig.existCheck();
  if (isXmlExist) {
    xmlFilePath = xmlOutputFilePath;
  } else {
    xmlFilePath = await xmlConfig.downloadMethod();
  }

  let resourceFilePath = '';
  const resourceConfig = resourcesConfigs().caption_resources;
  const {isExist: isResourceExist, outputFilePath: resourceOutputFilePath} =
    await resourceConfig.existCheck();
  if (isResourceExist) {
    resourceFilePath = resourceOutputFilePath;
  } else {
    resourceFilePath = await resourceConfig.downloadMethod();
  }

  const {
    metrics: {
      height: maxLineHeight,
      actualBoundingBoxDescent: maxLineDescentHeight,
    },
  } = measureTextOnCanvas('Éj', getFontParameters(parameters), false);

  const updatedParameters: CaptionsParameters = {
    ...parameters,
    text: {
      ...parameters.text,
      maxLineHeight,
      maxLineDescentHeight,
    },
    formating: {
      ...parameters.formating,
      uppercase: getIsUppercase(parameters),
    },
  };

  const {chunks: chunksWithLineMetrics} = calculateLineMetrics({
    chunks,
    parameters: updatedParameters,
  });

  const {chunks: chunksWithWordMetrics} = await calculateWordMetrics({
    chunks: chunksWithLineMetrics,
    parameters: updatedParameters,
  });

  return {
    parameters: updatedParameters,
    chunks: chunksWithWordMetrics,
    xmlFilePath,
    resourceFilePath,
  };
};

import {ProcessTranscriptFunction} from '@autocut/types/Transcription';
import {compute} from '@autocut/utils/compute.utils';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {getParametersForMode} from '@autocut/utils/parameters.utils';
import {
  addProcessSteps,
  endProcessStep,
  initProcessProgress,
  startProcessProgress,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {getTranscriptionLanguage} from '@autocut/utils/transcript/getTranscriptionLanguage';
import {setAutocutStore} from '@autocut/utils/zustand';

export const handleChaptersProcessTranscript: ProcessTranscriptFunction =
  async (intl, usedModel = 0) => {
    try {
      const processProgress = initProcessProgress(
        'chapters_transcript',
        'progress_steps_transcript_mainProcess',
      );
      addProcessSteps(processProgress, [
        {
          id: 'chapters_transcript_get_language',
          translationKey: 'progress_steps_chapters_transcript_get_language',
          progress: 1,
        },
        {
          id: 'chapters_transcript_mainProcess',
          translationKey: 'progress_steps_transcript_mainProcess',
          progress: '',
          countFor: 4,
        },
      ]);

      startProcessProgress(processProgress);

      const parameters = getParametersForMode('chapters');

      const language = getTranscriptionLanguage({
        languageOfTranscriptionValue: parameters.languageOfTranscription.value,
        usedModel,
      });

      endProcessStep(processProgress, 'chapters_transcript_get_language');

      const transcriptProcess = compute.process.getChapters({
        skipEmbedding: true,
        language,
      });

      updateProcessStep(processProgress, 'chapters_transcript_mainProcess', {
        progress: {
          computeTaskId: transcriptProcess.requestId,
        },
      });

      const result = await transcriptProcess;

      setAutocutStore('onGoingProcess.chapters', result);

      endProcessStep(processProgress, 'captions_transcript_mainProcess');
    } catch (error: any) {
      throw new IncrementalError(error, 'handleChaptersProcessTranscript');
    }
  };

import type {XMLDocument} from './_XMLDocument';

import './_prototypes';

export class XMLVideoFilterComponent {
  private parentComponent: Element;
  private parentDocument: XMLDocument;
  private videoFilterComponent: Element | undefined;
  public objectId: string;
  public videoFilterComponentName: string;
  public videoFilterComponentParams: {
    param: Element | undefined | null;
    paramName: string;
    paramComponent: Element | undefined | null;
  }[];

  constructor(
    parentComponent: Element,
    videoFilterComponent: Element | undefined,
    videoFilterComponentParams: {param: Element; paramComponent: Element}[],
    parentDocument: XMLDocument,
  ) {
    this.parentComponent = parentComponent;
    this.objectId = parentComponent.getAttribute('ObjectRef') ?? '';

    this.videoFilterComponent = videoFilterComponent;

    this.videoFilterComponentName =
      videoFilterComponent?.querySelector('DisplayName')?.textContent ?? '';
    const params = Array.from(
      videoFilterComponent?.querySelectorAll('Param') ?? [],
    ).map(param => {
      const paramObjectRef = param.getAttribute('ObjectRef') ?? '';
      const correspondingParamComponent = Array.from(
        videoFilterComponentParams,
      ).find(({param}) => {
        const vfParamObjectRef = param.getAttribute('ObjectRef') ?? '';
        return paramObjectRef === vfParamObjectRef;
      })?.paramComponent;

      const paramName =
        correspondingParamComponent?.querySelector('Name')?.textContent ?? '';

      return {
        param,
        paramName,
        paramComponent: correspondingParamComponent,
      };
    });

    this.videoFilterComponentParams = params;

    this.parentDocument = parentDocument;
  }

  public clone(parentComponent: Element) {
    const newVideoFilterComponentId = this.parentDocument.getNewObjectId();
    parentComponent.setAttribute('ObjectRef', newVideoFilterComponentId);
    const newVideoFilterComponent =
      this.videoFilterComponent?.cloneNodeWithNewId(newVideoFilterComponentId);

    const params = newVideoFilterComponent?.querySelectorAll('Param');
    const newVideoComponentParams = this.videoFilterComponentParams?.map(
      ({param, paramComponent}) => {
        const newParamId = this.parentDocument.getNewObjectId();

        const correspondingParam = Array.from(params ?? []).find(vfParam => {
          const vfParamObjectRef = vfParam.getAttribute('ObjectRef') ?? '';
          const paramObjectRef = param?.getAttribute('ObjectRef') ?? '';
          return paramObjectRef === vfParamObjectRef;
        }) as Element;
        correspondingParam?.setAttribute('ObjectRef', newParamId);

        const newVideoComponentParam = paramComponent?.cloneNodeWithNewId(
          newParamId,
        ) as Element;

        return {
          param: correspondingParam,
          paramComponent: newVideoComponentParam,
        };
      },
    );

    return new XMLVideoFilterComponent(
      parentComponent,
      newVideoFilterComponent,
      newVideoComponentParams,
      this.parentDocument,
    );
  }

  public exists() {
    return !!this.parentDocument.findElementWithObjectId(
      ['TrackItem'],
      this.objectId,
    );
  }

  public remove() {
    this.parentComponent.remove();
    this.videoFilterComponent?.remove();
    this.videoFilterComponentParams.forEach(({paramComponent}) => {
      paramComponent?.remove();
    });
  }
}

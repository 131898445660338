import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {useNavigate} from 'react-router-dom';
import {BRollsSizeStep} from './Parts/BRollsSizeStep';
import {colors} from '@autocut/designSystem/colors';

export const BRollsParameters = () => {
  const navigate = useNavigate();

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({buttonRef, isLoading, isDisabled}) => (
            <Button
              color={colors.primary600}
              ref={buttonRef}
              isLoading={isLoading}
              disabled={isDisabled}
              onClick={() => navigate('../language')}
            >
              <TranslatedMessage
                id="modes_broll_steps_parameters_cta"
                defaultMessage="Next"
              />
            </Button>
          )}
        />
      }
    >
      <BRollsSizeStep />
    </ModeLayout>
  );
};

import {CSSProperties, forwardRef} from 'react';

import css from './Card.module.scss';
import {CardVariant} from './variants';

export type CardProps = {
  children: React.ReactNode;
  selected?: boolean;
  variant?: CardVariant;
  hoverable?: boolean;
  onClick?: () => void;
  color?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      selected = false,
      variant,
      hoverable,
      onClick,
      color,
      style,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        data-variant={variant}
        className={`${className} ${css.root} ${hoverable ? css.hoverable : ''} ${selected ? css.selected : ''}`}
        onClick={onClick}
        style={
          {
            '--card-color': color,
            ...style,
          } as CSSProperties
        }
        {...props}
      >
        {children}
      </div>
    );
  },
);

import {ProcessTranscriptFunction} from '@autocut/types/Transcription';
import {compute} from '@autocut/utils/compute.utils';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {getParametersForMode} from '@autocut/utils/parameters.utils';
import {
  addProcessSteps,
  endProcessStep,
  initProcessProgress,
  startProcessProgress,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {getTranscriptionLanguage} from '@autocut/utils/transcript/getTranscriptionLanguage';
import {setAutocutStore} from '@autocut/utils/zustand';

export const handleBRollsProcessTranscript: ProcessTranscriptFunction = async (
  intl,
  usedModel = 0,
) => {
  try {
    const processProgress = initProcessProgress(
      'broll_transcript',
      'progress_steps_transcript_mainProcess',
    );
    addProcessSteps(processProgress, [
      {
        id: 'captions_transcript_get_language',
        translationKey: 'progress_steps_caption_transcript_get_language',
        progress: 1,
      },
      {
        id: 'broll_transcript_mainProcess',
        translationKey: 'progress_steps_transcript_mainProcess',
        progress: '',
        countFor: 4,
      },
    ]);

    startProcessProgress(processProgress);

    const parameters = getParametersForMode('broll');

    const language = getTranscriptionLanguage({
      languageOfTranscriptionValue: parameters.languageOfTranscription.value,
      usedModel,
    });

    endProcessStep(processProgress, 'captions_transcript_get_language');

    const transcriptProcess = compute.process.getBRolls({
      skipEmbedding: true,
      language: {
        ...language,
        maxWordsPerChunk: parameters.languageOfTranscription.maxWordsPerChunk,
      },
      brollsParameters: {
        maxDuration: parameters.maximumBRollTime,
        minDuration: parameters.minimumBRollTime,
      },
    });

    updateProcessStep(processProgress, 'broll_transcript_mainProcess', {
      progress: {
        computeTaskId: transcriptProcess.requestId,
      },
      countFor: 4, // Number of steps in compute
    });

    const result = await transcriptProcess;
    const timelineInfos = await compute.timeline.getInfos();

    setAutocutStore('onGoingProcess.bRolls', result);
    setAutocutStore('onGoingProcess.timelineInfos', timelineInfos);

    endProcessStep(processProgress, 'broll_transcript_mainProcess');
  } catch (error: any) {
    throw new IncrementalError(error, 'handleChaptersProcessTranscript');
  }
};

import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {convertFramesToSeconds} from '@autocut/utils/frames';
import {host} from '@autocut/utils/host';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

export const InOutStep = () => {
  const intl = useIntl();

  const [inOutPoints, setInOutPoints] = useState<
    {inPoint: string; outPoint: string} | undefined
  >();
  const [duration, setDuration] = useState<string | undefined>();

  const getInOutPoints = async () => {
    const result = await host.timeline.getInOut();
    const resultInSeconds = (
      await convertFramesToSeconds([[result.inPoint, result.outPoint]])
    )[0];
    setInOutPoints({
      inPoint: resultInSeconds[0].toFixed(2),
      outPoint: resultInSeconds[1].toFixed(2),
    });
    setDuration((resultInSeconds[1] - resultInSeconds[0]).toFixed(2));
  };

  useEffect(() => {
    void getInOutPoints();
  }, []);

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'modes_resize_steps_inOutPoints_title',
        defaultMessage: 'In/Out Points',
      })}
      description={intl.formatMessage({
        id: 'modes_resize_steps_inOutPoints_description',
        defaultMessage:
          'Please check your in & out points in your timeline. They should be the same as the values below.',
      })}
    >
      <PaddedSection>
        <FlexContainer
          flexDirection="column"
          gap={Spacing.s2}
        >
          <Text variant="textXs.semibold">
            <TranslatedMessage
              id="modes_resize_steps_inOutPoints_items_in"
              defaultMessage="In: {inPoint} seconds"
              values={{inPoint: inOutPoints?.inPoint}}
            />
          </Text>
          <Text variant="textXs.semibold">
            <TranslatedMessage
              id="modes_resize_steps_inOutPoints_items_out"
              defaultMessage="Out: {outPoint} seconds"
              values={{outPoint: inOutPoints?.outPoint}}
            />
          </Text>
          <Text variant="textXs.semibold">
            <TranslatedMessage
              id="modes_resize_steps_inOutPoints_items_duration"
              defaultMessage="Duration: {duration} seconds"
              values={{duration}}
            />
          </Text>
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};

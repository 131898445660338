import {rgbToInteger} from '@autocut/utils/color.utils';
import {RgbColor} from 'react-colorful';

export const getHighlightColor = ({
  highlightColor,
  textColor,
  unrevealedTextColor,
  indexStart,
  indexEnd,
}: {
  highlightColor: RgbColor;
  textColor: RgbColor;
  unrevealedTextColor?: RgbColor;
  indexStart: number;
  indexEnd: number;
}) => {
  const colors: [number, number][] =
    indexStart === 0 ? [] : [[0, rgbToInteger(textColor)]];
  colors.push([indexStart, rgbToInteger(highlightColor)]);
  colors.push([indexEnd + 1, rgbToInteger(unrevealedTextColor ?? textColor)]);

  return colors;
};

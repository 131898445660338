import {Button} from '@autocut/components/Button/Button';
import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {TranscriptLanguageStep} from '@autocut/components/TranscriptLanguageStep/TranscriptLanguageStep';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {LANGUAGE_TO_MODEL} from '@autocut/constants/languageToModel';
import {Alert} from '@autocut/designSystem/components/atoms/Alert/Alert';
import {IconInfoCircle} from '@autocut/designSystem/components/atoms/Icon/general/IconInfoCircle';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {BleepFileEnum} from '@autocut/enums/bleepFile.enum';
import {resourcesConfigs} from '@autocut/enums/resources.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {compute} from '@autocut/utils/compute.utils';
import {handleProcessBase} from '@autocut/utils/process/handleProcessBase';
import {
  endProcessStep,
  updateProcessStep,
} from '@autocut/utils/process/progress';

import BleepSoundChoiceStep from './Parts/BleepSoundChoiceStep/BleepSoundChoiceStep';

export const SwearWordsCustomization = () => {
  const {parameters} = useAutoCutStore(state => ({
    parameters: state.ui.parameters.swear_word,
  }));

  const handleClick = handleProcessBase(
    {
      executeProcess: async (_, progress) => {
        let bleepFilePath;
        let bleepFileName;
        if (parameters.bleepFile !== BleepFileEnum.Muted) {
          const bleepRessource =
            resourcesConfigs()[
              parameters.bleepFile as 'bleep_sound_1_wav' | 'bleep_sound_2_wav'
            ];
          bleepFileName = bleepRessource.fileName;
          const {isExist, outputFilePath} = await bleepRessource.existCheck();
          if (isExist) {
            bleepFilePath = outputFilePath;
          } else {
            bleepFilePath = await bleepRessource.downloadMethod();
          }
        }

        const correspondingLanguage =
          LANGUAGE_TO_MODEL[parameters.languageOfTranscription.value];
        const userOptions = correspondingLanguage[0]; // TODO : Implémenter le switch de provider de stt en cas d'erreur du premier

        const swearWordsProcess = compute.process.swearWords({
          userOptions: {
            ...userOptions,
            value: parameters.languageOfTranscription.value,
          },
          bleepFilePath,
          bleepFileName,
        });

        updateProcessStep(progress, 'swearWords_mainProcess', {
          progress: {
            computeTaskId: swearWordsProcess.requestId,
          },
          countFor: 5,
        });

        const result = await swearWordsProcess;

        endProcessStep(progress, 'swearWords_mainProcess');

        return result;
      },
    },
    {
      processTitleNameKey: 'modes_swearWords_title',
      processSteps: [
        {
          id: 'swearWords_mainProcess',
          translationKey: 'progress_steps_swearWords_mainProcess',
          progress: '',
        },
      ],
    },
  );

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({buttonRef, isLoading, isDisabled}) => (
            <Button
              ref={buttonRef}
              isLoading={isLoading}
              disabled={isDisabled}
              onClickFunction={handleClick}
            >
              <TranslatedMessage
                id="modes_swearWords_footerButton"
                defaultMessage="Remove profanities"
              />
            </Button>
          )}
        />
      }
    >
      <FlexContainer
        style={{paddingBottom: Spacing.s20}}
        flexDirection="column"
        gap={Spacing.s4}
      >
        <Alert
          icon={
            <IconInfoCircle
              size={18}
              color="gray.300"
            />
          }
        >
          <TranslatedMessage
            id="modes_swearWords_infoText"
            defaultMessage="Swear Words Bleeper, thanks to AI, analyzes your audio and automatically adds {br} bleeping sounds on top of profanities."
          />
        </Alert>

        <TranscriptLanguageStep modeId="swear_word" />
        <BleepSoundChoiceStep />
      </FlexContainer>
    </ModeLayout>
  );
};

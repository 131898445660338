import type {XMLDocument} from './_XMLDocument';

import './_prototypes';

import type {XMLTrackItem} from './_XMLTrackItem';

export class XMLTrackItemMasterClip {
  private element: Element | undefined;
  private mediaObject: Element | undefined | null;
  private objectUID: string;
  private parentDocument: XMLDocument;
  private sourceObject: Element | undefined | null;
  private videoClipObject: Element | undefined | null;
  private videoStreamObject: Element | undefined | null;

  constructor({
    trackItem,
    objectUID,
  }: {
    trackItem: XMLTrackItem;
    objectUID: string;
  }) {
    this.objectUID = objectUID;
    this.parentDocument = trackItem.parentDocument;

    this.element = this.parentDocument.findElementWithObjectId(
      ['MasterClip'],
      objectUID,
    );

    const clipRef =
      this.element?.querySelector('Clip')?.getAttribute('ObjectRef') ?? '';
    const clipObject = this.parentDocument.findElementWithObjectId(
      ['VideoClip'],
      clipRef,
    );
    this.videoClipObject = clipObject;

    const sourceRef =
      clipObject?.querySelector('Source')?.getAttribute('ObjectRef') ?? '';
    const sourceObject = this.parentDocument.findElementWithObjectId(
      ['VideoMediaSource'],
      sourceRef,
    );
    this.sourceObject = sourceObject;

    const mediaRef =
      sourceObject?.querySelector('Media')?.getAttribute('ObjectURef') ?? '';
    const mediaObject = this.parentDocument.findElementWithObjectId(
      ['Media'],
      mediaRef,
    );
    this.mediaObject = mediaObject;

    const videoStreamRef =
      mediaObject?.querySelector('VideoStream')?.getAttribute('ObjectRef') ??
      '';
    const videoStreamObject = this.parentDocument.findElementWithObjectId(
      ['VideoStream'],
      videoStreamRef,
    );
    this.videoStreamObject = videoStreamObject;
  }

  public resize([sequenceWidth, sequenceHeight]: [number, number]) {
    const frameRectElement = this.videoStreamObject?.querySelector('FrameRect');

    if (!frameRectElement) return false;

    const newFrameRectTextContent = `0,0,${sequenceWidth},${sequenceHeight}`;

    frameRectElement.textContent = newFrameRectTextContent;

    return true;
  }
}

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import React, {useRef, useState} from 'react';
import {MdOutlineEmail} from 'react-icons/md';
import {useIntl} from 'react-intl';

import css from './Email.module.css';
import {askForTrialKey} from '@autocut/utils/keys/askForTrialKey.util';
import {useLocale} from '@autocut/hooks/useLocale';
import {useNavigate} from 'react-router-dom';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Input} from '@autocut/components/Inputs/Input';
import {FooterLayout} from '@autocut/components/FooterLayout';
import {Button} from '@autocut/components/Button/Button';
import {z} from 'zod';

export type EmailProps = {
  setTrialEmail: (email: string) => void;
};

export const Email = ({setTrialEmail}: EmailProps) => {
  const intl = useIntl();
  const {locale} = useLocale();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const emailValidator = z
    .string()
    .min(1, 'Required')
    .email('Invalid email address');

  const validateEmail = (value: string) => {
    try {
      emailValidator.parse(value);
      return undefined;
    } catch (err: any) {
      return err.errors[0].message;
    }
  };

  const handleAskForTrial = async () => {
    const emailError = validateEmail(email);
    if (emailError !== undefined) {
      setError(emailError as string);
      return;
    }

    setError('');

    try {
      const result = await askForTrialKey(email, locale);

      if (result) {
        setTrialEmail(email);
        navigate('../sending-email');
      }
    } catch (error: any) {
      setError(
        intl.formatMessage({id: error.message, defaultMessage: error.message}),
      );
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      void handleAskForTrial();
    }
  };

  return (
    <FlexContainer
      flexDirection="column"
      gap={48}
    >
      <FlexContainer
        flexDirection="column"
        gap={12}
      >
        <FlexContainer
          alignItems="center"
          gap={8}
          className={css.title}
        >
          <TranslatedMessage
            id="onboarding_auth_trial_steps_email_title"
            defaultMessage="Enter your email"
          />
          <div className={css.emailContainer}>
            <MdOutlineEmail
              size={20}
              color="white"
            />
          </div>
        </FlexContainer>
        <FlexContainer
          flexDirection="column"
          gap={8}
          className={css.subtitle}
        >
          <TranslatedMessage
            id="onboarding_auth_trial_steps_email_subtitle"
            defaultMessage="Enter your email address and receive a free trial license"
          />
          <Input
            placeholder={intl.formatMessage({
              id: 'onboarding_auth_trial_steps_email_placeholder',
              defaultMessage: 'email@email.com',
            })}
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            inputSize="md"
          />
        </FlexContainer>
      </FlexContainer>
      <FooterLayout>
        <div className={css.buttonContainer}>
          <div className={css.errorMessage}>{error}</div>

          <Button
            onClickFunction={handleAskForTrial}
            weight="bold"
          >
            <TranslatedMessage
              id="onboarding_auth_trial_steps_email_cta"
              defaultMessage="Next"
            />
          </Button>
        </div>
      </FooterLayout>
    </FlexContainer>
  );
};

const translations = {
  progress: {
    footer: {
      warning:
        'AutoCut 작업 중에 {host}를 사용하지 않도록 하여 잠재적인 문제를 방지하세요.',
    },
    steps: {
      global: {
        starting: '시작 중...',
        processing: '처리 중...',
        ending: '마무리 중...',
      },
      dev: {
        ping: '{host} 핑...',
        pong: '{host} 퐁...',
      },
      common: {
        getSilences: '무음 구간 식별 중...',
        computeRMS: '오디오 파일 분석 중...',
        getDbValuesBetween: {
          getAudioInfos: '오디오 세부 정보 수집 중...',
          readAudioFile: '오디오 파일 로드 중...',
          convertRMSToDB: '오디오 파일 분석 중...',
        },
        timeline: {
          prepare: '타임라인 준비 중...',
          import: {
            writeXml: '업데이트된 타임라인 가져오기 중...',
            updateDrt: '업데이트된 타임라인 가져오기 중...',
            importXml: '업데이트된 타임라인 가져오기 중...',
          },
        },
        appendAdjustmentLayers: '조정 레이어 추가...',
      },
      repeat: {
        mainProcess: '로드 중...',
        transcript: {
          getLanguage: '언어 감지 중...',
        },
        getRepeatUtterances: '컷 타임코드 처리 중...',
        cutAtTimecodes: '컷 실행 중...',
      },
      resize: {
        mainProcess: '시퀀스 크기 조정 중...',
        prepareTimeline: '타임라인 준비 중...',
        createNewTimeline: '새로운 타임라인 생성 중...',
        reframeTimeline: '타임라인 재구성 중...',
        resizeTimeline: '타임라인 크기 조정 중...',
        handleWatermark: '워터마크 추가 중...',
      },
      podcast: {
        mainProcess: '팟캐스트 편집 중...',
        getVideoTracksWeight: '비디오 트랙 설정 중...',
        getAudioTracks: '오디오 트랙 설정 중...',
        getPodcastInterval: '팟캐스트 시간 추정 중...',
        getPodcastDuration: '팟캐스트 길이 계산 중...',
        getVideoTrack: '비디오 트랙 설정 중...',
        renders: '{host}에서 오디오 렌더링 중\n(몇 분 소요될 수 있습니다)',
        getTalkingTimelines: '발언 시간 할당 중...',
        getTransitions: '카메라 전환 계획 중...',
        getCutTimecodes: '컷 포인트 준비 중...',
        cutAtTimecodes: '컷 실행 중...',
      },
      silences: {
        mainProcess: '무음 제거 중...',
        getTimelineInfos: '타임라인 정보 가져오는 중...',
        renders: '{host}에서 오디오 렌더링 중\n(몇 분 소요될 수 있습니다)',
        convertAudioToMono: '오디오 설정 중...',
        getCutTimecodes: '컷 포인트 설정 중...',
        cutAtTimecodes: '컷 실행 중...',
        deleteSilentTrackItems: '무음 구간 삭제 중...',
        applyTransitions: '전환 적용 중...',
      },
      swearWords: {
        mainProcess: '비속어 검열 중...',
        prepareTimeline: '타임라인 준비 중...',
        getSwearWords: '전사 분석 중...',
        cutAndApplyVolume: '볼륨 필터 적용 중...',
        applyBleeps: '삑 소리 삽입 중...',
        getTranscript: '전사 생성 중...',
      },
      zoom: {
        mainProcess: '줌 효과 적용 중...',
        getTimelineInfos: '타임라인 정보 가져오는 중...',
        getWavData: '{host}에서 오디오 렌더링 중\n(몇 분 소요될 수 있습니다)',
        computeBestZoomIntervals: '최적의 줌 간격 계산 중...',
        createVideoTrack: '새 비디오 트랙 생성 중...',
        createZoomFusionComps: '줌 컴포넌트 가져오는 중...',
      },
      chapters: {
        transcript: {
          get_language: '언어 감지 중...',
          generateChapters: '챕터 생성 중...\n(몇 분 정도 소요될 수 있습니다)',
        },
        generation: {
          mainProcess: '챕터 추가 중...',
          importMarkers: '마커 가져오는 중...',
        },
      },
      transcript: {
        mainProcess: '전사 생성 중...',
        renderWav: '{host}에서 오디오 렌더링 중 (몇 분 소요될 수 있습니다)',
        uploadFile: '파일 업로드 중...',
        getTranscript:
          'AI를 사용하여 오디오 전사 중 (몇 분 소요될 수 있습니다)',
      },
      caption: {
        transcript: {
          get_language: '언어 감지 중...',
          split_chunks: '전사를 세그먼트로 나누는 중...',
        },
        generation: {
          mainProcess: '자막 추가 중...',
          initialization: '자막 초기화 중...',
          readTranscript: '전사 로드 중...',
          getTimelineInfos: '타임라인 세부 정보 가져오는 중...',
          appendAdjustmentLayer: '조정 레이어 추가 중...',
          importFusionComp: '자막 컴포넌트 가져오는 중...',
          prepareTimeline: '타임라인 준비 중...',
          generateSequence: '시퀀스를 생성하는 중...',
          importSequence: '시퀀스를 가져오는 중...',
          getSequenceParameters: '시퀀스 매개변수 가져오는 중...',
          createFiles: '파일 생성 중...',
        },
      },
      viralClips: {
        mainProcess: '바이럴 클립 생성 중...',
        transcript: {
          generateViralClips: '바이럴 클립 생성 중...',
        },
        importingClips: '바이럴 클립 가져오는 중...',
        generation: {
          viralClips: '바이럴 클립 생성 중...',
        },
      },
      broll: {
        mainProcess: 'B-Roll 추가 중...',
        getBRolls: 'B-Roll 가져오는 중...',
        downloadingBrolls: 'B-Roll 다운로드 중...',
        importingBrolls: 'B-Roll 가져오는 중...',
        insertingBrolls: 'B-Roll 삽입 중...',
      },
    },
  },
  launchManager: {
    title: '설치 / 업데이트',
    steps: {
      preload: {
        title: '로드 중',
      },
      update: {
        title: '업데이트 확인 중',
        modal: {
          title: 'AutoCut 업데이트됨',
          subtitle: 'AutoCut이 업데이트되었습니다',
          body: 'AutoCut을 향상된 경험으로 업데이트했습니다. 아래 버튼을 클릭하여 AutoCut을 다시 시작하세요.',
          cta: 'AutoCut 다시 시작',
        },
      },
      ffmpeg: {
        title: 'FFMPEG 설치 중',
      },
      resources: {
        title: '리소스 설치 중',
      },
      computeIPC: {
        title: 'AI 서비스에 연결 중',
      },
      hostIPC: {
        title: 'AutoCut 실행하기',
        subtitle: '찾을 수 없나요? Premiere 또는 DaVinci를 다시 시작하세요',
        stillNot:
          'Premiere Pro, DaVinci & AutoCut을 다시 시작한 후에도 찾을 수 없나요?{br}<copy><embedTranslation>globals_contactMail</embedTranslation></copy>로 문의하세요',
        instructions: {
          ppro: {
            part1: 'AutoCut 확장 프로그램 실행 위치:',
            part2: '창 > 확장 프로그램 > AutoCut V2',
          },
          davinci: {
            part1: '스크립트 실행 위치:',
            part2: '작업 공간 > 스크립트 > AutoCut',
          },
        },
        modals: {
          ppro: {
            title: '확장 프로그램 업데이트됨',
            subtitle:
              'Premiere Pro용 AutoCut 확장 프로그램이 업데이트되었습니다',
            body: 'AutoCut을 향상된 경험으로 업데이트했습니다. 확장 프로그램을 새로고침하려면 Premiere Pro를 다시 시작하세요.',
          },
          davinci: {
            title: '스크립트 업데이트됨',
            subtitle: 'DaVinci용 AutoCut 스크립트가 업데이트되었습니다',
            body: 'AutoCut을 향상된 경험으로 업데이트했습니다. AutoCut 스크립트를 다시 시작하세요.',
          },
        },
      },
    },
    footer: {
      discord: '우리의 Discord 커뮤니티에 참여하세요!',
      learnMore: 'AutoCut 기능을 최대화하는 팁 배우기!',
    },
    errors: {
      compute: 'AI 서비스에 연결할 수 없습니다. 지원팀에 문의하세요.',
      davinci:
        '다빈치 리졸브 또는 프리미어 프로에 연결할 수 없습니다. 실행 중이고 자동 자르기 스크립트가 활성화되어 있는지 확인하십시오.',
      cta: '다시 시도하기',
    },
  },
  homepage: {
    cards: {
      status: {
        trial: '무료 체험판',
        upgrade: '지금 업그레이드',
        coming_soon: '곧 출시!',
        version_not_supported: '지원되지 않는 {host} 버전',
        upgrade_to: '{plan}으로 업그레이드',
      },
    },
  },
  onboarding: {
    title: 'AutoCut에 오신 것을 환영합니다',
    description: '당신의 궁극적인 {host} 확장 프로그램',
    cta: {
      freeTrial: '무료 평가판 받아보기',
      useLicenceKey: '라이선스 키 사용',
      buyLicenceKey: '라이선스 키 구매',
    },
    auth: {
      login: {
        title: '라이선스 키를 입력하여 AutoCut 활성화',
        subtitle: '라이선스 키를 사용하세요',
        placeholder: '라이선스 키를 입력하세요',
        useTrial: '메일을 방금 이메일({email})로 받았습니다',
        resendEmail: '받지 못하셨나요? {trialEmail}로 이메일 재전송',
        cta: '활성화',
      },
      trial: {
        title: '무료 평가판 시작',
        steps: {
          email: {
            title: '이메일 주소를 입력하세요',
            subtitle: '이메일을 통해 평가판 라이선스를 받으세요',
            placeholder: 'email@example.com',
            cta: '다음',
          },
          sendingEmail: {
            title: '이메일 전송 중',
            success: '이메일이 {email}로 성공적으로 전송되었습니다',
          },
        },
      },
      errors: {
        no_key: '라이선스 키가 필요합니다',
        is_key_valid_error:
          '키 유효성 검사에 실패했습니다. 나중에 다시 시도해주세요.',
        invalid_response: '서버에서 예상치 못한 응답을 받았습니다.',
        cant_read_host_error: '컴퓨터 이름을 가져올 수 없습니다.',
        cant_read_uuid_error: '컴퓨터의 UUID를 가져올 수 없습니다.',
      },
    },
  },
  modes: {
    chapters: {
      title: '자동 챕터',
      description: '자동으로 챕터 표시 감지 및 추가',
      steps: {
        language: {
          cta: '챕터 생성',
        },
        editChapters: {
          title: '챕터 편집',
          description:
            '비디오의 챕터를 편집하세요. 타임코드와 제목을 변경할 수 있습니다.',
        },
        exportChapters: {
          cta: '유튜브용 챕터 내보내기',
        },
        markersColor: {
          title: '마커 색상',
        },
        cta: '챕터 추가',
      },
    },
    repeat: {
      title: 'AutoCut Repeat',
      description: 'AI를 사용하여 반복 클립 제거',
      steps: {
        language: {
          title: '클립 및 언어 선택',
          cta: '전사 생성',
          algorithm: {
            select: '알고리즘 선택',
            options: {
              precise: '정확한',
              fast: '빠른',
            },
            info: '정확한 알고리즘은 최근에 만든 가장 효율적인 알고리즘이지만 비디오의 6분당 약 1분의 처리가 필요합니다.',
          },
        },
        transcript: {
          title: '전사 편집',
          cta: '시퀀스 자르기',
          contextual: {
            cut: '자르기/자르지 않기',
            play: '재생',
          },
        },
      },
    },
    resize: {
      title: '자동 크기 조정',
      description: '다양한 플랫폼에 맞춰 시퀀스 크기 조정',
      steps: {
        inOutPoints: {
          title: '입력/출력 지점',
          description:
            '타임라인에서 입력 및 출력 지점을 확인하십시오. 아래 값과 동일해야 합니다.',
          items: {
            in: '입력: {inPoint} 초',
            out: '출력: {outPoint} 초',
            duration: '지속 시간: {duration} 초',
          },
        },
        presets: {
          title: '프리셋',
          description:
            '프리셋을 선택하여 시퀀스를 크기 조정하세요. 사용자가 직접 프리셋을 만들 수도 있습니다. 새로운 시퀀스의 차원은 아래에 표시됩니다.',
        },
        reframe: {
          title: '대상 속도 따라가기',
          description: '카메라가 대상을 따라가는 속도를 선택할 수 있습니다.',
          items: {
            slower: '느림',
            default: '보통',
            faster: '빠름',
            none: '없음',
          },
        },
        watermark: {
          title: '워터마크',
          description:
            '시퀀스에 워터마크를 추가할 수 있습니다. 워터마크는 시퀀스의 오른쪽 하단 코너에 추가됩니다.',
          items: {
            choose: '워터마크 선택',
            noFile: '선택된 워터마크 없음',
          },
        },
        cta: '시퀀스 크기 조정',
      },
    },
    podcast: {
      title: '오토 팟캐스트',
      description: '팟캐스트를 즉시 편집',
      steps: {
        select: {
          title: '최소 2개의 비디오 및 오디오 트랙이 있는 타임라인 선택',
          label: '타임라인 선택',
          descriptions: {
            '1': '편집 탭으로 이동',
            '2': '편집할 타임라인을 엽니다',
          },
          cta: '활성 타임라인 선택',
        },
        customization: {
          label: '팟캐스트 설정',
          steps: {
            speakers: {
              title: '발표자',
              description:
                '각 발표자에게 오디오 트랙을 지정하고 이름을 지정하세요. 한 트랙당 하나의 발표자입니다. 발표자가 공유되는 경우 두 이름을 사용하세요 (예: "Alice & Bob").',
              header: {
                audio: '오디오 트랙',
                name: '발표자 이름',
              },
              textInputPlaceholder: '발표자의 이름 입력...',
              trackTemplate: '트랙 A{index}',
              cta: '발표자 추가',
            },
            cameras: {
              title: '카메라',
              description:
                '각 카메라에 비디오 트랙을 할당하고 표시될 발표자를 선택하세요. 한 트랙당 하나의 카메라, 여러 발표자가 허용됩니다.',
              header: {
                audio: '비디오 트랙',
                name: '발표자(들)',
              },
              speakerSelectPlaceholder: '발표자 선택...',
              trackTemplate: '트랙 V{index}',
              cta: '카메라 추가',
            },
            cameraTime: {
              title: '카메라 지속 시간',
              description:
                '카메라가 전환되기 전에 머무르는 최소 및 최대 시간을 설정하세요.',
              presets: {
                title: '기본 설정',
                items: {
                  calm: '차분한',
                  paced: '정해진',
                  energetic: '활기찬',
                  hyperactive: '매우 활동적인',
                },
              },
              minimumCamTime: {
                title: '최소 시간',
                description: '카메라가 전환되기 전에 최소 시간(초)입니다.',
                unit: '초',
              },
              maximumCamTime: {
                title: '최대 시간',
                description: '카메라가 전환되기 전에 최대 시간(초)입니다.',
                unit: '초',
              },
            },
            silentVideoClips: {
              title: '사용되지 않은 클립 처리',
              description:
                '팟캐스트에서 사용되지 않는 부분을 제거하거나 사용 중지할 옵션을 선택하세요.',
              items: {
                remove: '제거',
                disable: '사용 안 함',
              },
            },
          },
          cta: {
            disable: '팟캐스트 편집 시작 - 사용되지 않은 클립 비활성화',
            delete: '팟캐스트 편집 시작 - 사용되지 않은 클립 제거',
          },
        },
      },
      error: {
        validation: {
          minimumCamTime: {
            min: '최소 카메라 시간은 최소 0초 이상이어야 합니다',
            higherThanMax:
              '최소 카메라 시간은 최대 카메라 시간보다 작아야 합니다',
          },
          maximumCamTime: {
            min: '최대 카메라 시간은 최소 0초 이상이어야 합니다',
          },
          deleteUnusedClips: {
            required:
              '사용되지 않은 클립을 제거하거나 비활성화할 옵션을 선택하세요',
          },
          speakers: {
            videoTracks: {
              min: '각 발표자에 대해 최소 하나의 비디오 트랙을 선택하세요',
            },
            audioTrack: {
              min: '각 발표자에게 오디오 트랙을 할당하세요',
            },
          },
        },
      },
    },
    silence: {
      title: '자동 무음 제거',
      description: '타임라인에서 침묵 제거',
      steps: {
        noiseLevel: {
          title: '노이즈 임계값',
          description:
            '아래에 표시된 대로 무음을 식별할 소리 수준을 설정하세요.',
        },
        silenceSize: {
          title: '무음 지속 시간',
          items: {
            silence: {
              title: '더 긴 침묵 제거',
              description: '침묵을 제거할 최소 지속 시간(밀리초)입니다.',
              unit: '밀리초',
            },
            talk: {
              title: '더 긴 말 유지',
              description:
                '토크 세그먼트를 유지할 최소 지속 시간(밀리초)입니다.',
              unit: '밀리초',
            },
          },
        },
        paddingSize: {
          title: '패딩',
          items: {
            before: {
              title: '이전 패딩',
              description:
                '부드러운 말 시작을 보장하기 위해 노이즈 전의 무음 시간입니다.',
              unit: '밀리초',
            },
            after: {
              title: '이후 패딩',
              description:
                '부드러운 말 끝을 보장하기 위해 노이즈 후의 무음 시간입니다.',
              unit: '밀리초',
            },
          },
        },
        silenceManagement: {
          title: '무음 관리',
          choices: {
            delete: '무음 삭제',
            keep: '무음 유지',
            mute: '무음 비활성화',
            delete_but_keep: '무음 삭제 및 공백 유지',
          },
        },
        transition: {
          params: {
            helper:
              '모든 컷은 J-Cut, L-Cut 또는 둘 다일 수 있습니다. 이는 더 동적인 전환 효과를 만듭니다.',
            title: '전환',
            warning: '전환을 위해서는 무음이 제거되어야 합니다.',
            default: {
              title: '기본',
              choices: {
                none: '없음',
              },
            },
            j_l_cut: {
              title: 'J-Cut & L-Cut',
              helper:
                '부드러운 전환을 위해 컷 주위에 패딩을 추가하고 새로운 전환 트랙을 만듭니다.',
              choices: {
                j_cut: 'J-Cut',
                l_cut: 'L-Cut',
                both: '둘 다',
              },
            },
            premiere_pro: {
              title: 'DaVinci Resolve',
              choices: {
                constant_power: '상수 전력',
              },
            },
          },
        },
        preview: {
          title: '미리보기',
          description:
            '유지되고 제거된 세그먼트를 봅니다. 화살표를 사용하여 탐색하거나 커서 위치를 기준으로 중심에 맞춥니다.',
          legend: {
            kept: '유지됨',
            deleted: '삭제됨',
            margin: '여백',
          },
          cta: '미리보기 생성',
          center: '커서 주위에 중심 맞추기',
        },
        cta: {
          delete: '무음을 자르고 삭제 {withTransition}',
          keep: '무음을 자르고 유지 {withTransition}',
          mute: '무음을 자르고 음소거 {withTransition}',
          delete_keep_spaces:
            '무음을 자르고 삭제하지만 공백은 유지 {withTransition}',
          withTransition: '전환을 적용',
        },
      },
      error: {
        validation: {
          noiseLevel: {
            negative: '소음 수준은 음수여야 합니다',
            int: '소음 수준은 숫자여야 합니다',
          },
          minTimeOfSilence: {
            required: '최소 무음 지속 시간이 필요합니다',
            min: '최소 무음 지속 시간은 최소 0ms 이상이어야 합니다',
          },
          minTimeOfTalk: {
            required: '최소 말 지속 시간이 필요합니다',
            min: '최소 말 지속 시간은 최소 0ms 이상이어야 합니다',
          },
          marginBefore: {
            min: '이전 패딩은 최소 0ms 이상이어야 합니다',
          },
          marginAfter: {
            min: '이후 패딩은 최소 0ms 이상이어야 합니다',
          },
        },
      },
      transition: {
        params: {
          title: '전환',
          warning: '전환을 위해서는 무음이 제거되어야 합니다.',
          default: {
            title: '기본',
            choices: {
              none: '없음',
            },
          },
          progress: {
            insert: '새 오디오 트랙 추가 중...',
            move: '전환을 위한 오디오 조정 중...',
            handling: '전환 적용 중: {transition}',
          },
        },
      },
    },
    swearWords: {
      title: '자동 비속어 필터',
      description: '불필요한 언어 자동 검열',
      parameters: {
        bleepSoundSelector: {
          mute: '음소거',
          helper: '삑 소리 효과 선택',
        },
      },
      footerButton: '비속어 제거',
      infoText:
        '비속어 비퍼는 AI 덕분에 오디오를 분석하고 비속어 위에 자동으로 {br} 삑 소리를 추가합니다.',
    },

    zoom: {
      title: '자동 줌',
      description: '자동으로 줌 효과 추가',
      parameters: {
        preview: {
          title: '줌 미리보기',
          description: '줌의 초점 위치를 설정하세요',
          maximumZoom: '최대 줌 레벨',
          errorMessage: '미리보기 생성 오류',
        },
        zoomTypes: '줌 스타일',
        defaultsPresets: {
          title: '기본 설정',
          calm: '차분한',
          paced: '정해진',
          energetic: '활기찬',
          hyperactive: '매우 활동적인',
        },
        zoomCoverage: '전체 줌 커버리지',
        constraint: {
          title: '각 클립 별로 줌 제한',
          helper:
            '활성화되면 줌 효과가 클립 경계를 넘지 않습니다. 비활성화되면 선택한 전체 구간을 포괄합니다.',
        },
        maximumZoomDuration: '최대 줌 지속 시간',
        smoothZooms: {
          title: '부드러운 줌',
          coverage: '부드러운 줌 커버리지',
          nervousness: '줌 속도와 강도',
        },
        dynamicZooms: {
          title: '동적 줌',
          coverage: '동적 줌 커버리지',
        },
      },
      types: {
        smooth: '부드러운',
        dynamic: '동적',
      },
      footerButton: '줌 적용',
      infoText:
        '자동 줌은 오디오 레벨을 기반으로 자동으로 줌 효과를 추가합니다.',
    },
    caption: {
      title: '자동 자막',
      description: 'AI를 사용하여 애니메이션 자막 추가',
      steps: {
        language: {
          button: '자막 편집기 열기 (스타일 및 전사)',
        },
        customization: {
          button: '자막 추가',
          parts: {
            style: {
              title: '자막 스타일',
              modal: {
                title: '스타일 편집',
                apply: '스타일 적용',
                save: '적용 및 프리셋으로 저장',
                text: {
                  title: '글꼴',
                  font: '글꼴 이름',
                  help: '새 글꼴 패밀리를 추가하는 방법?',
                  color: '텍스트 색상',
                },
                animation: {
                  title: '애니메이션',
                  wordBox: '텍스트 상자',
                  floatingText: '떠있는 텍스트',
                  highlight: {
                    title: '색상 강조',
                    color: '강조 표시 <color>색상</color>',
                    advancedColorOptions: {
                      title: '고급 색상 옵션',
                      revealedText: '강조 표시 <i>전</i> 색상',
                      unrevealedText: '강조 표시 <i>후</i> 색상',
                    },
                  },
                },
                outline: {
                  title: '윤곽선',
                  width: '윤곽선 두께',
                },
                box: {
                  title: '텍스트 상자',
                  paddingX: '수평 패딩',
                  paddingY: '수직 패딩',
                  opacity: '상자 불투명도',
                  radius: '상자 모서리 반경',
                  disabled:
                    '선택한 글꼴은 이 언어에 대한 상자 기능을 지원하지 않습니다.',
                  tips: '이 기능을 위해 선택한 언어를 지원하는 글꼴을 선택하세요.',
                },
                formating: {
                  title: '텍스트 형식',
                  removePunctuation: '문장 부호 제거',
                  uppercase: '대문자',
                  italic: '이탤릭체',
                  glow: {
                    title: '발광',
                    color: '발광 색상',
                    intensity: '발광 강도',
                  },
                },
                transition: {
                  title: '전환',
                  zoomIn: '확대',
                  blurIn: '흐림 효과',
                },
              },
            },
            preview: {
              title: '미리보기',
              modal: {
                title: '위치 및 크기',
                apply: '스타일 적용',
                fontSize: '글꼴 크기',
                nbLines: '라인 수',
                maxWidth: '최대 너비',
                help: '미리보기 기능은 개발 중이며 정확하지 않을 수 있습니다.',
              },
            },
            transcription: {
              title: '전사 편집',
              modal: {
                save: '저장',
                select: '편집할 자막 선택',
                placeholder: '편집 또는 사용자 정의할 전사 세그먼트 선택',
                formatNumbers: '숫자 서식 적용',
                checkTranscript: {
                  title: '전사를 확인했습니까?',
                  description: '아직 전사를 확인하지 않았습니다.',
                  dontShowAgain: '다시 알리지 않기',
                },
              },
              tooltips: {
                lowConfidence: '낮은 신뢰도 단어',
                merge: '세그먼트 병합',
                split: '세그먼트 분할',
              },
              emojis: {
                title: '어떤 유형의 이모지를 생성하시겠습니까?',
                animated: '애니메이션',
                static: '정적',
              },
            },
            presets: {
              noPresetSelected: '프리셋을 선택하여 미리보기',
              noPreviewAvailable: '미리보기 사용 불가',
              title: '프리셋',
              error:
                '프리셋을 가져오는 중 오류가 발생했습니다. Discord에서 문의하세요.',
              delete: {
                title: '프리셋 삭제',
                cancel: '취소',
                confirm: '프리셋 삭제',
              },
              search: {
                title: '커뮤니티 프리셋',
                input: '프리셋 검색',
                cancel: '취소',
                confirm: '프리셋 가져오기',
                usage: '{count} 사용자가 사용 중',
                error:
                  '프리셋 복사 오류. Discord 또는 contact@autocut.fr로 문의하세요.',
              },
              create: {
                title: '새 프리셋 만들기',
                placeholder: '프리셋 이름 (최소 두 단어)',
                community: '커뮤니티와 공유',
                public: '공개',
                cancel: '취소',
                confirm: '프리셋 만들기',
                alreadyExists:
                  '프리셋이 이미 존재합니다. 다른 이름을 선택하세요.',
              },
            },
          },
        },
      },
      error: {
        validation: {
          userPresets: {
            missing:
              '사용자 프리셋이 없습니다. 상단 메뉴에서 설정을 재설정하세요.',
            titleTooShort: '제목은 최소 5자 이상이어야 합니다',
            titleTooLong: '제목은 최대 25자 이하여야 합니다',
            titleNoSpace: '제목에는 최소 두 단어가 포함되어야 합니다',
          },
          position: {
            xPercentage: {
              min: 'X 위치는 최소 0이 되어야 합니다',
              max: 'X 위치는 최대 1이 되어야 합니다',
            },
            yPercentage: {
              min: 'Y 위치는 최소 0이 되어야 합니다',
              max: 'Y 위치는 최대 1이 되어야 합니다',
            },
            emojiXPercentage: {
              min: '이모지 X 위치는 최소 0이 되어야 합니다',
              max: '이모지 X 위치는 최대 1이 되어야 합니다',
            },
            emojiYPercentage: {
              min: '이모지 Y 위치는 최소 0이 되어야 합니다',
              max: '이모지 Y 위치는 최대 1이 되어야 합니다',
            },
          },
          text: {
            fontSize: {
              int: '글꼴 크기는 숫자이어야 합니다',
              min: '글꼴 크기는 최소 1이어야 합니다',
            },
            font: {
              missing:
                '선택한 글꼴이 불완전합니다. 다시 선택하거나 다른 것을 선택하세요.',
            },
          },
          outline: {
            width: {
              int: '윤곽선 너비는 숫자이어야 합니다',
              min: '윤곽선 너비는 최소 0이어야 합니다',
            },
          },
          formating: {
            nbLines: {
              int: '라인 수는 숫자이어야 합니다',
              min: '라인 수는 최소 1이어야 합니다',
            },
            maxWidth: {
              int: '최대 너비는 숫자이어야 합니다',
              min: '최대 너비는 최소 0이어야 합니다',
            },
          },
          textBox: {
            xPadding: {
              int: '수평 패딩은 숫자이어야 합니다',
              min: '수평 패딩은 최소 0이어야 합니다',
            },
            yPadding: {
              int: '수직 패딩은 숫자이어야 합니다',
              min: '수직 패딩은 최소 0이어야 합니다',
            },
            opacity: {
              int: '불투명도는 숫자이어야 합니다',
              min: '불투명도는 최소 0이어야 합니다',
              max: '불투명도는 최대 100을 초과할 수 없습니다',
            },
            radius: {
              int: '반지름은 숫자이어야 합니다',
              min: '반지름은 최소 0이어야 합니다',
            },
          },
        },
      },
    },
    viralClips: {
      title: '자동 바이럴',
      description: '긴 비디오에서 바이럴 가능성이 있는 클립 쉽게 식별',
      steps: {
        language: {
          button: '바이럴 클립 찾기',
        },
      },
      stepSelector: '바이럴 클립 선택',
      sorter: {
        viralScore: '바이럴 점수',
        start: '시작',
      },
      viralPotential: '바이럴 잠재력 점수',
      detectedClips:
        '{viralClipsLength}개의 잠재적 바이럴 클립이 감지되었습니다',
      footerButton: '바이럴 클립 생성',
    },
    broll: {
      title: '자동 B-Rolls',
      description: 'AI를 사용하여 자동으로 B-Roll 추가',
      steps: {
        language: {
          button: '다음',
        },
        parameters: {
          title: 'B-Roll 매개변수',
          cta: '다음',
          duration: {
            title: 'B-Roll 지속시간',
            minimumDuration: '최소 지속시간',
            minimumDurationHelper: '각 B-Roll은 최소한 이 정도 길이가 됩니다.',
            maximumDuration: '최대 지속시간',
            maximumDurationHelper: '각 B-Roll은 최대한 이 정도 길이가 됩니다.',
          },
        },
        transcript: {
          title: 'B-Roll 선택 수정',
          cta: 'B-Rolls 추가',
          selection: 'B-Roll 선택',
          choiceModal: {
            title: 'B-Roll 선택',
            inputPlaceholder: 'B-Rolls 검색하려면 입력하세요',
            searching: 'B-Rolls 검색 중...',
          },
        },
      },
    },

    general: {
      steps: {
        select: {
          title: '입력/출력 포인트 설정',
          descriptions: {
            '1': '입력/출력 포인트를 설정하지 않은 경우, AutoCut은 전체 타임라인에 적용됩니다.',
            '2': '오디오 분석에서 제외하기 위해 클립 또는 트랙을 수동으로 음소거하세요.',
          },
          cta: '입력/출력 포인트 확인',
        },
        transcription: {
          title: '오디오 언어 선택',
          description: '성적증명서를 생성할 언어를 선택합니다.',
        },
        customization: {
          title: '설정 사용자 정의',
        },
      },
    },
  },
  modals: {
    deepgramError: {
      title: '번역 오류',
      cta: 'Discord에 참여하기',
      text: '오디오 번역 중 오류가 발생했습니다. 이는 오디오 품질 또는 언어 지원 문제 때문일 수 있습니다. {br}Discord 또는 이메일 contact@autocut.com로 보고해 주세요.',
      retry: '다른 AI 모델로 재전사 시도.',
    },
    contactUs: {
      title: '불편을 드려 죄송합니다',
      text: 'AutoCut이 제대로 작동하지 않아 문제를 보고하려면:',
      non_editing: '편집과 관련 없는 문제(계정, 키, 결제 등)의 경우:',
      contact: '문의하기:',
      contactClicked: '연락처 정보가 복사되었습니다!',
      cta: '문제 보고',
    },
    author: '{author} from AutoCut',
    onboarding: {
      steps: {
        welcome: {
          title: 'AutoCut 베타에 오신 것을 환영합니다',
          stepper: '환영합니다',
          footer: '다음',
          text1:
            '이 여정의 일원이 되어주셔서 감사합니다! 🚀 AutoCut의 이 향상된 버전을 소개하게 되어 기쁩니다.{br}흐름이 원활해진 새 버전을 테스트할 수 있는 날을 고대합니다.',
          text2:
            '<b>향상된 사용자 인터페이스</b>로 AutoCut이 편집 워크플로우의 일부가 될 수 있도록 지원합니다 🎬{br}<b>안정성 80% 향상</b> - 예기치 않은 충돌이나 중단은 이제 그만🧘{br><b>성능이 50% 향상</b> - 신속한 응답 시간과 더 부드러운 결과 및 효율성이 향상되었습니다 🚀',
          text3:
            '이뿐만 아니라 앞으로 발견할 수 있는 내용도 있습니다. 시작해봅시다!',
        },
        whyAreYouHere: {
          title: '왜 여기에 있습니까?',
          stepper: '왜 여기에 있습니까?',
          footer: '다음',
          text1:
            '여기 계신 이유는 저희가 <b>도움을</b> 필요로 하기 때문입니다 🫵',
          text2:
            '당신은 우리가 가장 충성스러운 사용자 중 한 명임을 증명하셨고, 그 점에 대해 감사드립니다. 그리하여 이 독점적인 베타에 참여하게 되었습니다.',
          text3: '그러나 <i>큰 힘에는 큰 책임이 따릅니다.</i>',
          text4:
            'AutoCut을 당신의 비디오 편집 기술 모음에 필수적인 도구로 만들려면 피드백이 필요합니다. 반복적인 작업은 뒤로하고 당신이 가장 잘 하는 것, 즉 창의성에 집중할 수 있는 도구를 함께 만들어봅시다.',
        },
        weNeedYou: {
          title: '여러분이 필요합니다 🫵',
          stepper: '여러분이 필요합니다',
          footer: '다이빙해봅시다',
          text1:
            '새 버전에서 발생하는 버그를 보고할 수 있는 피드백 버블이 홈페이지의 오른쪽 하단에 새롭게 추가되었습니다.',
          text2:
            '문제를 해결하는 것이 우리의 최우선 과제이며, 그렇기 때문에 여러분의 의견 하나하나가 면밀히 검토됩니다.',
          text3:
            '필수적이라고 생각하는 기능을 제안할 수 있는 채널이기도 합니다.',
          text4:
            '2000명 이상의 친절한 비디오 편집자들과 함께하는 우리 <a>Discord</a> 커뮤니티에 가입하여 AutoCut의 미래를 미리보기로 엿보고 싶을 수도 있습니다 🌍',
        },
      },
    },
    usageStats: {
      title: '사용 통계',
      select: {
        label: '모드를 선택하세요',
      },
      stats: {
        thisMonth: '이번 달',
        allTime: '전체 시간',
        mostUsed: '가장 많이 사용된 기능',
      },
    },
    endProcess: {
      title: '프로세스 완료',
      time: '{minutes} 분 {seconds} 초 만에 완료되었습니다!',
      timeSaved:
        '저장된 시간: <b>{minutesSaved}</b> 분 <b>{secondsSaved}</b> 초',
      timeSavedAllTime:
        'AutoCut을 통해 총 {hourSaved} 시간 {minutesSaved} 분이 저장되었습니다! 🎉',
      feedback: '결과에 대한 귀하의 피드백을 소중히 여깁니다:',
      review: 'AutoCut 평가하기!',
      caption: {
        title: '자막 추가 완료!',
        footer:
          '자막 또는 전사를 조정해야 하는 경우, 자막 편집기로 돌아가세요.',
        button: '전사 편집기로 돌아가기',
        text_1: '애니메이션 자막이 추가되었습니다! 🎉',
        text_2: '필요하면 자막을 편집하세요:',
        list_1: '타임라인 커서를 원하는 자막에 놓습니다.',
        list_2: 'Fusion 페이지로 이동합니다.',
        list_3: 'TextPlus 노드에서 자막을 수동으로 편집합니다.',
        text_3: '더 많은 팁은 이 <a>튜토리얼</a>을 시청하세요.',
      },
      broll: {
        title: '당신의 영상에서 사용된 B-Roll의 크레딧은 다음과 같습니다 : ',
        providedByPexels: 'Pexels 제공',
        copyToClipboard: '클립 보드에 복사',
        copied: '복사됨',
        link: '링크',
      },
      nextMode: {
        silence: {
          text: '부드러운 오디오를 위해 AutoCut Silences를 사용하여 어색한 일시 중지를 쉽게 제거하세요.',
          button: 'AutoCut Silences로 이동',
        },
        podcast: {
          text: 'AutoCut Podcast로 팟캐스트를 향상시켜 매끄러운 편집과 자동 카메라 전환을 경험하세요.',
          button: 'AutoCut Podcast로 이동',
        },
        repeat: {
          text: '청중을 사로잡으세요! AutoCut Repeat를 사용하여 콘텐츠의 가장 매력적인 부분만 유지하세요.',
          button: 'AutoCut Repeat로 이동',
        },
        zoom: {
          text: 'AutoZoom으로 비디오에 생명을 불어넣으세요! 청중을 사로잡는 다이내믹한 줌 효과를 추가하세요.',
          button: 'AutoZoom으로 이동',
        },
        caption: {
          text: 'AutoCaptions를 사용하여 시청자와 연결하세요. 스토리텔링을 향상시키는 세련된 동기화 자막을 추가하세요.',
          button: 'AutoCaptions로 이동',
        },
        resize: {
          text: 'AutoResize를 사용하여 모든 플랫폼에 맞게 비디오를 최적화하세요.',
          button: 'AutoResize로 이동',
        },
        broll: {
          text: 'AutoB-Roll로 내러티브를 강화하세요. AI를 활용하여 스톡 영상을 원활하게 추가하세요.',
          button: 'AutoB-Roll로 이동',
        },
        swear: {
          word: {
            text: 'AutoProfanity Filter로 전문적인 대화를 유지하세요. 원하지 않는 언어를 자동으로 검열합니다.',
            button: 'AutoProfanity Filter로 이동',
          },
        },
      },
    },
    discord: {
      title: '디스코드',
    },
    unsupportedVersion: {
      title: '지원하지 않는 {host} 버전',
      currentVersion: '감지된 {host} 버전: {version}',
      text: 'AutoCut은 {host} 버전 18.6 이상이 필요합니다.',
      update: '다음에서 업데이트하세요: <a>{host} 웹사이트</a>',
    },
  },
  gamification: {
    savedHours: '방금 {hourSavedText} 시간을 절약하셨습니다,',
    haveABreak: '잠시 휴식을 취하세요, 당신은 그럴 자격이 있습니다!',
  },
  errors: {
    modal: {
      title: {
        noSelectedAudioClip: '선택된 오디오 클립 없음',
        noTimelineFound: '타임라인을 찾을 수 없음',
        subsequenceNotReachable: '서브시퀀스에 접근할 수 없음',
        unknown: '알 수 없는 오류 발생',
        noSpace: '스토리지 부족 오류',
        exportUnknown: '{host}에서의 내보내기 오류',
        invalidInputData: '임시 파일 생성 오류',
        fileNotFound: '파일 읽기 오류',
        scriptNotLoaded: 'AutoCut 스크립트 로드 오류',
        ffmpegError: '파일 읽기 오류입니다.',
        exportCancelled: '내보내기 취소됨',
        timelineMuted: '듣기 가능한 트랙 없음',
        noAudibleAudio: '듣기 가능한 오디오 없음',
        offline: '오프라인 상태 감지됨',
        resourceTimeout: '리소스 시간 초과',
        backupNotFound: '백업을 찾을 수 없음',
        setCursorOutInterval: 'In/Out 포인트 범위 밖',
        emptyPath: '선택 오류 분석',
        sequenceMismatch: '작업을 완료할 수 없음',
      },
      text: {
        noSelectedAudioClip:
          '선택한 시작 및 종료 지점 사이에 오디오 클립이 없음',
        noTimelineFound: '활성 시퀀스를 찾을 수 없음',
        subsequenceNotReachable: '선택한 서브시퀀스에 접근할 수 없음.',
        unknown: '원인이 불분명한 AutoCut 오류 발생.',
        noSpace: '기기 저장공간이 부족합니다.',
        exportUnknown: '디스크 저장공간 부족으로 인해 발생한 오류입니다.',
        invalidInputData: '임시 파일 생성 중 오류가 발생했습니다.',
        fileNotFound:
          '다음 위치에 {filename}이 있는지 확인하십시오: {filepath}',
        scriptNotLoaded:
          '다른 모든 확장 프로그램과 {host}를 닫고 다시 시도하십시오.',
        ffmpegError: '{filepath} 파일이 손상되었을 가능성이 있습니다.',
        exportCancelled:
          '인코딩이 취소된 경우 오디오 분석이 중단됩니다. 다시 시도하십시오.',
        timelineMuted: '선택한 오디오 트랙이 음소거되었습니다.',
        noAudibleAudio: '감지 가능한 오디오가 없습니다.',
        offline: '서버에 접근할 수 없습니다.',
        resourceTimeout: '30초 내에 리소스를 다운로드할 수 없습니다.',
        backupNotFound: '복원할 백업 시퀀스를 찾을 수 없습니다.',
        setCursorOutInterval:
          '선택한 섹션이 In/Out 포인트 범위를 벗어났습니다.',
        emptyPath: '선택된 클립이 귀하의 컴퓨터에 경로가 없을 수 있습니다.',
        sequenceMismatch: 'AutoCut가 처리하는 동안 시퀀스 변경을 피하십시오.',
      },
      tips: {
        noSelectedAudioClip: '시작 및 종료 지점을 올바르게 설정하십시오.',
        noTimelineFound: '유효한 타임라인을 선택하십시오.',
        subsequenceNotReachable:
          '타임라인의 서브시퀀스가 프로젝트 패널의 원본 시퀀스와 동일한 이름을 공유하는지 확인하십시오.',
        unknown: '선택/설정을 수정해 보십시오. 문제가 지속되면 문의하십시오.',
        noSpace: '공간을 확보하고 AutoCut을 다시 시작하십시오.',
        exportUnknown: '공간을 확보하고 AutoCut을 다시 시작하십시오.',
        invalidInputData:
          '동일한 선택으로 다시 시도하십시오. 문제가 지속되면 파일을 보내주십시오.',
        fileNotFound:
          '동일한 선택으로 다시 시도하십시오. 문제가 지속되면 파일을 보내주십시오.',
        scriptNotLoaded: '문제가 지속되면 문의하십시오.',
        ffmpegError: '문제가 지속되면 문의하십시오.',
        exportCancelled: ' ',
        timelineMuted: '적어도 하나의 트랙의 음소거를 해제하십시오.',
        noAudibleAudio:
          '클립이 활성화되고 들릴 수 있는지 확인하십시오. 문제가 지속되면 Discord나 contact@autocut.fr로 연락해주십시오.',
        offline: '인터넷 연결을 확인하고 다시 시도하십시오.',
        resourceTimeout: '인터넷 연결을 확인하고 다시 시도하십시오.',
        backupNotFound:
          '프로젝트 루트의 "AutoCut-Backup" 빈에서 백업 유무를 확인하십시오.',
        setCursorOutInterval:
          '이 전사 섹션에 접근하려면 In/Out 포인트를 제거하십시오.',
        noSoundDetected:
          '소리가 감지되지 않았습니다. 언어가 클립과 일치하는지, 트랙이 음소거되지 않았는지, In/Out 포인트가 소리 클립을 포함하는지 확인하십시오. {br}문제가 지속되면 Discord나 contact@autocut.fr로 연락해주십시오.',
        emptyPath: '클립이 원자 미디어 또는 서브시퀀스인지 확인하십시오.',
        sequenceMismatch:
          'AutoCut 처리 완료 전까지 시퀀스 변경 및 수정을 피하십시오.',
      },
    },
  },
  globals: {
    ok: 'OK',
    copied: 'Copiado',
    contactMail: 'contact@autocut.com',
    defaults: {
      components: {
        ProcessModal: {
          defaultStepName: '처리 중',
        },
        KebabMenu: {
          account: '라이센스 키 관리',
          usageStatistics: '사용 통계',
          bug: '버그 신고하기',
          review: 'AutoCut 리뷰하기',
          tuto: '튜토리얼 보기',
          logout: '로그아웃',
          settings: '설정 재설정',
          featureRequest: '기능 요청',
          academy: '도움말 센터',
        },
        LicenseInfos: {
          timeLeft: '{daysLeft} 일 남음',
          info: '{licenceType} 라이센스',
          trialButton: '더 알아보기',
          paidButton: '라이센스 관리',
        },
        PromoBanner: {
          promo: '다음 24시간 내 최대 20% 할인!',
          shop: '지금 쇼핑하기',
        },
        FileInput: {
          uploadScreenshot: '문제 스크린샷 업로드',
        },
        LinkToUserFileInput: {
          sendLogLinkToUserFile: {
            title: '비디오 및 오디오 파일의 링크를 제공합니다.',
            description:
              '(권장: SwissTransfer: https://www.swisstransfer.com/)',
            helper:
              "Swisstransfer를 사용하여 업로드하려면 swisstransfer.com을 방문하여 '파일 업로드'를 클릭하고 링크를 얻어 여기에 붙여넣으세요.",
            end: '문제 해결 및 분석에 필요합니다.',
          },
        },
        Select: {
          'no-more': '더 이상의 옵션 없음',
          'select-all': '전체 선택',
          placeholder: '옵션 선택',
        },
        PreviewFrame: {
          previewOnIndicator: '지표에 중심 맞추기',
        },
        FontNotFoundModal: {
          title: '폰트를 찾을 수 없음',
          description:
            '시스템에서 폰트를 찾을 수 없습니다. 캡션을 추가하기 위해 새 폰트를 선택하십시오.',
          help: '이 튜토리얼을 따라 새 폰트 패밀리를 추가할 수 있습니다.',
        },
        TimeBadge: {
          errors: {
            wrong_format:
              '시간 형식이 잘못되었습니다. HH:MM:SS 형식을 사용하세요.',
          },
        },
      },
    },
    misc: {
      retry: '다시 시도하기',
      since_your_last_visit: '마지막 방문 이후',
      back_to_autocut: '자동 자르기로 돌아가기',
    },
    units: {
      second: '초',
      millisecond: '밀리초',
      percentage: '%',
      px: '픽셀',
    },
  },
  old: {
    error: {
      UseLicence: {
        __text__: '다른 장치의 키 연결 해제를 통해',
        link: 'AutoCut 클라이언트 포털에서',
      },
      modal: {
        title: {
          subsequenceNotReachable: '서브시퀀스 접근 불가',
          unknown: '알 수 없는 오류 발생',
          noSpace: '저장 공간 부족 오류',
          exportUnknown: 'DaVinci Resolve 내보내기 오류',
          invalidInputData: '임시 파일 생성 오류',
          fileNotFound: '파일 읽기 오류',
          scriptNotLoaded: 'AutoCut 스크립트 로드 오류',
          ffmpegError: '파일 읽기 오류.',
          exportCancelled: '내보내기 취소됨',
          timelineMuted: '들리는 트랙 없음',
          noAudibleAudio: '들리는 오디오 없음',
          offline: '오프라인 상태 감지됨',
          resourceTimeout: '자원 타임아웃',
          backupNotFound: '백업 찾을 수 없음',
          setCursorOutInterval: '입력/출력 포인트 밖에 있음',
          emptyPath: '선택된 분석 오류',
          sequenceMismatch: '작업 완료 불가',
        },
        text: {
          subsequenceNotReachable: '선택한 서브시퀀스에 접근할 수 없습니다.',
          unknown: '알 수 없는 원인으로 AutoCut 오류 발생.',
          noSpace: '장치에 충분한 저장 공간이 없습니다.',
          exportUnknown: '디스크 저장 부족으로 인해 이 오류가 발생했습니다.',
          invalidInputData: '임시 파일 생성 중 오류가 발생했습니다.',
          fileNotFound: '{filename}이(가) 다음 위치에 있는지 확인: {filepath}',
          scriptNotLoaded:
            '다른 확장을 모두 닫고 DaVinci Resolve를 다시 시도하세요.',
          ffmpegError: '파일 {filepath}이(가) 손상되었을 수 있습니다.',
          exportCancelled:
            '인코딩이 취소되면 오디오 분석이 중단됩니다. 다시 시도하세요.',
          timelineMuted: '선택된 오디오 트랙은 음소거 상태입니다.',
          noAudibleAudio: '감지 가능한 오디오가 없습니다.',
          offline: '서버에 연결할 수 없습니다.',
          resourceTimeout: '30초 이내에 리소스를 다운로드할 수 없습니다',
          backupNotFound:
            '"AutoCut-Backup" 폴더의 루트에서 백업이 있는지 확인하세요.',
          setCursorOutInterval:
            '선택된 섹션이 입력/출력 포인트를 벗어났습니다.',
          emptyPath: '선택한 클립이 컴퓨터에 경로가 없을 수 있습니다.',
          sequenceMismatch:
            'AutoCut 처리가 완료될 때까지 시퀀스 변경과 수정사항을 피하세요.',
        },
        tips: {
          subsequenceNotReachable:
            '타임라인의 서브시퀀스가 프로젝트 패널의 원래 시퀀스와 동일한 이름을 공유하는지 확인하세요.',
          unknown:
            '선택/설정을 변경하여 시도하세요. 문제 지속 시, 저희에게 문의하세요.',
          noSpace: '공간을 확보하고 AutoCut을 다시 시작하세요.',
          exportUnknown: '공간을 확보하고 AutoCut을 다시 시작하세요.',
          invalidInputData:
            '같은 선택 사항으로 다시 시도하십시오. 문제가 지속되면 파일과 함께 저희에게 문의하세요.',
          fileNotFound:
            '같은 선택 사항으로 다시 시도하십시오. 문제가 지속되면 파일과 함께 저희에게 문의하세요.',
          scriptNotLoaded: '문제가 지속되면 저희에게 문의하세요.',
          ffmpegError: '문제가 지속되면 저희에게 문의하세요.',
          exportCancelled: ' ',
          timelineMuted: '최소 하나의 트랙을 음소거 해제하세요.',
          noAudibleAudio:
            '클립이 활성화되어 소리가 나는지 확인하십시오. 문제가 지속되면 Discord 또는 contact@autocut.fr로 문의해주세요.',
          offline: '인터넷 연결을 확인하고 다시 시도하세요.',
          resourceTimeout: '인터넷 연결을 확인하고 다시 시도하세요.',
          backupNotFound: '"AutoCut-Backup" 빈에서 백업 시퀀스를 확인하세요.',
          setCursorOutInterval:
            '이 전사 섹션에 접근하려면 입력/출력 포인트를 제거하세요.',
          noSoundDetected:
            '소리가 감지되지 않았습니다. 언어가 클립과 일치하는지 확인하고, 트랙이 음소거되지 않았고 입력/출력 포인트가 소리 클립을 포함하는지 확인하세요. {br}문제가 지속되면 Discord 또는 contact@autocut.fr로 문의하세요.',
          emptyPath: '클립이 원자 미디어 또는 서브시퀀스인지 확인하세요.',
          sequenceMismatch:
            'AutoCut 처리 완료까지 시퀀스 변경 및 수정을 피하세요.',
        },
        lost: {
          cep: {
            title: '연결 손실 오류',
            text: {
              '1': 'DaVinci Resolve와의 연결이 손실되었습니다. 확장을 다시 시작하세요.',
              '2': '실패하는 경우 DaVinci Resolve를 다시 시작하세요.',
            },
          },
        },
      },
      language: {
        not: {
          supported: '이 모드는 언어 지원 불가',
        },
      },
    },
    retour: '키가 존재하지 않습니다!',
    countdown: {
      days: '체험 남은 일수',
    },
    saveas: {
      default: {
        settings: '현재 매개변수 저장',
      },
    },
    button: {
      no_silences: '무음이 감지되지 않음',
      no_audios: '선택된 오디오 클립 없음',
      SavingBackup: '프로젝트 백업 중...',
      RestoringBackup: '프로젝트 복구 중...',
      ExportingAudio: '오디오 내보내기 중...',
      UploadingAudio: '오디오 업로드 중...',
      SearchingSilences: '무음 검색 중...',
      CuttingStep: {
        Audio:
          '오디오 트랙 {numTrack}에서 오디오 커팅 수행 : {current} / {max}',
        Video:
          '비디오 트랙 {numTrack}에서 비디오 커팅 수행 : {current} / {max}',
      },
      PostCuttingStep: '컷 완료...',
      PreDeletingStep: '삭제할 무음 클립 검색 중...',
      PostDeletingStep: '무음 제거 완료...',
      finalizing: '마무리 중...',
      DeletingStart: '무음 삭제 중...',
      AssociatingStep: '연관 수행 {current} / {max}',
      Success: '프로세스 성공!',
      Failure: '실패... 문제가 발생했습니다',
      NoSilences: '무음이 감지되지 않음',
      undo: {
        modal: '되돌리기',
      },
      not: {
        supported: {
          modal: '닫기',
        },
      },
      PreZoomingStep: '줌을 위한 서브 클립 얻기...',
      ZoomingStep: '줌 추가 : {current} / {max}',
      Autozoom: 'AutoZoom 준비 중...',
      no_video: {
        to_zoom: '줌할 비디오 없음.',
      },
      getSubclip: {
        step: 'AutoZoom을 위한 서브 클립 검색 중...',
      },
      checkSilenceSuppression: {
        step: '무음 제거 확인 중...',
      },
      back: '뒤로',
      DeletingStep: {
        delete: {
          Audio: '오디오 트랙 {numTrack}에서 무음 삭제 : {current} / {max}',
          Video: '비디오 트랙 {numTrack}에서 무음 삭제 : {current} / {max}',
        },
        mute: {
          Audio: '오디오 트랙 {numTrack}에서 무음 무음 : {current} / {max}',
          Video: '비디오 트랙 {numTrack}에서 무음 무음 : {current} / {max}',
        },
      },
      skip: '건너뛰기',
      save: '저장',
    },
    warning: {
      DontTouchTimeline: '처리 중 타임라인을 편집하지 마세요',
      undo: {
        modal: '오디오 파일 분석 중 메타데이터 누락 감지됨.',
      },
      modal: {
        title: {
          ffmpeg: '잠재적 FFMPEG 문제',
        },
        text: {
          ffmpeg: '파일 {filename}에서 메타데이터 누락 감지됨.',
        },
        tips: {
          ffmpeg: '문제가 지속되면 파일을 재인코딩하는 것을 고려하세요.',
        },
      },
      beta: {
        version:
          '베타 DaVinci Resolve 버전 감지됨. AutoCut 문제가 있을 수 있습니다. 최근의 안정 된 DaVinci Resolve 버전에서 테스트를 권장합니다.',
      },
    },
    preview: {
      loading: {
        selection: {
          validation: '선택 확인 중...',
          parsing: {
            step: '데이터 검색 중: 클립 {current} / {max}...',
          },
        },
        silence: {
          detection: {
            step: '무음 탐지 중: 클립 {current} / {max}...',
          },
        },
        drawing: '미리보기 생성 중...',
      },
      frame: {
        no_sequence: '미리보기를 보려면 시퀀스를 시작하세요',
      },
    },
    contact: {
      us_title: '불편을 드려 죄송합니다',
      us_text: {
        '2': '편집 관련이 아닌 문제(계정, 키, 결제 등)에 대해서는: ',
        __text__: 'AutoCut이 올바르게 작동하지 않고 문제를 신고하고 싶다면:',
      },
      us_button: '문제 신고하기',
      us_contact: '문의하기:',
      us_copy: '연락처 정보 복사됨!',
    },
    switch: {
      to_send: {
        log: {
          button: '또는, 로그 파일을 전송',
        },
      },
    },
    send: {
      log: {
        title: '문제에 대해 사과 드립니다',
        details: '이 로그 파일은 미디어 처리 방법을 보여줍니다.',
        select: '문제를 해결하기 위해 일일 로그 파일을 선택하세요.',
        text: {
          area: '문제를 자세히 설명하세요:',
        },
        button: '로그 파일 제출',
        success: {
          title: '파일 수신! 조사를 시작합니다.',
        },
        thank: {
          text: '편집을 계속하세요.',
          text2: '문제가 해결되면 업데이트하겠습니다.',
        },
        message: {
          error: '메시지는 최소 10자 이상이어야 합니다',
        },
        screen: {
          timeline: {
            __text__: 'DaVinci Resolve 타임라인의 스크린샷 포함.',
            before: '컷 전의 스크린샷 포함.',
            after: '컷 후의 스크린샷 포함.',
          },
          error: {
            message: '오류 메시지의 스크린샷 포함.',
          },
        },
        trial: {
          mail: '체험과 연결된 이메일 제공.',
        },
        other: '문제 해결을 보장하기 위한 추가 정보를 제공하세요.',
      },
    },
    select: {
      issue: {
        title: '아래에서 문제를 선택하세요.',
        subtitle: '이렇게 하면 문제를 빠르게 해결할 수 있습니다.',
      },
      one: {
        audios: 'AutoCut을 사용하기 위해 하나 이상의 오디오 클립을 선택하세요.',
      },
    },
    upload: {
      screenshot: '스크린샷 제출',
    },
    modal: {
      feedback: {
        title: '피드백',
        input: {
          feedback: {
            title: 'AutoCut에 대한 의견은?',
            placeholder: '피드백 입력...',
          },
          improve: {
            title: '개선 제안 사항은?',
            placeholder: '개선 제안 입력...',
          },
        },
        button: {
          text: '제출',
        },
        sent: {
          title: '피드백 감사합니다!',
          text: '기능 아이디어가 있나요? 알려주세요!',
          button: {
            text: '기능 요청',
          },
        },
      },
      bug: {
        report: {
          title: '버그 보고',
          description: {
            placeholder: '문제를 설명하세요...',
            error: '버그 설명은 최소 20자 이상이어야 합니다.',
          },
          reproduce: {
            placeholder: '문제를 재현하는 단계들...',
            error: '재현 세부사항은 최소 20자 이상이어야 합니다.',
          },
          blocking: '이 문제가 저지될 수 있나요?',
          button: {
            text: {
              __text__: '버그 보고서 제출',
              success: '보고해 주셔서 감사합니다!',
            },
          },
          link: {
            error: '유효한 파일 링크를 제공하세요.',
          },
        },
      },
    },
    issue: {
      undo: {
        modal: '컷에 문제가 발생했습니까?',
      },
    },
    star: {
      review: {
        title: 'AutoCut 리뷰 남기기!',
        caption: {
          title: 'AutoCaptions 평가하기!',
        },
        text: 'AutoCut에 대한 경험을 나누세요!',
      },
    },
    review: {
      title: '추가 세부사항',
      text: '컷에 만족하셨습니까? 피드백을 남겨 AutoCut 개선에 도움을 주십시오!',
      form: {
        placeholder: '피드백 입력...',
      },
      button: '제출',
      thank: {
        title: '피드백 감사합니다!',
        text: '편집을 계속하세요.',
      },
    },
    exchange: {
      title: '7일 추가 체험 제공!',
      text: 'Adobe Exchange에서 AutoCut 평점을 남기고 7일 추가 체험을 받으세요!',
      button: 'Adobe Exchange에서 AutoCut을 평가',
      info: "사이트에서 직접 설치한 경우 재설치 필요 없음. '무료'를 클릭하여 평가를 시작합니다.",
    },
    licence: {
      trial: '체험',
      paid: '유료',
      info: '{licenceType} 라이센스',
      time: {
        left: '{daysLeft} 일 남음',
      },
    },
    trial: {
      promo: {
        text: '24시간 내 최대 20% 할인 제공',
      },
      resend: {
        error: '체험 키를 재전송하는 동안 오류 발생. 나중에 다시 시도하세요.',
      },
      expired: {
        modal: {
          title: '체험 만료됨.',
          text1: '체험이 만료되었습니다. 구독하여 AutoCut을 계속 사용하세요.',
          text2: '다음에서 AutoCut 대시보드를 방문하세요:',
          text3: '라이센스 키를 구독하세요.',
          text4: '지원이 필요하시다면: {supportEmail}로 연락하세요.',
          button: '20% 할인으로 구독하기',
        },
      },
      title: '무료 체험',
      stepper: {
        onboarding: 'AutoCut 개선을 위한 도움',
        email: '이메일 입력',
        sending: {
          email: '이메일 전송 중',
        },
        login: '라이센스 키 입력',
      },
      onboarding: {
        subtitle:
          '새롭고 강력한 DaVinci Resolve 기능을 개발하기 위해 통찰력을 공유',
        discover: 'AutoCut을 어떻게 발견하셨습니까?',
        job: {
          status: '현재 직업 상태',
        },
        type: {
          creation: 'DaVinci Resolve 프로젝트 유형',
        },
      },
      sending: {
        email: {
          success: '{email}로 이메일이 성공적으로 전송되었습니다',
        },
      },
      task: {
        caption: 'AutoCaptions 사용: <link>더 알아보기</link>',
        podcast: 'AutoCut Podcast 사용: <link>더 알아보기</link>',
        zoom: 'AutoZoom 사용: <link>더 알아보기</link>',
        repeat: 'AutoCut Repeat 사용: <link>더 알아보기</link>',
        broll: 'AutoB-Roll 사용: <link>더 알아보기</link>',
        swear: {
          word: 'AutoProfanity 필터 사용: <link>더 알아보기</link>',
        },
        preset: 'AutoCaptions, AutoZoom 또는 AutoResize에 대한 새 프리셋 생성',
        testimonial:
          '평가 남기기 (동일한 이메일 사용): <link>평가 남기기</link>',
      },
    },
    shop: {
      now: '지금 쇼핑하기',
    },
    back: {
      to_autocut: 'AutoCut으로 돌아가기',
    },
    'discover-option-youtube-video': 'YouTube 비디오',
    'discover-option-youtube-comment': 'YouTube 댓글',
    'discover-option-reddit': 'Reddit',
    'discover-option-google': 'Google',
    'discover-option-facebook': 'Facebook',
    'discover-option-friend': '친구',
    'discover-option-school': '학교',
    'discover-option-other': '기타',
    link: {
      not: {
        supported: {
          modal: {
            __text__: '더 많은 정보와 솔루션은 ',
            no_solution: '오류 설명은 ',
            end: '을(를) 참조하고, 우리의 문서 및 튜토리얼 사이트',
          },
        },
      },
    },
    text1: {
      use: {
        trial: {
          licence: '({email})로 이메일이 전송되었습니다',
        },
      },
    },
    text2: {
      use: {
        trial: {
          licence: '이메일이 없으면 스팸 또는 프로모션 폴더를 확인하세요.',
        },
      },
    },
    placeholder: {
      use: {
        licence: '라이센스 키 입력',
      },
    },
    "can't": {
      reach: {
        server: {
          error: '서버에 연결할 수 없습니다. 나중에 다시 시도하세요.',
        },
      },
      read: {
        uuid: {
          error: '컴퓨터의 UUID를 읽을 수 없습니다.',
        },
        host: {
          error: '컴퓨터의 이름을 읽을 수 없습니다.',
        },
      },
    },
    asking: {
      key: {
        error: '서버와의 키 확인 중 오류 발생. 나중에 다시 시도하세요.',
      },
    },
    sending: {
      log: {
        file: {
          error: '일일 로그 전송 실패. 나중에 다시 시도하세요.',
        },
      },
    },
    is_key: {
      valid: {
        error: '키 검증 중 오류 발생. 나중에 다시 시도하세요.',
      },
    },
    base: {
      error: {
        message: '오류 발생 ',
      },
    },
    invalid: {
      response: {
        error: '서버에서 예기치 않은 응답을 받았습니다.',
      },
    },
    description: {
      banner: {
        podcast: {
          beta: '개발 중; 문제나 제안 사항은 contact@autocut.com으로 연락바랍니다.',
        },
      },
    },
    header: {
      trial: {
        button: '더 알아보기',
      },
      paid: {
        button: '관리',
      },
      speakers: {
        name: '발표자 이름',
        track: '발표자의 오디오 트랙',
      },
      cameras: {
        name: {
          __text__: '카메라 비디오 트랙',
          helper:
            '각 카메라에 대해 비디오 트랙을 선택하세요. 한 트랙당 하나의 카메라입니다.',
        },
        speakers: {
          __text__: '보이는 발표자(들)',
          helper:
            '이 카메라에 보이는 발표자를 선택하세요. 여러 발표자가 허용됩니다.',
        },
      },
    },
    game: {
      saved: {
        hours: '{hourSavedText} 시간 절약됨,',
      },
      have: {
        a_break: '자격 있는 휴식을 취하세요!',
      },
    },
    onboarding: {
      skip: '온보딩 건너뛰기',
      close: '닫기',
      previous: '이전',
      next: '다음 ({current}/{max})',
      onboarding: {
        step1: {
          title: 'AutoCut에 오신 것을 환영합니다!',
          body: '첫 사용 시 AutoCut은 매끄러운 편집 경험을 위해 다양한 기능을 안내합니다. 시작해봅시다!',
        },
        step2: {
          title: '무료 체험 키',
          body: '체험 키 사용으로 모든 기능을 탐색하고 14일간 유효합니다.',
        },
        step3: {
          title: '라이센스 키',
          body: '라이센스 키(체험 또는 유료)를 입력하여 모든 AutoCut 도구에 액세스하세요.',
        },
        step4: {
          title: '라이센스 키 구매',
          body: '버튼을 통해 라이센스 키를 구매하세요. 이는 구매 정보와 함께 우리 사이트로 리디렉션됩니다.',
        },
      },
      modeSelection: {
        step1: {
          title: 'AutoCut 모드',
          body: '편집 필요에 맞는 모드를 사용 가능한 옵션 중에서 선택하세요.',
        },
        step2: {
          title: '도움이 필요하십니까?',
          body: '인터페이스 요소 위로 마우스를 가져가 도구 팁을 통해 자세히 알아보세요.',
        },
      },
      legacy: {
        step1: {
          title: 'AutoCut V2',
          body: 'AutoCut V2는 오디오 노이즈 레벨에 기반하여 무음을 감지합니다. 초기에 설정이 필요합니다.',
        },
        step2: {
          title: '노이즈 레벨',
          body: '최소 소음 수준을 설정하여 무음으로 분류합니다.',
        },
        step3: {
          title: '다음 단계',
          body: '화살표를 사용하여 단계 간 탐색하고 추가 AutoCut 기능을 탐색하세요.',
        },
        step4: {
          title: '미리보기 생성',
          body: '처리 전에 오디오를 내보냅니다. 오디오 컷을 시각화하려면 "미리보기 생성"을 클릭하세요.',
        },
        step6: {
          title: '컷할 시간',
          body: '미리보기를 보고 만족하면 컷을 진행하세요.',
        },
        step7: {
          title: '컷할 시간',
          body: '선호하는 컷 옵션을 선택하세요.',
        },
        step8: {
          title: '컷할 시간',
          body: '릴렉스하고 AutoCut이 편집을 처리하게 두세요!',
        },
      },
      podcast: {
        step1: {
          title: 'AutoCut Podcast',
          body: '자동화된 편집으로 AutoCut Podcast를 사용하여 팟캐스트 비디오를 빠르게 편집하세요.',
        },
        step2: {
          title: '발표자',
          body: '"발표자 추가" 버튼을 사용하여 발표자를 추가합니다. 각 발표자에 대해 하나의 트랙을 할당하세요.',
        },
        step3: {
          title: '발표자',
          body: '각 발표자의 설정을 필요에 따라 구성하세요.',
        },
        step4: {
          title: '발표자의 이름',
          body: '각 발표자에게 식별을 위한 이름을 할당하세요.',
        },
        step5: {
          title: '비디오 트랙 선택',
          body: '각 발표자를 표시하는 비디오 트랙을 선택하세요.',
        },
        step6: {
          title: '오디오 트랙 선택',
          body: '각 발표자에게 오디오 트랙을 할당하세요. 발표자 당 하나의 트랙을 사용합니다.',
        },
        step7: {
          title: '편집 시간',
          body: '발표자를 정의하고 편집을 진행하세요.',
        },
        step8: {
          title: '편집 시간',
          body: '화살표를 사용하여 편집 옵션을 선택하세요.',
        },
        step9: {
          title: '편집 시간',
          body: '릴렉스하고 AutoCut이 편집 과정을 관리하게 하세요.',
        },
      },
      title: 'AutoCut에 오신 것을 환영합니다',
      subtitle: '궁극의 DaVinci Resolve 확장입니다',
    },
    start: {
      transcription: '전사 시작',
    },
    no_more: {
      credits: '크레딧 소진됨',
    },
    file: {
      too: {
        long: '오디오 파일이 너무 깁니다',
      },
      sent: '파일이 업로드되었고, 전사를 기다리고 있습니다...',
    },
    please: {
      wait1: '오디오 전사를 기다리고 있습니다...',
      wait2: '이 과정은 길이에 따라 몇 분이 걸릴 수 있습니다.',
      wait3: '파일 업로드 중:',
    },
    language: {
      selection: {
        step: {
          title: {
            __text__: '오디오 언어',
            helper: '오디오 클립의 언어를 명시하세요',
          },
        },
        french: {
          __text__: '프랑스어',
          beta: '프랑스어 (베타)',
        },
        danish: '덴마크어',
        dutch: '네덜란드어',
        english: {
          __text__: '영어',
          united: {
            states: '영어 - 미국',
          },
        },
        flemish: '플랑드르어',
        german: {
          __text__: '독일어',
          beta: '독일어 (베타)',
        },
        hindi: {
          __text__: '힌디어',
          beta: '힌디어 (베타)',
        },
        italian: '이탈리아어',
        japanese: '일본어',
        korean: '한국어',
        norwegian: {
          __text__: '노르웨이어',
          nynorsk: '노르웨이 뉘노르스크어',
        },
        polish: '폴란드어',
        portuguese: {
          __text__: '포르투갈어',
          brazil: '포르투갈어 - 브라질',
          'brazil—beta': '포르투갈어 - 브라질 (베타)',
        },
        'portuguese—beta': '포르투갈어 (베타)',
        spanish: {
          __text__: '스페인어',
          latin: {
            america: '스페인어 - 라틴 아메리카',
            'america—beta': '스페인어 - 라틴 아메리카 (베타)',
          },
        },
        'spanish—beta': '스페인어 (베타)',
        swedish: '스웨덴어',
        tamil: '타밀어',
        chinese: '중국어',
        russian: '러시아어',
        turkish: '터키어',
        catalan: '카탈로니아어',
        arabic: {
          __text__: '아라비아어',
          algeria: '아라비아어 - 알제리',
          bahrain: '아라비아어 - 바레인',
          egypt: '아라비아어 - 이집트',
          iraq: '아라비아어 - 이라크',
          israel: '아라비아어 - 이스라엘',
          jordan: '아라비아어 - 요르단',
          kuwait: '아라비아어 - 쿠웨이트',
          lebanon: '아라비아어 - 레바논',
          mauritania: '아라비아어 - 모리타니아',
          morocco: '아라비아어 - 모로코',
          oman: '아라비아어 - 오만',
          qatar: '아라비아어 - 카타르',
          saudi: {
            arabia: '아라비아어 - 사우디아라비아',
          },
          palestine: '아라비아어 - 팔레스타인',
          syria: '아라비아어 - 시리아',
          tunisia: '아라비아어 - 튀니지',
          united: {
            arab: {
              emirates: '아라비아어 - 아랍에미리트',
            },
          },
          yemen: '아라비아어 - 예멘',
        },
        indonesian: '인도네시아어',
        finnish: '핀란드어',
        vietnamese: '베트남어',
        hebrew: '히브리어',
        ukrainian: '우크라이나어',
        greek: '그리스어',
        malay: '말레이어',
        czech: '체코어',
        romanian: '루마니아어',
        hungarian: '헝가리어',
        thai: '태국어',
        urdu: '우르두어',
        croatian: '크로아티아어',
        bulgarian: '불가리아어',
        lithuanian: '리투아니아어',
        latin: '라틴어',
        maori: '마오리어',
        malayalam: '말라얄람어',
        welsh: '웨일스어',
        slovak: '슬로바키아어',
        telugu: '텔루구어',
        persian: '페르시아어',
        latvian: '라트비아어',
        bengali: '벵골어',
        serbian: '세르비아어',
        azerbaijani: '아제르바이잔어',
        slovenian: '슬로베니아어',
        kannada: '칸나다어',
        estonian: '에스토니아어',
        macedonian: '마케도니아어',
        breton: '브르테뉴어',
        basque: '바스크어',
        icelandic: '아이슬란드어',
        armenian: '아르메니아어',
        nepali: '네팔어',
        mongolian: '몽골어',
        bosnian: '보스니아어',
        kazakh: '카자흐어',
        albanian: '알바니아어',
        swahili: '스와힐리어',
        galician: '갈리시아어',
        marathi: '마라티어',
        punjabi: '펀자브어',
        sinhala: '싱할라어',
        khmer: '크메르어',
        shona: '시오나어',
        yoruba: '요루바어',
        somali: '소말리어',
        afrikaans: '아프리칸스어',
        occitan: '옥시타니아어',
        georgian: '조지아어',
        belarusian: '벨라루스어',
        tajik: '타지크어',
        sindhi: '신디어',
        gujarati: '구자라트어',
        amharic: '암하라어',
        yiddish: '이디시어',
        lao: '라오어',
        uzbek: '우즈베크어',
        faroese: '페로어',
        haitian: {
          creole: '아이티 크리올어',
        },
        pashto: '파슈토어',
        turkmen: '투르크멘어',
        maltese: '몰타어',
        sanskrit: '산스크리트어',
        luxembourgish: '룩셈부르크어',
        burmese: '버마어',
        tibetan: '티베트어',
        tagalog: '타갈로그어',
        malagasy: '마다가스카르어',
        assamese: '아삼어',
        tatar: '타타르어',
        hawaiian: '하와이어',
        lingala: '링갈라어',
        hausa: '하우사어',
        bashkir: '바시키르어',
        javanese: '자와어',
        sundanese: '순다어',
      },
    },
    big: {
      sequence: {
        info: {
          message:
            '시퀀스에 많은 클립이 있습니다! 로드하는 데 더 오랜 시간이 걸릴 수 있습니다. 필요한 클립만 선택하고 별도의 시퀀스에서 자르세요.',
        },
      },
    },
    homepage: {
      title: '홈페이지',
      comming: {
        soon: '곧 출시!',
      },
    },
    autozoom: {
      dynamism: {
        __text__: '다이너미즘',
        normal: '보통',
        high: '높음',
        extreme: '극단적',
        custom: '맞춤:',
      },
      button: {
        __text__: '줌 적용',
        message: {
          step1: 'AutoZoom 준비 중...',
          step2: '오디오 데이터 추출 중...',
          step3: '줌 간격 계산 중...',
          step4: '줌 적용 {current} / {max}...',
        },
      },
      success: '성공! 줌 효과가 성공적으로 적용되었습니다.',
      info: {
        text: 'AutoZoom은 오디오 레벨을 기반으로 선택한 클립에 다이내믹 줌 효과를 추가합니다.',
      },
      invalid: {
        selection: {
          screen: {
            title: '타임라인에서 오디오 및 비디오 클립을 선택하세요',
            text1: 'AutoZoom을 적용하려면 타임라인의 클립을 선택하세요.',
          },
        },
      },
      anchor: {
        title: '앵커 포인트 설정',
        helper:
          '앵커 포인트는 줌이 집중되는 지점입니다. 색상 상자는 최대 및 최소 줌 효과를 보여줍니다.',
      },
      dual: {
        range: {
          title: '줌 계수 범위 정의',
          helper: '줌 레벨은 이 값과 클립의 최대 볼륨을 기반으로 합니다.',
        },
      },
      preview: {
        on_indicator: '지표에 중심 맞추기',
        error: {
          message: '미리보기 로드 오류',
          button: '다시 시도',
        },
      },
      min: {
        zoom: {
          title: '최소 줌 지속 시간',
          helper: '줌 효과는 이 시간보다 짧아지지 않습니다.',
        },
      },
      max: {
        zoom: {
          title: '최대 줌 지속 시간',
          helper: '줌 효과는 이 시간보다 길어지지 않습니다.',
        },
      },
      constraint: {
        zoom: {
          title: '클립에 맞춘 줌 제한',
          helper:
            '활성화되면, 줌 효과는 클립 내에서만 제한됩니다. 비활성화되면 전체 선택에 걸쳐 적용됩니다.',
        },
      },
      zoom: {
        types: {
          __text__: '줌 스타일',
          helper:
            '부드러운(점진적인 줌)과 동적(즉각적인 줌) 중에서 선택하세요.',
        },
        coverage: {
          __text__: '전체 줌 커버리지',
          helper: '적용된 줌의 백분율.',
        },
      },
      smooth: {
        __text__: '부드러운',
        zoom: {
          __text__: '부드러운 줌',
          coverage: '부드러운 줌 커버리지',
          nervousness: {
            __text__: '불안감',
            helper:
              '낮은 값은 느리고 부드러운 줌을, 높은 값은 빠르고 강한 줌을 나타냅니다.',
          },
        },
      },
      dynamic: {
        __text__: '동적',
        zoom: {
          __text__: '동적 줌',
          coverage: '동적 줌 커버리지',
        },
      },
    },
    zoom: {
      preset: {
        title: '기본 설정',
        calm: '차분한',
        paced: '정해진',
        energetic: '활기찬',
        hyperactive: '매우 활동적인',
      },
    },
    repeat: {
      info: {
        banner:
          'AutoCut Repeat는 반복적인 클립을 제거합니다. 정밀한 편집을 위해 가위를 사용하여 자르거나 유지하도록 전환합니다.',
      },
      text: {
        step: {
          edit: {
            transcript: '전사 수정',
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title:
              'AutoCut Repeat를 위한 타임라인 오디오 (및 선택적으로 비디오) 클립 선택',
            text1:
              'AutoCut Repeat를 위한 타임라인 오디오 (및 선택적으로 비디오) 클립 선택',
          },
        },
      },
      select: {
        algorithm: '알고리즘 선택',
      },
      precise: {
        algorithm: {
          info: '우리의 새로운 효율적인 알고리즘은 대략 1분의 비디오를 1분 안에 처리합니다.',
        },
      },
      fast: {
        algorithm: '빠름',
      },
      toggle: {
        cut: {
          button: {
            text: '컷 토글',
          },
        },
      },
      play: {
        button: {
          text: '재생',
        },
      },
    },
    caption: {
      step: {
        customization: '자막 사용자 정의',
        script: '클립 및 언어 선택',
        transcription: '전사 편집',
      },
      script: {
        transcript: {
          part: '전사',
        },
        sequence: {
          part: '시퀀스',
          name: '이름',
          track: {
            clip: {
              unit: {
                __text__: '{nbClips} 클립 | {from} → {to}',
                detail: '{clipName} | {from} → {to}',
              },
            },
          },
        },
      },
      transcript: {
        action: '자막 추가',
        coming: {
          soon: '자막 편집기가 곧 도착합니다! 현재 자막은 중요한 그래픽 패널을 통해 편집하세요. 타임라인의 자막을 선택하고 패널 편집 탭에서 수정하세요.',
        },
        transcription: {
          part: '전사',
        },
        info: '자막 편집기에 오신 것을 환영합니다 – 자막을 추가한 후 DaVinci Resolve 타임라인에서 직접 편집할 수 있습니다.',
        add: {
          emojis: {
            cta: '이모지 생성',
          },
        },
      },
      text: {
        noClip: {
          title: '자막을 위한 클립 선택',
          no_selection:
            'AutoCut이 응답하지 않으면 DaVinci Resolve를 다시 시작하세요.',
        },
      },
      process: {
        transcript: {
          button: '처리',
        },
      },
      customization: {
        info: '자동 자막은 베타 상태이며 11월 10일까지 안정화될 예정입니다.',
        back: {
          button: '전사 삭제 및 돌아가기',
        },
        example: {
          __text__:
            '미리보기 <opacity50><i>자막/이모지 위치를 조정하려면 드래그하세요</i></opacity50>',
          text: '자동 자막은 미리보기에서 자막을 추가합니다. AutoCaptions 내 옵션을 사용자 지정하고 필요한 경우 전사를 수정합니다. 자막은 타임라인에 즉시 추가됩니다.',
        },
        animation: {
          __text__: '애니메이션',
          helper: '텍스트 애니메이션 효과를 제어',
          color: {
            __text__: '하이라이트 <color>오디오</color>로',
            text: {
              color: '텍스트 색상',
            },
            before: {
              highlight: {
                color: '사전 강조 색상',
              },
            },
            after: {
              highlight: {
                color: '후 강조 색상',
              },
            },
          },
          advanced: {
            color: {
              options: '고급 색상 옵션',
            },
          },
          word: {
            box: '텍스트 <color>상자</color>로',
          },
          bold: {
            with: {
              text: '텍스트는 <b>굵게</b>입니다',
            },
          },
          floating: {
            text: '텍스트는 부유 중입니다',
          },
        },
        transition: {
          __text__: '전환',
          helper:
            '자막 전환을 제어합니다; 지속 시간은 예와 일치하지 않을 수 있습니다.',
          effect: {
            zoomIn: '줌 인',
            blurIn: '블러 인',
          },
        },
        outline: {
          __text__: '윤곽선',
          helper: '윤곽선 스타일을 제어',
          color: '윤곽선 색상',
          width: '윤곽선 너비',
        },
        font: {
          __text__: '글꼴',
          helper: '글꼴 스타일 관리',
          font: '글꼴 이름',
          help: '새 글꼴 패밀리를 추가하는 방법?',
          size: '글꼴 크기',
          weight: '글꼴 두께',
          color: '글꼴 색상',
        },
        formating: {
          __text__: '텍스트 서식',
          helper: '텍스트 서식 옵션 제어',
          ponctuation: '구두점 제거',
          uppercase: '대문자화',
          bold: '굵게',
          italic: '기울임꼴',
          glow: {
            __text__: '글로우',
            color: '글로우 색상',
            intensity: '글로우 강도',
          },
        },
        transform: {
          __text__: '변형',
          x: 'X 위치',
          y: 'Y 위치',
          helper: '자막 위치 및 회전을 조정',
          position: {
            __text__: '수직 위치',
            emoji: '이모지 수직 위치',
            info: '미리보기에서 텍스트 이동',
          },
          rotation: '회전',
        },
        multiple: {
          lines: '다중 줄',
        },
        box: {
          __text__: '상자',
          helper: '텍스트 박스의 스타일링 옵션',
        },
        boxes: {
          padding: {
            x: '수평 패딩',
            y: '수직 패딩',
          },
          opacity: '상자 불투명도',
          radius: '모서리 반경',
          color: '상자 색상',
        },
        emojis: {
          __text__: '이모지',
          helper: 'AI로 생성한 이모지',
          size: '이모지 크기',
        },
        shadow: '섀도우',
        transcript: '전사',
        action: '진행',
        select: {
          chunk: '편집하거나 사용자 지정할 전사 세그먼트 선택',
        },
        delete: {
          caption: {
            modal: {
              title: '자막 제거 확인',
              cancel: '아니오, 유지할래요',
              confirm: '네, 삭제합니다',
            },
          },
        },
        get: {
          presets: {
            number: {
              error:
                '프리셋 수를 가져오는 중 오류 발생. Discord 또는 contact@autocut.com으로 문의하세요.',
            },
            error:
              '프리셋을 가져오는 중 오류 발생. Discord 또는 contact@autocut.com으로 문의하세요.',
          },
          public: {
            presets: {
              error:
                '공용 프리셋을 가져오는 중 오류 발생. Discord 또는 contact@autocut.com으로 문의하세요.',
            },
          },
        },
        presets: {
          title: '프리셋',
          save: {
            new: {
              preset: '현재 설정을 프리셋으로 저장',
            },
          },
          view: {
            more: '더 보기',
            less: '덜 보기',
          },
        },
        save: {
          preset: {
            info: {
              text: '이 설정을 프리셋으로 저장하여 나중에 사용하세요!',
            },
          },
        },
      },
      editor: {
        title: '자막 편집기',
        description: {
          title: '편집할 자막 선택',
        },
        chunk: {
          low: {
            confidence: {
              tooltip: '신뢰도가 낮은 단어',
            },
          },
          merge: {
            tooltip: '청크 병합',
          },
          split: {
            tooltip: '청크 분할',
          },
        },
        sound: {
          play: '사운드 재생 활성화',
        },
      },
      fontSelect: {
        notFoundFontModal: {
          title: '글꼴 누락',
          message:
            '글꼴 <i><b>{fontLabel}</b></i>이(가) 사용할 수 없습니다. 기본 글꼴이 사용됩니다.',
          helper: '이 튜토리얼을 통해 새 글꼴을 추가하세요.',
        },
      },
    },
    version: {
      not: {
        supported: '지원되지 않는 버전',
      },
    },
    transcript: {
      usage: {
        unit: '분',
      },
      upgrade: {
        button: 'AI로 업그레이드하여 {mode}',
      },
      too: {
        short: {
          error:
            '전사가 너무 짧거나 언어가 지원되지 않을 수 있습니다. 문제가 지속되면 contact@autocut.com으로 연락하세요.',
        },
      },
    },
    feature: {
      request: {
        __text__: '기능 요청',
        form: {
          placeholder: '더 나은 고려를 위한 기능 요청 설명.',
        },
        screenshot: '명확성을 위해 스크린샷 또는 비디오를 첨부: ',
        button: '기능 요청 제출',
        success: {
          title: '제출 완료! 🥳',
          body: 'AutoCut 개선에 도움이되어 감사합니다! 우리는 귀하의 요청을 신속하게 검토할 것입니다.',
        },
        error: {
          title: '오, 어떤 문제가 발생했습니다... 🤕',
          body: '불편을 드려서 죄송합니다. 나중에 다시 시도하거나 contact@autocut.com으로 문의하세요.',
        },
      },
    },
    with: {
      emojis: '이모지와 함께',
    },
    resize: {
      invalid: {
        selection: {
          screen: {
            title: '타임라인에서 입력 및 출력 포인트 설정',
            text1:
              'AutoResize를 사용하려면 타임라인에서 입력 및 출력 포인트를 설정하세요.',
            cta: '전체 시퀀스를 위한 입력 및 출력 포인트 설정',
          },
        },
      },
      text: {
        info: 'AutoResize는 지능적으로 시퀀스를 소셜 미디어 포맷에 최적화하여 자동으로 조정하고 리프레임합니다.',
      },
      in_out: {
        step: {
          title: '입력/출력 포인트',
          helper: '입력/출력 포인트가 원하는 섹션과 일치하는지 확인하세요.',
        },
      },
      preset: {
        step: {
          title: '프리셋',
          helper:
            '시퀀스를 리사이즈하려면 프리셋을 선택하거나 사용자 정의를 만듭니다. 아래에서 차원을 확인하세요.',
        },
        undefined: '프리셋을 선택하세요.',
      },
      watermark: {
        step: {
          title: '워터마크',
          helper: '워터마크를 시퀀스의 오른쪽 하단 모서리에 추가합니다.',
          text: '워터마크 업로드',
        },
      },
      button: '소셜 클립 생성',
      reframe: {
        undefined: '"대상 팔로우" 속도를 선택하세요.',
        step: {
          title: '대상 팔로우',
          helper: '대상을 따라가는 카메라의 속도를 선택하세요.',
          slower: '느림',
          default: '보통',
          faster: '빠름',
        },
      },
      backup: '시퀀스를 백업 중...',
      create: {
        new: {
          sequence: '새 시퀀스를 생성 중...',
        },
      },
      resizing: {
        sequence: '새 시퀀스를 리사이즈 중...',
      },
      adding: {
        watermark: '워터마크 추가 중...',
      },
      cleaning: {
        sequence: '시퀀스를 정리 중...',
      },
    },
    broll: {
      invalid: {
        selection: {
          screen: {
            title: 'AutoB-Roll의 기본 클립 선택',
            text1:
              'AutoB-Roll을 사용하려면 최소 하나의 오디오가 포함된 클립을 선택하세요.',
          },
        },
      },
      text: {
        info: 'AutoB-Roll은 오디오를 분석하고 Pexels에서 관련 있는 B-Roll을 자동으로 추가합니다.',
      },
      duration: {
        step: {
          title: 'B-Roll 지속 시간',
          minimum: {
            title: '최소 B-Roll 지속 시간',
            helper: '각 B-Roll의 최소 지속 시간.',
          },
          maximum: {
            title: '최대 B-Roll 지속 시간',
            helper: '각 B-Roll의 최대 지속 시간.',
          },
        },
      },
      choice: {
        step: {
          title: 'B-Roll 선택',
        },
      },
      no_broll: {
        screen: {
          title: '전사가 완료되었지만 B-Roll을 찾을 수 없음.',
          text1:
            'AutoB-Roll을 사용하려면 말 영어가 포함된 오디오 클립을 선택하세요.',
          text2: '전사 언어를 확인하고 오디오 트랙이 음소거되지 않도록 합니다.',
        },
      },
      button: {
        __text__: 'B-Rolls 추가',
        next: '다음',
      },
      exporting: '선택된 오디오 클립 내보내기 중...',
      analyse: '오디오 분석 중...',
      getting: {
        broll: 'B-Rolls 가져오는 중... (몇 분 소요될 수 있습니다)',
      },
      transcript: {
        finished: '전사 생성 완료',
      },
      step: {
        parameters: 'B-Roll 설정',
        language: '클립 및 언어 선택',
        brolls: 'B-Roll 선택 편집',
      },
      adding: {
        broll: 'B-Rolls 추가 중...',
      },
      restore: '시퀀스를 복구 중...',
      backup: '시퀀스를 백업 중...',
      credit: {
        modal: {
          title: 'B-Roll 크레딧:',
          provided: {
            by: 'Pexels 제공',
          },
          link: '링크',
          button: '클립 보드에 복사',
        },
      },
      search: {
        modal: {
          title: 'B-Roll 선택',
          input: {
            placeholder: 'B-Rolls 검색',
          },
          searching: 'B-Rolls 검색 중...',
        },
      },
    },
    discordCta: {
      title: '디스코드',
    },
    double: {
      click: {
        to_edit: '편집하려면 두 번 클릭하세요',
      },
    },
    swear: {
      words: {
        invalid: {
          selection: {
            screen: {
              title: '불필요한 언어 필터링을 위한 클립 선택',
              text1:
                'AutoProfanity Filter를 사용하려면 오디오가 포함된 클립을 선택하세요.',
            },
          },
        },
        text: {
          info: 'AutoProfanity Filter는 AI를 사용하여 욕설을 감지하고 자동으로 삐 소리로 검열합니다.',
        },
        mute: '음소거',
        button: '욕설 제거',
        audio: {
          export: '오디오 내보내기 중...',
          analyse: '오디오 분석 중...',
          insert: '삐 소리 넣기 중...',
          filter: '볼륨 조절 적용 중...',
        },
        script: {
          transcript: {
            part: '전사',
          },
        },
      },
    },
    bleep: {
      selection: {
        step: {
          title: '삐 소리 효과 선택',
          helper: '삐 소리 효과를 선택하세요',
        },
      },
    },
    create: {
      format: {
        modal: {
          title: '새 포맷 생성',
          button: {
            __text__: '생성',
            disabled: '이름이 이미 차지 중',
          },
          name: '이름',
          width: '너비',
          height: '높이',
        },
      },
    },
    process: {
      modal: {
        warning:
          '잠재적 문제를 방지하기 위해 DaVinci Resolve에서 변경을 피하세요.',
        generating: {
          emojis: '전사로부터 이모지 생성 중...',
        },
        formatting: {
          numbers: '전사에서 숫자 형식 조정 중...',
        },
      },
    },
    backup: {
      modal: {
        title: 'AutoCut 백업',
        text: {
          '1': '처리 전 백업이 발생합니다. "AutoCut-Backup" 폴더에서 찾을 수 있습니다.',
          '2': '필요 시 사용하세요.',
        },
      },
    },
    confirm: {
      modal: {
        title: '확실합니까?',
        confirm: {
          label: '확인',
        },
        cancel: {
          label: '취소',
        },
      },
    },
    input: {
      speaker: {
        name: {
          placeholder: '발표자의 이름 입력...',
        },
      },
    },
    login: {
      resend: {
        email: '받지 못하셨나요? {trialEmail}로 이메일을 재전송하세요',
      },
    },
    typeofcreation: {
      option: {
        interview: '인터뷰',
        podcast: '팟캐스트',
        youtube: 'YouTube 비디오',
        short: 'TikTok/Shorts/Reels',
        internal: '내부 비디오',
        other: '기타',
      },
    },
    jobstatus: {
      option: {
        content: {
          creator: '콘텐츠 크리에이터',
        },
        video: {
          editor: {
            professional: '전문 비디오 편집자',
            semi: {
              professional: '준전문 비디오 편집자',
            },
            beginner: '초보자 비디오 편집자',
          },
        },
        student: '학생',
        other: '기타',
      },
    },
    progress: {
      caption: {
        modal: {
          message: {
            initialization: '자막 초기화 중...',
          },
        },
      },
    },
    stat: {
      undo: {
        modal: '멋져요! {minutes} 분 {seconds} 초 만에 완료되었습니다.',
      },
    },
    cancelCutModal: {
      timesaved: {
        this: {
          cut: '저장된 시간: <b>{minutesSaved} {minutesSaved, plural, one {분} other {분}}</b> 및 <b>{secondsSaved} {secondsSaved, plural, one {초} other {초}}</b>.',
        },
        all: {
          cuts: '모든 커트에서 총 시간 저장: <b>{hourSaved} {hourSaved, plural, one {시간} other {시간}}</b> 및 <b>{minutesSaved} {minutesSaved, plural, one {분} other {분}}</b>! 🎉',
        },
      },
    },
    selected: {
      clips: {
        helper:
          '배경 음악이나 게임 소리를 유지하기 위해 무음 탐지에서 오디오 트랙을 비활성화합니다. 특정 클립에서 무음을 유지하려면 해당 클립을 선택하지 마세요.',
        video: '선택된 비디오 트랙:',
        audio: '선택된 오디오 트랙:',
      },
    },
    free: {
      trial: {
        usage: {
          exceeded: {
            title: '무료 체험 한도 도달',
            cta: '지금 구독하기',
            text: '1000 AI 기능 분수를 초과했습니다.{br}AutoCut AI를 계속 사용하려면 구독하세요.',
          },
        },
      },
    },
    paid: {
      usage: {
        exceeded: {
          title: '전사 한도 도달',
          text: '6000 AI 기능 분수를 초과했습니다.{br}도움이 필요하면 저희에게 연락하세요.',
        },
      },
    },
    validation: {
      error: {
        ai_transitions:
          '유효한 전환 유형을 선택하세요 (보기 <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>).',
        ai_silences:
          '유효한 무음 처리 옵션을 선택하세요 (보기 <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>).',
        broll: {
          min: {
            max: {
              duration:
                '최대 B-Roll 시간이 최소를 초과해야 합니다 (보기 <a><b><embedTranslation>broll_duration_step_title</embedTranslation></b></a>).',
            },
          },
          minimumBRollTime:
            '최소 B-Roll 시간은 양수여야 합니다 (보기 <a><b><embedTranslation>broll_duration_step_minimum_title</embedTranslation></b></a>).',
          maximumBRollTime:
            '최대 B-Roll 시간은 양수여야 합니다 (보기 <a><b><embedTranslation>broll_duration_step_maximum_title</embedTranslation></b></a>).',
        },
        caption: {
          position:
            '자막은 미리보기 영역 내에 있어야 합니다 (보기 <a><b><embedTranslation>caption_customization_transform_position</embedTranslation></b></a>).',
          emoji: {
            position:
              '이모지는 미리보기 영역 내에 있어야 합니다 (보기 <a><b><embedTranslation>caption_customization_transform_position_emoji</embedTranslation></b></a>).',
          },
          text: {
            fontSize:
              '글꼴 크기는 양수여야 합니다 (보기 <a><b><embedTranslation>caption_customization_font_size</embedTranslation></b></a>).',
          },
          outline: {
            width:
              '윤곽선 너비는 양수여야 합니다 (보기 <a><b><embedTranslation>caption_customization_outline_width</embedTranslation></b></a>).',
          },
          transition: {
            effects:
              '유효한 자막 전환 효과를 선택하세요 (보기 <a><b><embedTranslation>caption_customization_transition</embedTranslation></b></a>).',
          },
          emojis: {
            size: '이모지 크기는 양수여야 합니다 (보기 <a><b><embedTranslation>caption_customization_font_size</embedTranslation></b></a>).',
          },
          formating: {
            nbLines:
              '최소 하나의 라인은 서식에 대해 선택되어야 합니다 (보기 <a><b><embedTranslation>caption_customization_formating_nbLines</embedTranslation></b></a>).',
          },
          title: {
            too: {
              short: '타이틀은 최소 5자 이상이어야 합니다.',
              long: '타이틀은 25자를 초과할 수 없습니다.',
            },
            no_space:
              '타이틀은 최소 두 개의 단어를 포함해야 하며, 공백으로 구분되어야 합니다.',
          },
        },
        font: {
          fontFamily:
            '글꼴 패밀리가 필요합니다 (보기 <a><b><embedTranslation>caption_customization_font_font</embedTranslation></b></a>).',
          label:
            '글꼴을 선택하세요 (보기 <a><b><embedTranslation>caption_customization_font_font</embedTranslation></b></a>).',
        },
        languageOfTranscription:
          '전사 언어를 선택하세요 (보기 <a><b><embedTranslation>language_selection_step_title_helper</embedTranslation></b></a>).',
        cameras: {
          speakerIds:
            '각 카메라는 최소 하나의 발표자가 할당되어야 합니다 (보기 <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
          label:
            '각 카메라는 비디오 트랙 할당이 필요합니다 (보기 <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
        },
        podcast: {
          minimum: {
            cam: {
              time: '최소 카메라 시간은 양수여야 합니다 (보기 <a><b><embedTranslation>text_step1_podcast_minimum</embedTranslation></b></a>).',
            },
          },
          maximum: {
            cam: {
              time: '최대 카메라 시간은 양수여야 합니다 (보기 <a><b><embedTranslation>text_step1_podcast_maximum</embedTranslation></b></a>).',
            },
          },
          deleteUnusedClips:
            '무음을 제거하거나 음소거할지 선택하세요 (보기 <a><b><embedTranslation>title_podcast_silences_step</embedTranslation></b></a>).',
          speakers: {
            cameras:
              '각 발표자는 하나의 카메라에 할당되어야 합니다 (보기 <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
            audio:
              '각 발표자는 할당된 오디오 트랙이 필요합니다 (보기 <a><b><embedTranslation>header_speakers_track</embedTranslation></b></a>).',
          },
          cameras: {
            __text__:
              '각 카메라는 자체 트랙이 있어야 합니다 (보기 <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
            length:
              '최소 두 개의 개별 카메라가 필요합니다 (보기 <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
          },
        },
        resize: {
          autoReframePreset: {
            required:
              '자동 리프레임 프리셋이 필요합니다 (보기 <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>).',
            invalid:
              '프리셋 옵션: 느림, 기본, 또는 빠름 (보기 <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>).',
          },
          watermark:
            '유효한 워터마크 형식: JPEG, PNG, 또는 WebP. (보기 <a><b><embedTranslation>resize_watermark_step_title</embedTranslation></b></a>).',
          preset: {
            name: '프리셋 이름이 필요합니다.',
            width: '너비는 최소 1픽셀 이상이어야 합니다.',
            height: '높이는 최소 1픽셀 이상이어야 합니다.',
          },
        },
        rgb: 'RGB 값은 0에서 255 사이여야 합니다.',
        silence: {
          noiseLevel: {
            type: '노이즈 레벨은 숫자여야 합니다 (보기 <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
            nonpositive:
              '노이즈 레벨은 양수일 수 없습니다 (보기 <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
            integer:
              '노이즈 레벨은 정수이어야 합니다 (보기 <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
          },
          minTimeOfSilence: {
            type: '무음 시간은 숫자여야 합니다 (보기 <a><b><embedTranslation>text_Step1_Silences</embedTranslation></b></a>).',
          },
          maxTimeOfSilence: {
            type: '무음 시간은 숫자여야 합니다 (보기 <a><b><embedTranslation>text_Step1_Talks</embedTranslation></b></a>).',
          },
          marginBefore: {
            type: '이전 패딩은 숫자여야 합니다 (보기 <a><b><embedTranslation>text_Step2_Before</embedTranslation></b></a>).',
          },
          marginAfter: {
            type: '이후 패딩은 숫자여야 합니다 (보기 <a><b><embedTranslation>text_Step2_After</embedTranslation></b></a>).',
          },
          transitions:
            '유효한 전환 유형을 선택하세요 (보기 <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>).',
          silences:
            '유효한 무음 처리 옵션을 선택하세요 (보기 <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>).',
        },
        swearWord: {
          bleepFile:
            '삐 소리 효과 선택 (보기 <a><b><embedTranslation>bleep_selection_step_title_helper</embedTranslation></b></a>).',
        },
        textBox: {
          xPadding:
            '수평 패딩은 숫자여야 합니다 (보기 <a><b><embedTranslation>caption_customization_boxes_padding_x</embedTranslation></b></a>).',
          yPadding:
            '수직 패딩은 숫자여야 합니다 (보기 <a><b><embedTranslation>caption_customization_boxes_padding_y</embedTranslation></b></a>).',
          opacity: {
            min: '불투명도는 최소 0% 이어야 합니다 (보기 <a><b><embedTranslation>caption_customization_boxes_opacity</embedTranslation></b></a>).',
            max: '불투명도는 최대 100%를 초과할 수 없습니다 (보기 <a><b><embedTranslation>caption_customization_boxes_opacity</embedTranslation></b></a>).',
          },
        },
        anchor:
          '앵커는 미리보기 영역 내에 있어야 합니다 (보기 <a><b><embedTranslation>autozoom_anchor_title</embedTranslation></b></a>).',
        zoom: {
          autoZoomMinCoef: {
            min: '최소 줌 계수는 1보다 커야 합니다 (보기 <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
            max: '최소 줌 계수는 2보다 작아야 합니다 (보기 <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          },
          autoZoomMaxCoef: {
            min: '최대 줌 계수는 1보다 커야 합니다 (보기 <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
            max: '최대 줌 계수는 2보다 작아야 합니다 (보기 <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          },
          minZoomTime: '최소 줌 시간은 양수여야 합니다.',
          maxZoomTime: '최대 줌 시간은 양수여야 합니다.',
          dynamicZoomPercentage: {
            min: '줌 비율은 0% 이상이어야 합니다',
            max: '줌 비율은 100% 이하이어야 합니다',
          },
          coef: '최대 줌 계수는 최소 줌 계수보다 커야 합니다 (보기 <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          time: '최대 줌 시간은 최소 줌 시간보다 길어야 합니다.',
          dynamicMinZoomIntensity:
            '동적 줌 강도는 0-100% 사이여야 합니다 (보기 <a><b><embedTranslation>autozoom_dynamic_zoom_coverage</embedTranslation></b></a>).',
          smoothZoomPercentage:
            '부드러운 줌 커버리지는 0-100% 사이여야 합니다 (보기 <a><b><embedTranslation>autozoom_smooth_zoom_coverage</embedTranslation></b></a>).',
          nervousness:
            '불안감 수준은 0-100% 사이여야 합니다 (보기 <a><b><embedTranslation>autozoom_smooth_zoom_nervousness</embedTranslation></b></a>).',
          totalZoomPercentage:
            '전체 줌 커버리지는 0-100% 사이여야 합니다 (보기 <a><b><embedTranslation>autozoom_zoom_coverage</embedTranslation></b></a>).',
          types: {
            at_least: {
              one: {
                true: '최소 하나의 줌 유형을 선택하세요 (보기 <a><b><embedTranslation>autozoom_zoom_types</embedTranslation></b></a>).',
              },
            },
          },
        },
        repeat: {
          algorithm: '잘못된 알고리즘이 선택되었습니다.',
        },
      },
    },
    key: {
      expired: {
        modal: {
          title: '결제 문제',
          button: {
            recover: '구독 복구',
          },
          to_continue:
            '결제 문제로 인해 구독이 <b>{status}</b>되었습니다. 계속 진행하려면:',
          deactivated: '비활성화됨',
          deleted: '삭제됨',
          recover: {
            list: {
              click:
                '구독을 복구하거나 결제 정보를 확인하려면 아래 버튼을 클릭하세요.',
              re_activated:
                '결제가 업데이트되면 키가 <b>즉시 재활성화</b>됩니다.',
            },
          },
          new: {
            list: {
              website:
                'AutoCut 방문: <span>https://www.autocut.com/#pricing</span>',
              subscribe: '새 라이센스 키 구입',
            },
          },
          support:
            '도움이 필요하신가요? 다음으로 연락하세요: <span>contact@autocut.com</span>',
          thanks: '감사합니다!',
        },
        moda: {
          button: {
            new: '새 구독 구매',
          },
        },
      },
    },
    gaming: {
      trial: {
        modal: {
          title: 'AutoCut 여정 시작하기',
          header: {
            days: {
              left: '14일간의 체험이 시작되었습니다. {completedElements}개의 추가 기능이 제공됩니다. 탐색할 {daysLeft}일 남았습니다!',
            },
            more: {
              days: 'AutoCut을 충분히 경험하기 위해 추가 <b>{bonusDays}일</b>을 제공합니다.',
            },
          },
          timeline: {
            left: '14일 무료 체험',
            right: '24일 무료 체험',
          },
          task: {
            list: {
              title: '작업 진행: {completed}/{maximum} 완료',
            },
          },
        },
      },
    },
    reload: {
      autocut: {
        modal: {
          title: '다시 로드 필요',
          cta: '모든 확장 닫기',
          text: 'AutoCut의 여러 인스턴스가 감지되었습니다. 아래 버튼을 클릭하여 닫아 문제를 방지하세요.',
        },
      },
    },
    'key-already-activated':
      '키가 다른 장치에서 활성화되었습니다. AutoCut 대시보드(https://www.autocut.com/en/)에서 연결을 해제한 후 다시 시도하세요.',
    'key-does-not-exist':
      '키를 찾을 수 없습니다. 키를 확인하고 다시 시도하세요.',
    unsupported: {
      version: {
        modal: {
          title: {
            __text__: '자르기를 위한 지원되지 않는 DaVinci Resolve 버전',
            '24.4.0':
              'DaVinci Resolve 24.3으로 다운그레이드하거나 24.5로 업그레이드하세요',
          },
          cta: '인식',
          text: {
            __text__:
              '지원되지 않는 DaVinci Resolve 버전({version})이 감지되었습니다. 경험이 영향을 받을 수 있습니다. <b>최신 안정 릴리스</b>를 사용하여 최적의 AutoCut 성능을 경험하세요.',
            '24.4.0':
              '지원되지 않는 DaVinci Resolve 버전({version}). AutoCut을 사용하려면 24.5로 업그레이드하거나 24.3으로 다운그레이드하세요.',
          },
        },
      },
      rendering: {
        engine: {
          title: '지원되지 않는 렌더링 엔진',
          modal: {
            text: '현재 렌더링 엔진은 지원되지 않습니다. AutoCaptions 및 AutoZoom이 작동하지 않습니다. {br}{br}프로젝트 설정에서 GPU 가속을 선택하세요.',
          },
        },
      },
    },
    handshake: {
      restart: {
        modal: {
          title: '다시 시작 필요',
          cta: '모든 확장 닫기',
          text: 'AutoCut의 여러 인스턴스가 감지되었습니다. 아래 버튼을 클릭하여 닫고 문제를 방지하세요.',
        },
      },
    },
    disabled: {
      features: {
        info: '선택한 언어/글꼴 조합이 비활성화된 기능: <b>{features}</b>. 자막에서 이 기능은 사용할 수 없습니다.',
        tips: '문자 지원 문제가 있을 가능성. 이 기능을 사용하려면 글꼴 또는 언어를 변경하세요.',
      },
    },
    upgrade: {
      to: '{plan}으로 업그레이드',
    },
    deepgram: {
      error: {
        retry: '다른 AI 모델로 전사를 다시 시도하세요.',
        modal: {
          title: '번역 오류',
          cta: '디스코드 가입',
          text: '오디오 번역 중 오류가 발생했습니다. 오디오 품질이나 언어 지원 문제일 수 있습니다. {br}Discord 통해 보고하거나 contact@autocut.com으로 이메일 하세요.',
        },
      },
    },
    completing: {
      preset: {
        preview: '프리셋 미리보기를 마치는 중... 거의 완료되었습니다.',
      },
    },
    chapters: {
      preview: {
        error:
          '미리보기를 생성하는 중 오류 발생. 타임스탬프를 조정하거나 문제가 지속되면 AutoCut을 다시 시작하세요.',
      },
      step: {
        language: '클립 및 언어 선택',
        editor: '챕터 편집기',
      },
      button: {
        chapters: '챕터 추가',
      },
      editor: {
        chapters: {
          title: '감지된 챕터',
        },
        parameters: {
          title: '챕터 설정',
        },
        download: {
          youtube: {
            chapters: {
              file: {
                download: 'YouTube 챕터 다운로드',
                success: '챕터.txt가 Premiere Pro 프로젝트에 저장됨',
              },
            },
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title: '챕터 기준용 클립 선택',
            text1: '최소 하나의 오디오가 포함된 클립을 선택하세요.',
          },
        },
      },
      process: {
        transcript: {
          button: '챕터 생성',
        },
      },
      adding: {
        chapters: '챕터 추가 중...',
      },
      backup: '시퀀스를 백업 중...',
      analyse: '오디오 분석 중...',
      getting: {
        chapters: '챕터 생성 중...',
      },
      transcript: {
        finished: '챕터 생성 완료',
      },
      disabled: {
        language: '선택한 언어는 챕터 감지를 지원하지 않습니다.',
        file: {
          too: {
            long: '클립이 최대 길이를 초과했습니다. {limit} 분 이하의 클립을 사용하세요.',
          },
        },
      },
    },
    time: {
      badge: {
        error: {
          wrong: {
            format: '잘못된 시간 형식입니다. HH:MM:SS를 사용하세요.',
          },
        },
      },
    },
    emoji: {
      picker: {
        animated: {
          option: '애니메이션',
        },
        static: {
          option: '정적',
        },
      },
    },
    viral: {
      clips: {
        invalid: {
          selection: {
            screen: {
              title: 'Viral Clips 감지를 위한 타임라인 클립 선택',
              text1: '최소 하나의 오디오가 포함된 클립을 선택하세요.',
            },
          },
        },
        disabled: {
          language: 'Viral Clips 감지는 이 언어를 지원하지 않습니다.',
          file: {
            too: {
              long: '클립이 너무 깁니다. {limit} 분 이하의 클립을 선택하세요.',
            },
          },
        },
        process: {
          transcript: {
            button: 'Viral Clips 생성',
          },
        },
        button: {
          process: '클립 생성',
        },
        selector: {
          title: '잠재적 바이럴 클립 {number}개 감지됨',
        },
        virality: {
          score: '바이럴 잠재 점수',
        },
        step: {
          language: '클립 및 언어 선택',
          selector: '바이럴 클립 선택',
        },
        backup: '시퀀스를 백업 중...',
        analyse: '오디오 분석 중...',
        getting: {
          clips: '바이럴 클립 생성 중...',
        },
        transcript: {
          finished: '바이럴 클립 생성 완료',
        },
        adding: {
          creating: {
            clips: '클립 생성 중...',
          },
        },
      },
      score: '바이럴 점수',
    },
    formatted: {
      numbers: {
        title: '형식화된 숫자',
        modal: {
          cta: '모달 닫기',
          text: '<b>{number} 단어</b>가 전사에서 숫자로 변환되었습니다.',
        },
      },
    },
    transcription: {
      provider: {
        step: {
          title: '전사 모델 선택',
          model: {
            '1': '모델 1 (기본)',
            '2': '모델 2',
          },
        },
      },
    },
  },
} as const;

export default translations;

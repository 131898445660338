import type {ResizedXMLTemplates} from './_resizeTemplate';

import {cloneDeep, merge} from 'lodash';

import {getHighlightTextProperties} from './_getHighlightTextProperties';
import {INITIAL_COPIED_VIDEO_FILTER_COMPONENTS} from './_getSelectedTransitions';
import {getXMLTextParameters} from './_getXMLTextParameters';
import {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import {CaptionChunk, CaptionChunkHighlight} from '@autocut/types/Captions';
import {secondToTick} from '../temp/_ticks.temp';
import {PproSourceTextParam} from '../_formatSourceTextData';

export const handleHighlightText = ({
  parameters,
  chunk,
  secondTimes,
  templates,
  wordIndex,
  highlight,
}: {
  parameters: CaptionsParameters;
  chunk: CaptionChunk;
  secondTimes: {start: number; end: number};
  templates: ResizedXMLTemplates;
  wordIndex: number;
  highlight: CaptionChunkHighlight;
}) => {
  const textProperties = getHighlightTextProperties({
    chunk,
    highlight,
    parameters,
  });

  const newClip = templates.captionsClip.clone({
    startTick: secondToTick(secondTimes.start).toString(),
    endTick: secondToTick(secondTimes.end).toString(),
    index: wordIndex,
    copiedVideoFilterComponents: INITIAL_COPIED_VIDEO_FILTER_COMPONENTS,
  });

  const xmlTextParameters = getXMLTextParameters(parameters);

  const clipProperties: PproSourceTextParam = merge(
    cloneDeep(xmlTextParameters),
    textProperties,
  );

  newClip.updateSourceTextParam(clipProperties);
};

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import css from './ViralityScore.module.scss';
import {Helper} from '@autocut/components/Helper/Helper';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';

export const ViralityScore = ({
  score,
  reason,
}: {
  score: number;
  reason: string;
}) => {
  return (
    <FlexContainer
      flexDirection="column"
      alignItems="center"
    >
      <Text
        style={{textAlign: 'center'}}
        variant="textXs"
      >
        <TranslatedMessage
          id="modes_viralClips_viralPotential"
          defaultMessage="Viral potential score"
        />
      </Text>
      <p className={css.title}></p>
      <FlexContainer
        justifyContent="center"
        flexWrap="nowrap"
        gap={8}
      >
        <Text>{score}</Text>
        <Helper
          // @ts-ignore We just want to display the default_message
          id_message={'default'}
          default_message={reason}
          size={12}
          position="top-right"
          textStyle={{maxWidth: '280px'}}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {HSLtoHex, rgbToHsl} from '@autocut/utils/color.utils';
import {setAutocutStore} from '@autocut/utils/zustand';
import React from 'react';

import {Switch} from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';
import ColorPicker from '@autocut/components/ColorPicker/ColorPicker';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';

export const HighlightParameters = () => {
  const {
    enabled: highlightEnabled,
    color: highlightColor,
    revealText: {enabled: revealTextEnabled, color: unrevealedTextColor},
    revealedTextColor,
  } = useAutoCutStore(state => ({
    ...state.ui.parameters.caption.animations.highlight,
    revealedTextColor: state.ui.parameters.caption.text.color,
  }));
  return (
    <ParameterLayout
      title={
        <TranslatedMessage
          id={
            'modes_caption_steps_customization_parts_style_modal_animation_highlight_title'
          }
          defaultMessage="The <color>color</color>"
          valuePamareters={{
            color: HSLtoHex({
              ...rgbToHsl(highlightColor),
              l: Math.max(rgbToHsl(highlightColor).l, 0.3),
            }),
          }}
        />
      }
    >
      <Switch
        checked={highlightEnabled}
        onChange={(checked: boolean) =>
          setAutocutStore(
            'ui.parameters.caption.animations.highlight.enabled',
            checked,
          )
        }
        size={16}
      />
      <ParametersGroupLayout
        status={highlightEnabled}
        hiddenContent={null}
      >
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_animation_highlight_color'
              }
              defaultMessage="Color"
            />
          }
        >
          <ColorPicker
            value={highlightColor}
            onChange={color =>
              setAutocutStore(
                'ui.parameters.caption.animations.highlight.color',
                color,
              )
            }
          />
        </ParameterLayout>

        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_animation_highlight_advancedColorOptions_title'
              }
              defaultMessage="Advanced color options"
              valuePamareters={{
                color: HSLtoHex({
                  ...rgbToHsl(highlightColor),
                  l: Math.max(rgbToHsl(highlightColor).l, 0.3),
                }),
              }}
              // values={{
              //   revealed: getDefaultTranslatedMessageValues({
              //     color: HSLtoHex({
              //       ...rgbToHsl(revealedTextColor),
              //       l: Math.max(rgbToHsl(revealedTextColor).l, 0.3),
              //     }),
              //   }).color,
              //   unrevealed: getDefaultTranslatedMessageValues({
              //     color: HSLtoHex({
              //       ...rgbToHsl(unrevealedTextColor),
              //       l: Math.max(rgbToHsl(unrevealedTextColor).l, 0.3),
              //     }),
              //   }).color,
              // }}
            />
          }
        >
          <Switch
            checked={revealTextEnabled}
            onChange={(checked: boolean) =>
              setAutocutStore(
                'ui.parameters.caption.animations.highlight.revealText.enabled',
                checked,
              )
            }
            size={16}
          />
        </ParameterLayout>

        <ParameterLayout>
          <ParametersGroupLayout
            status={revealTextEnabled}
            hiddenContent={null}
          >
            <ParameterLayout
              title={
                <FlexContainer>
                  <TranslatedMessage
                    id={
                      'modes_caption_steps_customization_parts_style_modal_animation_highlight_advancedColorOptions_revealedText'
                    }
                    defaultMessage="Color before highlight"
                  />
                </FlexContainer>
              }
            >
              <ColorPicker
                value={revealedTextColor}
                onChange={color =>
                  setAutocutStore('ui.parameters.caption.text.color', color)
                }
              />
            </ParameterLayout>
            <ParameterLayout
              title={
                <FlexContainer>
                  <TranslatedMessage
                    id={
                      'modes_caption_steps_customization_parts_style_modal_animation_highlight_advancedColorOptions_unrevealedText'
                    }
                    defaultMessage="Color after highlight"
                  />
                </FlexContainer>
              }
            >
              <ColorPicker
                value={unrevealedTextColor}
                onChange={color =>
                  setAutocutStore(
                    'ui.parameters.caption.animations.highlight.revealText.color',
                    color,
                  )
                }
              />
            </ParameterLayout>
          </ParametersGroupLayout>
        </ParameterLayout>
      </ParametersGroupLayout>
    </ParameterLayout>
  );
};

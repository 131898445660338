import {z as zod} from 'zod';
import {resizePresetValidationSchema} from './resizePreset.validationSchema';

const ACCEPTED_IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
];

export const resizeValidationSchema = zod.object({
  preset: resizePresetValidationSchema,
  autoReframePreset: zod.enum(['slower', 'default', 'faster', 'none'], {
    required_error: 'validation_error_resize_autoReframePreset_required',
    invalid_type_error: 'validation_error_resize_autoReframePreset_invalid',
  }),
  watermark: zod
    .instanceof(File)
    .optional()
    .refine(
      file => !file || ACCEPTED_IMAGE_MIME_TYPES.includes(file?.type),
      'validation_error_resize_watermark',
    ),
});

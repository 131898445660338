import type {CaptionsParameters} from '@autocut/types/CaptionsParameters';

export const getIsUppercase = (parameters: CaptionsParameters) => {
  const isUppercaseAllowed =
    !parameters?.languageOfTranscription?.unsupportedFeatures?.(
      parameters.text.font,
    )?.uppercase?.disabled;

  return isUppercaseAllowed && parameters.formating.uppercase;
};

export type mogrtClip = {
  trackItem: {
    videoClipTrackItem: {
      id: string;
      clipTrackItem: {
        components: {
          videoComponentChain: {
            id: string;
            componentChain: {
              components: {
                component: {
                  videoFilterComponent: {
                    id: string;
                    component: {
                      id: string;
                      params: {
                        param: {
                          arbVideoComponentParam: {
                            id: string;
                          };
                        }[];
                      };
                    };
                  };
                };
              };
            };
          };
        };
        subClip: {
          id: string;
          clip: {
            videoClip: {
              id: string;
              clipId: string;
            };
          };
        };
      };
    };
  };
};

export enum CaptionsXmlProjectTrackIndex {
  TEXT_BACKGROUND = 0,
  WORD_BACKGROUND = 1,
  CAPTIONS = 2,
  EMOJI = 3,
  EFFECTS = 4,
  FLOATING_TEXT_EFFECT = 5,
}

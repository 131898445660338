import {PProLocale} from '@autocut/contexts/LocaleContext';
import {
  localTransformEffectName,
  localUniformScaleEffectName,
  localScaleWidthEffectName,
  localBlurEffectName,
  localBlurrinessName,
} from '@autocut/enums/videoEffect.enum';
import {captionValidationSchema} from '@autocut/validationSchemas/caption.validationSchema';

import {z as zod} from 'zod';

export type TransitionSlugs = zod.infer<
  typeof captionValidationSchema
>['transition']['effects'][number];

export type EffectData = (locale: PProLocale) => {
  componentName: string;
  properties: {
    name: string;
    keyFrames: [number, any][];
  }[];
}[];

export const CAPTIONS_TRANSITIONS: Record<TransitionSlugs, EffectData> = {
  zoomIn: (locale: PProLocale) => [
    {
      componentName: localTransformEffectName[locale],
      properties: [
        {name: localUniformScaleEffectName[locale], keyFrames: [[0, false]]},
        {
          name: localScaleWidthEffectName[locale],
          keyFrames: [
            [0, 93],
            [0.05, 98],
            [0.1, 100],
          ],
        },
      ],
    },
  ],
  blurIn: (locale: PProLocale) => [
    {
      componentName: localBlurEffectName[locale],
      properties: [
        {
          name: localBlurrinessName[locale],
          keyFrames: [
            [0, 30],
            [0.05, 10],
            [0.1, 0],
          ],
        },
      ],
    },
  ],
};

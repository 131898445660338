import {setAutocutStore} from '@autocut/utils/zustand';
import React from 'react';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {HighlightParameters} from './HighlightParameters';
import {WordBoxParameters} from './WordBoxParameters';
import {Switch} from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';

const AnimationsCustomization = () => {
  const {enabled, floatingText} = useAutoCutStore(
    state => state.ui.parameters.caption.animations,
  );

  return (
    <ParametersGroupLayout
      style={{
        overflow: 'hidden',
      }}
      status={enabled}
      onStatusChange={checked =>
        setAutocutStore('ui.parameters.caption.animations.enabled', checked)
      }
      title={
        <TranslatedMessage
          id={
            'modes_caption_steps_customization_parts_style_modal_animation_title'
          }
          defaultMessage="Animation"
        />
      }
    >
      <HighlightParameters />

      <ParameterLayout
        title={
          <TranslatedMessage
            id={
              'modes_caption_steps_customization_parts_style_modal_animation_floatingText'
            }
            defaultMessage="Floating text"
          />
        }
      >
        <Switch
          checked={floatingText}
          onChange={value =>
            setAutocutStore(
              'ui.parameters.caption.animations.floatingText',
              value,
            )
          }
          size={16}
        />
      </ParameterLayout>

      <WordBoxParameters />
    </ParametersGroupLayout>
  );
};

export default AnimationsCustomization;

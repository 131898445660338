import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import {RadioForm} from '@autocut/designSystem/components/molecules/RadioForm/RadioForm';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useIntl} from 'react-intl';

export type SilenceStepProps = {
  modeId?: 'repeat' | 'silence';
};

export const SilenceStep = ({modeId = 'silence'}: SilenceStepProps) => {
  const intl = useIntl();
  const cuttingParameters = useAutoCutStore(
    state => state.ui.parameters[modeId],
  );

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'modes_silence_steps_silenceManagement_title',
        defaultMessage: 'Manage silences',
      })}
    >
      <PaddedSection>
        <RadioForm
          options={[
            {
              title: intl.formatMessage({
                id: 'modes_silence_steps_silenceManagement_choices_delete',
                defaultMessage: 'Remove silences',
              }),
              name: 'delete',
              value: 'delete',
              defaultChecked:
                cuttingParameters.silences === 'delete' ||
                !cuttingParameters.silences,
            },
            {
              title: intl.formatMessage({
                id: 'modes_silence_steps_silenceManagement_choices_keep',
                defaultMessage: 'Keep silences',
              }),
              name: 'keep',
              value: 'keep',
              defaultChecked: cuttingParameters.silences === 'keep',
            },
            {
              title: intl.formatMessage({
                id: 'modes_silence_steps_silenceManagement_choices_mute',
                defaultMessage: 'Mute silences',
              }),
              name: 'mute',
              value: 'mute',
              defaultChecked: cuttingParameters.silences === 'mute',
            },
            {
              title: intl.formatMessage({
                id: 'modes_silence_steps_silenceManagement_choices_delete_but_keep',
                defaultMessage: 'Remove silences but keep spaces',
              }),
              name: 'delete_keep_spaces',
              value: 'delete_keep_spaces',
              defaultChecked:
                cuttingParameters.silences === 'delete_keep_spaces',
            },
          ]}
          onChange={newValue => {
            setAutocutStore(
              `ui.parameters.${modeId}.silences`,
              newValue as typeof cuttingParameters.silences,
            );

            if (newValue !== 'delete') {
              setAutocutStore(`ui.parameters.${modeId}.transistions`, 'none');
            }
          }}
        />
      </PaddedSection>
    </FormSection>
  );
};

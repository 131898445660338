import {Env} from '@autocut/utils/currentEnv.utils';

const AUTOCUT_CONSTANTS = {
  [Env.Development]: {
    JULY_ADDRESS: 'https://july-api-staging.onrender.com',
    GTM_ID: 'GTM-NLNML5QZ',
    JULY_API_KEY: 'sF9bSeLtJYNRkiFodE7dhbdL7boQ5i8bHCkXFndK',
    JULY_SECRET_KEY: 'GZouSDLspL2KL6MM%okWB@#QJK^n@Hs',
    ALGOLIA_PROJECT_ID: 'ZVSLMFJH8J',
    ALGOLIA_API_KEY: '03e75d140c544402cac0c07982ebbfe1',
    ALGOLIA_PUBLIC_CAPTIONS_PRESETS_INDEX: 'public-captions-presets-dev',
  },
  [Env.Production]: {
    JULY_ADDRESS: 'https://july.autocut.fr',
    GTM_ID: 'GTM-MLZ5MM4T',
    JULY_API_KEY: 'RftDDTeX8DfxXXXsTeqQyekmiR3SogCGncyodgBS',
    JULY_SECRET_KEY: 'GZouSDLspL2KL6MM%okWB@#QJK^n@Hs',
    ALGOLIA_PROJECT_ID: 'ZVSLMFJH8J',
    ALGOLIA_API_KEY: '03e75d140c544402cac0c07982ebbfe1',
    ALGOLIA_PUBLIC_CAPTIONS_PRESETS_INDEX: 'public-captions-presets',
  },
  [Env.Staging]: {
    JULY_ADDRESS: 'https://staging.july.autocut.fr',
    GTM_ID: 'GTM-NLNML5QZ',
    JULY_API_KEY: 'sF9bSeLtJYNRkiFodE7dhbdL7boQ5i8bHCkXFndK',
    JULY_SECRET_KEY: 'GZouSDLspL2KL6MM%okWB@#QJK^n@Hs',
    ALGOLIA_PROJECT_ID: 'ZVSLMFJH8J',
    ALGOLIA_API_KEY: '03e75d140c544402cac0c07982ebbfe1',
    ALGOLIA_PUBLIC_CAPTIONS_PRESETS_INDEX: 'public-captions-presets-dev',
  },
} satisfies {
  [key in Env]: any;
};

export {AUTOCUT_CONSTANTS};

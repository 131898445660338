import {measureTextOnCanvas} from '@autocut/pages/modes/captions/utils/measureTextOnCanvas';
import type {CaptionChunk} from '@autocut/types/Captions';
import {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import {getFontParameters} from './getFontParameters';
import {getIsUppercase} from './getIsUppercase';
import {getHighlightPosition} from './getHighlightPosition';
import {getWordValue} from './getWordValue';

export const calculateWordMetrics = async ({
  chunks,
  parameters,
}: {
  chunks: CaptionChunk[];
  parameters: CaptionsParameters;
}) => {
  const isUppercase = getIsUppercase(parameters);
  const updatedChunks: CaptionChunk[] = await Promise.all(
    chunks.map(async chunk => {
      const chunkHighlightMetrics = await Promise.all(
        chunk.highlight.map(async highlight => {
          const wordWidth = measureTextOnCanvas(
            highlight.word,
            getFontParameters(parameters),
            isUppercase,
          ).metrics.width;

          const position = await getHighlightPosition({
            highlightLineText: chunk.lines[highlight.indexLine]
              .map(word => getWordValue(word))
              .join(' '),
            highlightWord: highlight.word,
            indexLine: highlight.indexLine,
            lineBeforeWord: highlight.lineBeforeWord,
            properties: {
              fontParameters: getFontParameters(parameters),
              uppercase: isUppercase,
            },
          });

          return {
            ...highlight,
            wordWidth,
            position,
          };
        }),
      );

      return {
        ...chunk,
        highlight: chunkHighlightMetrics,
      };
    }),
  );

  return {
    chunks: updatedChunks,
  };
};

import {defaultTextBox} from '@autocut/enums/modes.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {rgbToHex} from '@autocut/utils/color.utils';
import {setAutocutStore} from '@autocut/utils/zustand';
import React from 'react';
import {Switch} from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';
import {Slider} from '@autocut/designSystem/components/atoms/Slider/Slider';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import ColorPicker from '@autocut/components/ColorPicker/ColorPicker';
import {InfoText} from '@autocut/components/InfoText/InfoText';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';

export const WordBoxParameters = () => {
  const {
    textColor,
    wordBox: {
      xPadding: wordBoxXPadding,
      yPadding: wordBoxYPadding,
      opacity: wordBoxOpacity,
      color: wordBoxColor,
      enabled: wordBoxEnabled,
      radius: wordBoxRadius,
    },
    highlight: {enabled: highlightEnabled, color: highlightColor},
    font,
    unsupportedFeatures,
  } = useAutoCutStore(state => ({
    //@ts-ignore
    wordBox: defaultTextBox,
    textColor: state.ui.parameters.caption.text.color,
    font: state.ui.parameters.caption.text.font,
    unsupportedFeatures:
      state.ui.parameters.caption.languageOfTranscription.unsupportedFeatures,
    ...state.ui.parameters.caption.animations,
  }));

  const isNotSupported = unsupportedFeatures?.(font).wordBox.disabled;

  return (
    <ParameterLayout
      title={
        <TranslatedMessage
          id={
            'modes_caption_steps_customization_parts_style_modal_animation_wordBox'
          }
          defaultMessage="Box with text"
          valuePamareters={{
            color: rgbToHex(highlightEnabled ? highlightColor : textColor),
            backgroundColor: rgbToHex(wordBoxColor),
          }}
        />
      }
    >
      <Switch
        checked={wordBoxEnabled && !isNotSupported}
        onChange={() =>
          !isNotSupported &&
          setAutocutStore(
            'ui.parameters.caption.animations.wordBox.enabled',
            !wordBoxEnabled,
          )
        }
        size={16}
      />
      <ParametersGroupLayout
        status={wordBoxEnabled && !isNotSupported}
        hiddenContent={
          isNotSupported && (
            <InfoText style="warning">
              <FlexContainer
                flexDirection="column"
                gap={4}
              >
                <TranslatedMessage
                  id={
                    'modes_caption_steps_customization_parts_style_modal_box_disabled'
                  }
                  defaultMessage={
                    'The font you have chosen does not support the box feature for the language you have selected.'
                  }
                />
                <TranslatedMessage
                  id={
                    'modes_caption_steps_customization_parts_style_modal_box_tips'
                  }
                  defaultMessage={
                    'Pick a font that supports the language you have selected if you want to use this feature.'
                  }
                />
              </FlexContainer>
            </InfoText>
          )
        }
      >
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_text_color'
              }
              defaultMessage="Color"
            />
          }
        >
          <ColorPicker
            value={wordBoxColor}
            onChange={color =>
              setAutocutStore(
                'ui.parameters.caption.animations.wordBox.color',
                color,
              )
            }
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_box_paddingX'
              }
              defaultMessage="Padding x"
            />
          }
          wrap={true}
        >
          <Slider
            value={
              isNaN(wordBoxXPadding) || !wordBoxXPadding
                ? `0`
                : `${wordBoxXPadding}`
            }
            min={0}
            max={100}
            variant="valueOnLeft"
            unit="%"
            onChange={value =>
              setAutocutStore(
                'ui.parameters.caption.animations.wordBox.xPadding',
                value,
              )
            }
          />
        </ParameterLayout>

        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_box_paddingY'
              }
              defaultMessage="Padding y"
            />
          }
          wrap={true}
        >
          <Slider
            value={
              isNaN(wordBoxYPadding) || !wordBoxYPadding
                ? `0`
                : `${wordBoxYPadding}`
            }
            min={0}
            max={100}
            variant="valueOnLeft"
            unit="%"
            onChange={value =>
              setAutocutStore(
                'ui.parameters.caption.animations.wordBox.yPadding',
                value,
              )
            }
          />
        </ParameterLayout>

        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_box_opacity'
              }
              defaultMessage="Opacity"
            />
          }
          wrap={true}
        >
          <Slider
            value={
              isNaN(wordBoxOpacity) || !wordBoxOpacity
                ? `0`
                : `${wordBoxOpacity}`
            }
            min={0}
            max={100}
            variant="valueOnLeft"
            unit="%"
            onChange={value =>
              setAutocutStore(
                'ui.parameters.caption.animations.wordBox.opacity',
                value,
              )
            }
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_style_modal_box_radius'
              }
              defaultMessage="Radius"
            />
          }
          wrap={true}
        >
          <Slider
            value={
              isNaN(wordBoxRadius) || !wordBoxRadius ? `0` : `${wordBoxRadius}`
            }
            min={0}
            max={100}
            variant="valueOnLeft"
            unit="%"
            onChange={value =>
              setAutocutStore(
                'ui.parameters.caption.animations.wordBox.radius',
                value,
              )
            }
          />
        </ParameterLayout>
      </ParametersGroupLayout>
    </ParameterLayout>
  );
};

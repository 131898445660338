import type {ProcessProgress} from '@autocut/utils/process/progress';

import {StepperRouterStep} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import {OS_MAC, OS_WINDOWS} from '@autocut/constants/constants';
import {
  AutocutModes,
  AutocutModesKeys,
  AutocutModeState,
} from '@autocut/enums/modes.enum';
import {BRoll} from '@autocut/types/BRolls';
import {CaptionChunk} from '@autocut/types/Captions';
import {Utterance} from '@autocut/types/Deepgram';
import {Font} from '@autocut/types/font';
import {Licence} from '@autocut/types/IsKeyValidResponse';
import {JSONTimeline} from '@autocut/types/JSONTimeline';
import {Progress} from '@autocut/types/Progress';
import {ResizePreset} from '@autocut/types/Resize';
import {StartUpModal} from '@autocut/types/StartUpModal';
import {NeedingTranscriptionModes} from '@autocut/types/Transcription';
import {TrialTask} from '@autocut/types/TrialTask';
import {
  getKey,
  getLocalStorage,
  setLocalStorage,
} from '@autocut/utils/localStorage.utils';
import {getOS} from '@autocut/utils/system/os.system.utils';
import {DEFAULT_UUID} from '@autocut/utils/system/uuid.system.utils';
import {
  DeepTypeSearch,
  GenerateKeys,
  isFunction,
} from '@autocut/utils/type.utils';
import {parametersValidationSchema} from '@autocut/validationSchemas/parameters.validationSchema';
import {createStore} from '@udecode/zustood';
import {cloneDeep, merge} from 'lodash';
import {z as zod} from 'zod';

import {AutocutModeIds} from '../enums/modes.enum';
import {defaultTrialTasks} from './game/trialGamfication.util';

// ======== Types ========
export type AutoCutStateKeys = GenerateKeys<AutoCutState>;
export type AutoCutStateValueType<K extends AutoCutStateKeys> = DeepTypeSearch<
  AutoCutState,
  K
>;
export type ProgressState = {
  percentage: number;
  current: number;
  max: number;
};

export type AutoCutStateLocalStorageSyncConfig = {
  [K in AutoCutStateKeys]?: {localStorageKey: string; defaultValue?: any};
};
// TODO : traduire spécificité ppro
// export type AutoCutStateCEPSyncConfig = {
//   [K in AutoCutStateKeys]?: ArgTypes<Scripts['setSharedStore']>[0];
// };
export type AutoCutStateSubscriberAction = (key: AutoCutStateKeys) => void;
export type AutoCutStateSubscriberConfig = {
  [K in AutoCutStateKeys]?:
    | AutoCutStateSubscriberAction
    | AutoCutStateSubscriberAction[];
};

// ======== Local Storage sync ========

// To synchronise a field with local storage, add it to this object with the name of the local storage key
const localStorageSyncKeys: AutoCutStateLocalStorageSyncConfig = {
  'user.key': {localStorageKey: 'userKey'},
  'user.isBetaOnboarded': {localStorageKey: 'isBetaOnboarded'},
  'dev.debugCompute': {localStorageKey: 'debugCompute'},
  'ui.resize.customPresets': {
    localStorageKey: 'resizeCustomPresets',
    defaultValue: [],
  },
  'ui.host': {
    localStorageKey: 'lastHost',
    defaultValue: [],
  },
  //Modes parameters
  'ui.parameters.broll': {
    localStorageKey: 'brollParams',
    defaultValue: AutocutModes.BRoll.defaultParameters,
  },
  'ui.parameters.caption': {
    localStorageKey: 'captionsParams',
    defaultValue: AutocutModes.Captions.defaultParameters,
  },
  'ui.parameters.podcast': {
    localStorageKey: 'podcastParams',
    defaultValue: AutocutModes.Podcast.defaultParameters,
  },
  'ui.parameters.resize': {
    localStorageKey: 'resizeParams',
    defaultValue: AutocutModes.Resize.defaultParameters,
  },
  'ui.parameters.silence': {
    localStorageKey: 'cuttingParams',
    defaultValue: AutocutModes.Legacy.defaultParameters,
  },
  'ui.parameters.repeat': {
    localStorageKey: 'repeatParams',
    defaultValue: AutocutModes.Repeat.defaultParameters,
  },
  'ui.parameters.swear_word': {
    localStorageKey: 'swearWordParams',
    defaultValue: AutocutModes.SwearWords.defaultParameters,
  },
  'ui.parameters.chapters': {
    localStorageKey: 'chaptersParams',
    defaultValue: AutocutModes.Chapters.defaultParameters,
  },
  'ui.parameters.viral_clips': {
    localStorageKey: 'viralClipsParams',
    defaultValue: AutocutModes.ViralClips.defaultParameters,
  },
  'ui.parameters.zoom': {
    localStorageKey: 'zoomParams',
    defaultValue: AutocutModes.Zoom.defaultParameters,
  },
  'game.trial.tasks': {
    localStorageKey: 'trialTasks',
    defaultValue: defaultTrialTasks,
  },
} as const;

//Set the value of the local storage with the value of the store
export const syncToLocalStorage = (key: AutoCutStateKeys) => {
  setLocalStorage(
    localStorageSyncKeys[key]?.localStorageKey ?? '',
    deepFind(autocutStoreVanilla(), key),
  );
};

//Set the value of the store with the value of the local storage (if exists)
export const syncStoreFromLocalStorage = (key: AutoCutStateKeys) => {
  const value = getLocalStorage(
    localStorageSyncKeys[key]?.localStorageKey ?? '',
    false,
  );
  if (value != undefined && value != null && typeof value != 'object') {
    setAutocutStore(key, value);
    return;
  }

  const defaultValue = localStorageSyncKeys[key]?.defaultValue;
  if (defaultValue === undefined || defaultValue === null) {
    return;
  }
  const defaultValueClone = cloneDeep(defaultValue);
  let mergedParams = merge(defaultValueClone, value ?? {});

  // Fix the parameters if they are not valid
  if (key.startsWith('ui.parameters')) {
    const modeId = key.substring('ui.parameters.'.length) as AutocutModeIds;
    const paramValidator = parametersValidationSchema[modeId];
    try {
      paramValidator.parse(mergedParams);
    } catch (error: any) {
      if (modeId === 'caption') {
        mergedParams = defaultValue;
        error.issues = [];
      }

      for (const issue of error.issues) {
        const issuePath = issue.path as string[];
        const issuePathString = issuePath.join('.');
        const paramDefaultValue = deepFind(defaultValue, issuePathString);

        try {
          mergedParams = deepSet(mergedParams, issuePath, paramDefaultValue);
        } catch (setError) {
          mergedParams = defaultValue;
        }
      }
    }
  }

  setAutocutStore(key, mergedParams);
};

//Set the value of the store with the value of the local storage (if exists)
export const syncAllStoreFromLocalStorage = () => {
  Object.keys(localStorageSyncKeys).forEach(key =>
    syncStoreFromLocalStorage(key as AutoCutStateKeys),
  );
};

// ======== CEP sync ========

// TODO : traduire spécificité ppro
// To synchronise a field with CEP, add it to this object with the name of the sync function
// const cepSyncKeys: AutoCutStateCEPSyncConfig = {
//   'ui.process.isProcessing': 'process.isProcessing',
// };

// const syncCep = async (key: AutoCutStateKeys) => {
//   const cepKey = cepSyncKeys[key] as any;

//   await evalTS('setSharedStore', cepKey, deepFind(autocutStoreVanilla(), key));
// };

// ======== Subscribers ========
const buildOnStateUpdate = (customs: AutoCutStateSubscriberConfig) => {
  const result: AutoCutStateSubscriberConfig = {};

  //Add local storage sync subscribers
  Object.keys(localStorageSyncKeys).forEach(key => {
    result[key as AutoCutStateKeys] = syncToLocalStorage;
  });

  // TODO : traduire spécificité ppro
  //Add CEP sync subscribers
  // Object.keys(cepSyncKeys).forEach(key => {
  //   result[key as AutoCutStateKeys] = syncCep;
  // });

  return {...result, ...customs} as AutoCutStateSubscriberConfig;
};

const onStateUpdate: AutoCutStateSubscriberConfig = buildOnStateUpdate({
  'user.key': syncToLocalStorage,
  'user.isBetaOnboarded': syncToLocalStorage,
  'dev.debugCompute': syncToLocalStorage,
  'ui.resize.customPresets': syncToLocalStorage,
  'ui.host': syncToLocalStorage,
  //Modes parameters
  'ui.parameters.broll': syncToLocalStorage,
  'ui.parameters.caption': syncToLocalStorage,
  'ui.parameters.podcast': syncToLocalStorage,
  'ui.parameters.resize': syncToLocalStorage,
  'ui.parameters.silence': syncToLocalStorage,
  'ui.parameters.swear_word': syncToLocalStorage,
  'ui.parameters.zoom': syncToLocalStorage,
  'ui.parameters.chapters': syncToLocalStorage,
  'ui.parameters.viral_clips': syncToLocalStorage,
});

export type AutoCutState = {
  dev: {
    debugCompute: boolean;
    // TODO : traduire spécificité ppro
    // cepCallHistory: {
    //   functionName: string;
    //   args: any[];
    //   endsWithError: boolean;
    // }[];
  };
  // TODO : traduire spécificité ppro
  // ppro: {
  //   version: string;
  //   isBeta: boolean;
  //   renderingEngine: string;
  //   isInCEPEnv: boolean;
  //   isScriptLoaded: boolean;
  //   handshakeSecret: string;
  //   errors: {
  //     errorDuringScriptLoading: boolean;
  //   };
  // };
  backup: {
    lastId: string;
    isRestorable: boolean;
  };
  ui: {
    host: 'ppro' | 'davinci';
    versions: {
      aea: string;
      front: string;
      compute: string;
      hostClient: string;
      hostServer: string;
    };
    parameters: {
      [key in AutocutModeIds]: zod.infer<
        (typeof parametersValidationSchema)[key]
      >;
    };
    cutButtonMessage?: string;
    currentTranscription: {
      modeId?: NeedingTranscriptionModes;
      usedModel: number;
    };
    podcast: {
      hasEnoughClips: boolean;
    };
    resize: {
      customPresets: Omit<ResizePreset, 'icons'>[];
    };
    broll: {
      usersToCredit: {
        id: number;
        name: string;
        url: string;
      }[];
    };
    selection: {
      isLoading: boolean;
    };
    process: {
      mode: AutocutModeState;
      isProcessing: boolean;
      isPaused: boolean;
      durationProcessMinutes: number;
      durationProcessSeconds: number;
      numberOfCutsDone: number;
    };
    openedModalName: string;
    modalQueue: string[];
    startUpModals: StartUpModal[];
    isTourOpened: boolean;
    currentErrorId: string;
    currentSteps?: StepperRouterStep<any>[];
  };
  user: {
    isLogged: boolean;
    key?: string;
    license?: Licence;
    accessToken?: string;
    isBetaOnboarded: boolean;
    additionalInformations?: {
      displayOnboarding?: boolean;
      latestInvoice?: string;
      scoreRef?: string;
    };
    clientInfos: {
      uuid: string;
      os: typeof OS_WINDOWS | typeof OS_MAC;
      hostname: string;
    };
  };
  game: {
    level: {
      currentProgress: Progress;
      xpGained: number;
      levelupAnimation?: {to: Progress; from: Progress};
      levelupAnimationQueued?: {to: Progress; from: Progress};
    };
    trial: {
      tasks: TrialTask[] | readonly TrialTask[];
      displayModal: boolean;
    };
  };
  // TODO : traduire spécificité ppro
  // sequence: {
  //   parsingProcess: {
  //     progress: ProgressState;
  //     isLoading: boolean;
  //   };
  //   lastSettings: Sequence['settings'];
  //   infos?: Sequence;
  //   exportedAudioInfos?: ExportResult<ExportSequenceValue>;
  //   errors: {
  //     unsupportedScenario: UnsupportedScenarioIds | '';
  //     unsupportedFileName: string;
  //   };
  // };
  utils: {
    openedTimestamp: number;
    intervals: NodeJS.Timer[];
  };
  fonts: Font[];
  onGoingProcess: {
    //Global
    nbStepTotal?: number;
    //Cuts
    startDate?: Date;
    // TODO : traduire spécificité ppro
    // clipsToCut?: Clip[];
    timelineBasedSilencesTotal?: number[][];
    sequenceParams: {
      userSequenceVideoDisplayFormat?: number;
    };
    workerTimeoutReport?: Worker;
    //Captions
    captionChunks?: CaptionChunk[];
    //B-Roll
    bRolls?: BRoll[];
    //Chapters
    chapters?: Chapter[];
    //Repeat
    repeatUtterances?: Utterance[][];
    //Viral clips
    viralClips?: ViralClip[];
    //Repeat
    delimitedTranscript?: Utterance[][];
    CEPProgressCallback?: (progress: ProgressState) => void;
    progress?: ProcessProgress;
    preview: {
      isProcessing: boolean;
    };
    timelineInfos?: JSONTimeline;
  };
  operationsCache: Record<string, {key: string; result: any}>;
};

const getDefaultStorage = () =>
  ({
    dev: {
      debugCompute: false,
    },
    ppro: {
      version: '',
      isBeta: false,
      renderingEngine: '',
      isInCEPEnv: false,
      isScriptLoaded: false,
      handshakeSecret: Date.now().toString(),
      errors: {
        errorDuringScriptLoading: false,
      },
    },
    backup: {
      lastId: '',
      isRestorable: false,
    },
    ui: {
      host: '' as 'ppro' | 'davinci',
      versions: {
        aea: '000',
        front: import.meta.env.VITE_AUTOCUT_FRONT_VERSION,
        compute: '000',
        hostClient: '000',
        hostServer: '000',
      },
      parameters: Object.keys(AutocutModes).reduce((acc, mode) => {
        return {
          ...acc,
          [AutocutModes[mode as AutocutModesKeys].id]:
            AutocutModes[mode as AutocutModesKeys].defaultParameters,
        };
      }, {}) as AutoCutState['ui']['parameters'],
      cutButtonMessage: '',
      currentTranscription: {
        usedModel: 0,
      },
      podcast: {
        hasEnoughClips: false,
      },
      openedModalName: '',
      modalQueue: [],
      currentErrorId: '',
      resize: {
        customPresets: [],
      },
      broll: {
        usersToCredit: [],
      },
      startUpModals: [],
      isTourOpened: false,
      process: {
        mode: AutocutModes.Legacy,
        isProcessing: false,
        isPaused: false,
        durationProcessMinutes: 0,
        durationProcessSeconds: 0,
        numberOfCutsDone: 0,
      },
      selection: {
        isLoading: false,
      },
    },
    user: {
      isLogged: false,
      key: getKey(false),
      license: undefined,
      accessToken: undefined,
      isBetaOnboarded: false,
      clientInfos: {
        hostname: '',
        os: getOS(),
        uuid: DEFAULT_UUID,
      },
    },
    game: {
      level: {
        currentProgress: {
          xp: 0,
          level: 0,
        },
        xpGained: 0,
        levelupAnimation: undefined,
        levelupAnimationQueued: undefined,
      },
      trial: {tasks: defaultTrialTasks, displayModal: false},
    },
    sequence: {
      parsingProcess: {
        progress: {
          percentage: 0,
          current: 0,
          max: 0,
        },
        isLoading: false,
      },
      lastSettings: {
        width: 1920,
        height: 1080,
        frameRate: 25,
        timebase: 10160640000,
        audioChannelCount: 2,
        audioTrackType: 1,
        videoDisplayFormat: 101,
        name: '',
        projectItem: {
          interpretedTimebase: '10160640000',
          sourceTimebase: '10160640000',
          timeDisplayFormat: 101,
          treePath: '',
          name: 'global',
          nodeId: '',
          type: 1,
        },
      },
      infos: undefined,
      errors: {
        unsupportedScenario: '',
        unsupportedFileName: '',
      },
    },
    utils: {
      openedTimestamp: Date.now(),
      intervals: [],
    },
    fonts: [],
    onGoingProcess: {
      sequenceParams: {},
      preview: {
        isProcessing: false,
      },
    },
    operationsCache: {},
  }) as AutoCutState;

export const autocutStore = createStore('autoCutStore')(getDefaultStorage());

export const autocutStoreHooked = autocutStore.useStore;
export const autocutStoreVanilla = (): AutoCutState =>
  autocutStore.store.getState();

export const setAutocutStore = <K extends AutoCutStateKeys>(
  key: K,
  valueOrFunction:
    | AutoCutStateValueType<K>
    | ((state: AutoCutState) => AutoCutStateValueType<K>),
) => {
  const getValue = isFunction(valueOrFunction)
    ? valueOrFunction
    : () => valueOrFunction;

  // The typing allow to end the key with .undefined, next line is a safeguard
  key = key.replace(/\.undefined$/, '') as K;

  const keys = key.split('.') as string[];
  const value = getValue(autocutStoreVanilla());

  const actualState = autocutStoreVanilla();

  autocutStore.set[keys[0] as keyof AutoCutState](
    createObject(actualState, keys, value)[keys[0]],
  );

  const keyToSync = Object.keys(onStateUpdate).find(parentKey =>
    key.startsWith(parentKey),
  ) as AutoCutStateKeys;

  const onUpdate = onStateUpdate[keyToSync];
  const callStack = onUpdate
    ? Array.isArray(onUpdate)
      ? onUpdate
      : [onUpdate]
    : [];
  callStack.forEach(call => call?.(keyToSync));
};

const createObject = (
  current: any,
  keys: string[],
  value: any,
  depth = 0,
): any => {
  if (keys.length === 1) {
    return {...((depth > 0 ? current : undefined) || {}), [keys[0]]: value};
  }

  return {
    ...((depth > 0 ? current : undefined) || {}),
    [keys[0]]: createObject(current[keys[0]], keys.slice(1), value, depth + 1),
  };
};

export function deepFind(obj: any, path: string) {
  const paths = path.split('.');
  let current = obj;

  for (let i = 0; i < paths.length; ++i) {
    if (current[paths[i]] == undefined) {
      return undefined;
    } else {
      current = current[paths[i]];
    }
  }
  return current;
}

const deepSet = (obj: any, paths: string[], value: any): any => {
  const currentKey = paths.shift();

  if (currentKey === undefined) return obj;

  if (paths.length === 0) {
    obj[currentKey] = value;
    return obj;
  }

  if (!(currentKey in obj)) throw new Error('Invalid path');

  return deepSet(obj[currentKey], paths, value);
};

export const deepGet = (obj: any, paths: string[]): any => {
  const currentKey = paths.shift();

  if (currentKey === undefined) return obj;

  if (!(currentKey in obj)) throw new Error('Invalid path');

  return deepGet(obj[currentKey], paths);
};

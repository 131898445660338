import {PProLocale} from '@autocut/contexts/LocaleContext';

export const localGraphicParametersName: Record<PProLocale, string> = {
  de_DE: 'Grafikparameter',
  en_US: 'Graphic Parameters',
  es_ES: 'Parámetros gráficos',
  fr_FR: 'Paramètres graphiques',
  it_IT: 'Parametri grafici',
  ja_JP: 'グラフィックパラメーター',
  ko_KR: '그래픽 매개 변수',
  pt_BR: 'Parâmetros de gráfico',
  ru_RU: 'Параметры графики',
  zh_CN: '图形参数',
} as const;

export const localMotionEffectName: Record<PProLocale, string> = {
  de_DE: 'Bewegung',
  en_US: 'Motion',
  es_ES: 'Movimiento',
  fr_FR: 'Trajectoire',
  it_IT: 'Movimento',
  ja_JP: 'モーション',
  ko_KR: '모션',
  pt_BR: 'Movimento',
  ru_RU: 'Движение',
  zh_CN: '运动',
} as const;

export const localScaleEffectName: Record<PProLocale, string> = {
  de_DE: 'Skalierung',
  en_US: 'Scale',
  es_ES: 'Escala',
  fr_FR: 'Echelle',
  it_IT: 'Scala',
  ja_JP: 'スケール',
  ko_KR: '비율 조정',
  pt_BR: 'Dimensionar',
  ru_RU: 'Масштаб',
  zh_CN: '缩放',
} as const;

export const localBorderEffectName: Record<PProLocale, string> = {
  de_DE: 'Rahmen',
  en_US: 'Border',
  es_ES: 'Borde',
  fr_FR: 'Bordure',
  it_IT: 'Bordo',
  ja_JP: '縁',
  ko_KR: '테두리',
  pt_BR: 'Borda',
  ru_RU: 'Граница',
  zh_CN: '边框',
} as const;

export const localUniformScaleEffectName: Record<PProLocale, string> = {
  de_DE: 'Skalierung',
  en_US: 'Scale',
  es_ES: 'Escala',
  fr_FR: 'Echelle uniforme',
  it_IT: 'Scala',
  ja_JP: 'スケール',
  ko_KR: '비율 조정',
  pt_BR: 'Dimensionar',
  ru_RU: 'Масштаб',
  zh_CN: '缩放',
} as const;

export const localScaleWidthEffectName: Record<PProLocale, string> = {
  de_DE: 'Skalierungshöhe',
  en_US: 'Scale Width',
  es_ES: 'Anchura de escala',
  fr_FR: "Largeur d'échelle",
  it_IT: 'Larghezza scala',
  ja_JP: 'スケール (幅)',
  ko_KR: '폭 비율 조정',
  pt_BR: 'Dimensionar largura',
  ru_RU: 'Масштабировать ширину',
  zh_CN: '缩放宽度',
} as const;

export const localScaleHeightEffectName: Record<PProLocale, string> = {
  de_DE: 'Skalierungsbreite',
  en_US: 'Scale Height',
  es_ES: 'Altura de escala',
  fr_FR: "Hauteur d'échelle",
  it_IT: 'Altezza scala',
  ja_JP: 'スケール (高さ)',
  ko_KR: '높이 비율 조정',
  pt_BR: 'Dimensionar altura',
  ru_RU: 'Масштабировать высоту',
  zh_CN: '缩放高度',
} as const;

export const localPositionEffectName: Record<PProLocale, string> = {
  de_DE: 'Position',
  en_US: 'Position',
  es_ES: 'Posición',
  fr_FR: 'Position',
  it_IT: 'Posizione',
  ja_JP: '位置',
  ko_KR: '위치',
  pt_BR: 'Posição',
  ru_RU: 'Положение',
  zh_CN: '位置',
} as const;

export const localOpacityEffectName: Record<PProLocale, string> = {
  de_DE: 'Deckkraft',
  en_US: 'Opacity',
  es_ES: 'Opacidad',
  fr_FR: 'Opacité',
  it_IT: 'Opacità',
  ja_JP: '不透明度',
  ko_KR: '불투명도',
  pt_BR: 'Opacidade',
  ru_RU: 'Непрозрачность',
  zh_CN: '不透明度',
} as const;

export const localTextEffectName: Record<PProLocale, string> = {
  de_DE: 'Text',
  en_US: 'Text',
  es_ES: 'Texto',
  fr_FR: 'Texte',
  it_IT: 'Testo',
  ja_JP: 'テキスト',
  ko_KR: '텍스트',
  pt_BR: 'Texto',
  ru_RU: 'Текст',
  zh_CN: '文本',
} as const;

export const localRoughenEdgesEffectName: Record<PProLocale, string> = {
  de_DE: 'Kanten aufrauen',
  en_US: 'Roughen Edges',
  es_ES: 'Bordes rugosos',
  fr_FR: 'Contours bruts',
  it_IT: 'Bordi ruvidi',
  ja_JP: 'ラフエッジ',
  ko_KR: '가장자리 거칠게 하기',
  pt_BR: 'Tornar bordas ásperas',
  ru_RU: 'Огрубление краев',
  zh_CN: '粗糙边缘',
} as const;

export const localAncrageEffectName: Record<PProLocale, string> = {
  fr_FR: "Point d'ancrage",
  de_DE: 'Ankerpunkt',
  en_US: 'Anchor Point',
  es_ES: 'Punto de anclaje',
  it_IT: 'Punto di ancoraggio',
  ja_JP: 'アンカーポイント',
  ko_KR: '기준점',
  pt_BR: 'Ponto de ancoragem',
  ru_RU: 'Опорная точка',
  zh_CN: '锚点',
};

export const localTransformEffectName: Record<PProLocale, string> = {
  de_DE: 'Transformieren',
  en_US: 'Transform',
  es_ES: 'Transformar',
  fr_FR: 'Transformation',
  it_IT: 'Trasformazione',
  ja_JP: 'トランスフォーム',
  ko_KR: '변형',
  pt_BR: 'Transformar',
  ru_RU: 'Преобразовать',
  zh_CN: '变换',
} as const;

export const localBlurEffectName: Record<PProLocale, string> = {
  de_DE: 'Gaußscher Weichzeichner',
  en_US: 'Gaussian Blur',
  es_ES: 'Desenfoque gaussiano',
  fr_FR: 'Flou gaussien',
  it_IT: 'Sfocatura gaussiana',
  ja_JP: 'ブラー (ガウス)',
  ko_KR: '가우시안 흐림',
  pt_BR: 'Desfoque gaussiano',
  ru_RU: 'Размытие по Гауссу',
  zh_CN: '高斯模糊',
} as const;

export const localBlurrinessName: Record<PProLocale, string> = {
  de_DE: 'Weichzeichnung',
  en_US: 'Blurriness',
  es_ES: 'Desenfoque',
  fr_FR: 'Flou',
  it_IT: 'Sfocatura',
  ja_JP: 'ブラー',
  ko_KR: '흐림',
  pt_BR: 'Nível de desfoque',
  ru_RU: 'Размытие',
  zh_CN: '模糊度',
} as const;

export const isScaleDisplayName = (displayName: string) => {
  for (const key in localScaleEffectName) {
    if (localScaleEffectName[key as PProLocale] === displayName) {
      return true;
    }
  }
  return false;
};

import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {FileWithPath} from '@autocut/designSystem/components/molecules/FileInput/FileInput';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {compute} from '@autocut/utils/compute.utils';
import {handleProcessBase} from '@autocut/utils/process/handleProcessBase';
import {updateProcessStep} from '@autocut/utils/process/progress';
import {forwardRef} from 'react';
import {useIntl} from 'react-intl';

type Props = {
  isDisabled: boolean;
  isLoading: boolean;
};

export const ResizeFooterButton = forwardRef<HTMLButtonElement, Props>(
  ({isDisabled, isLoading}, ref) => {
    const intl = useIntl();
    const resizeParameters = useAutoCutStore(
      state => state.ui.parameters.resize,
    );

    const handleClick = handleProcessBase(
      {
        executeProcess: async (_, progress) => {
          const watermarkPath = resizeParameters.watermark
            ? (resizeParameters.watermark as FileWithPath).path
            : undefined;

          const resizeProcess = compute.process.resize({
            width: resizeParameters.preset.width,
            height: resizeParameters.preset.height,
            reframeSpeed: resizeParameters.autoReframePreset,
            watermarkPath,
          });

          updateProcessStep(progress, 'resize_mainProcess', {
            progress: {
              computeTaskId: resizeProcess.requestId,
            },
            countFor: 5, // Number of steps in compute
          });

          const result = await resizeProcess;

          return result;
        },
      },
      {
        processTitleNameKey: 'modes_podcast_title',
        processSteps: [
          {
            id: 'resize_mainProcess',
            translationKey: 'progress_steps_resize_mainProcess',
            progress: '',
          },
        ],
      },
    );

    return (
      <Button
        ref={ref}
        color={colors.primary600}
        disabled={isDisabled || isLoading}
        isLoading={isLoading}
        onClick={() => handleClick(intl)}
      >
        <TranslatedMessage
          id="modes_resize_steps_cta"
          defaultMessage="Resize sequence"
        />
      </Button>
    );
  },
);

import type {ResizedXMLTemplates} from './_resizeTemplate';

export const handleCleanClips = ({
  captionsClip,
  textBackgroundClip,
  effectsClip,
  wordBackgroundClip,
}: ResizedXMLTemplates) => {
  captionsClip.remove();
  textBackgroundClip.remove();
  effectsClip.remove();
  wordBackgroundClip.remove();
};

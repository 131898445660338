import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {Divider} from '@autocut/designSystem/components/atoms/Divider/Divider';
import {InOutStep} from './steps/InOutStep';
import {ResizePresetStep} from './steps/ResizePresetStep/ResizePresetStep';
import {ReframeStep} from './steps/ReframeStep';
import {WatermarkStep} from './steps/WatermarkStep';
import {ResizeFooterButton} from './steps/ResizeFooterButton';
import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';

export const ResizeCustomizationStep = () => {
  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({buttonRef, isLoading, isDisabled}) => (
            <ResizeFooterButton
              ref={buttonRef}
              isLoading={isLoading}
              isDisabled={isDisabled}
            />
          )}
        />
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s4}
      >
        <InOutStep />
        <Divider />
        <ResizePresetStep />
        <Divider />
        <ReframeStep />
        <Divider />
        <WatermarkStep />
      </FlexContainer>
    </ModeLayout>
  );
};

import {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import {TimelinePpro} from '../temp/_pproTimeline.temp';

export const getPosition = ({
  parameters,
  timeline,
}: {
  parameters: CaptionsParameters;
  timeline: TimelinePpro;
}) => {
  const xPosition = parameters.position.xPercentage * timeline.settings.width;
  const yPosition =
    parameters.position.yPercentage * timeline.settings.height -
    (parameters.text.maxLineDescentHeight ?? 0) +
    (parameters.text.maxLineHeight ?? 0);

  return {
    x: xPosition,
    y: yPosition,
  };
};

import {useEffect} from 'react';

import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {colors} from '@autocut/designSystem/colors';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {TranscriptLanguageStep} from '@autocut/components/TranscriptLanguageStep/TranscriptLanguageStep';
import {useHandleProcessTranscript} from '@autocut/hooks/useHandleProcessTranscript/useHandleProcessTranscript';
import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {snakeToCamel} from '@autocut/utils/string.utils';

type TranscriptLanguageProps = {
  mode: 'caption' | 'viral_clips' | 'broll';
};

export const TranscriptLanguage = ({mode}: TranscriptLanguageProps) => {
  const {handleProcessTranscript, loading} = useHandleProcessTranscript(mode);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({isLoading, isDisabled}) => (
            <Button
              onClick={() => handleProcessTranscript()}
              disabled={loading || isLoading || isDisabled}
              color={colors.primary600}
            >
              <TranslatedMessage
                id={
                  `modes_${snakeToCamel(mode)}_steps_language_button` as TranslationKeys
                }
                defaultMessage="Go to next step"
              />
            </Button>
          )}
        />
      }
    >
      <TranscriptLanguageStep modeId={mode} />
    </ModeLayout>
  );
};

import {BRoll} from '@autocut/types/BRolls';
import {CaptionChunk} from '@autocut/types/Captions';
import {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import {Utterance} from '@autocut/types/Deepgram';
import {preload, ProgressStep} from '@autocut/types/ElectronPreload';
import {JSONTimeline} from '@autocut/types/JSONTimeline';
import {
  GoogleTranscriptionLocation,
  TranscriptionProvider,
  TranscriptionType,
} from '@autocut/types/Transcription';
import {CURRENT_ENV, Env} from '@autocut/utils/currentEnv.utils';

import {IncrementalError} from './errors/IncrementalError';
import {onlyFor} from './host';
import {accessTokensModule} from './http.utils';
import {
  CaptionsDavinciData,
  CaptionsPremiereData,
} from './process/captions/prepareCaptions/prepareCaptions';
import {NeededCaptionsParameters} from './prproj/temp/_handleProcessCaptions.temp';
import {AutoCutState, autocutStoreVanilla} from './zustand';

export type ComputeRequestId = ReturnType<
  ReturnType<typeof preload>['computeNodeIpc']['startTask']
>['requestId'];

export type ComputeHandler<OutputType> = Promise<OutputType> & {
  requestId: ComputeRequestId;
};

const computeHandler = <OutputType>(
  task: Task<any>,
): ComputeHandler<OutputType> => {
  const runRequest = () => {
    console.log(
      ...[
        `[Compute] Requesting "${task.domain.target}/${task.domain.slug}" ${task.data ? 'with data: ' : ''}`,
        ...(task.data ? [task.data] : []),
      ],
    );

    const isDebug =
      CURRENT_ENV === Env.Production
        ? false
        : (task.debug ?? autocutStoreVanilla().dev.debugCompute);

    return preload().computeNodeIpc.startTask<OutputType>({
      priority: 0,
      tokens: {
        accessToken: accessTokensModule.accessToken,
        refreshToken: accessTokensModule.refreshToken,
      },
      ...task,
      debug: isDebug,
    });
  };

  const request = runRequest();
  const res = request.promise.catch(err => {
    console.log('ERROR IN COMPUTE HANDLER', err);
    throw new IncrementalError(
      err.content,
      `${err.from}(${err.content.fingerprint})`,
    );
  }) as ComputeHandler<OutputType>;
  res.requestId = request.requestId;

  return res;
};

export const compute = {
  dev: (data: any | undefined) =>
    computeHandler<any>({
      domain: {slug: 'dev', target: 'common'},
      data,
    }),
  utils: {
    getDbValuesBetween: (parameters: {
      filePath: string;
      start: number;
      end: number;
      precision?: number;
    }) =>
      computeHandler<number[]>({
        domain: {slug: 'get-db-values-between', target: 'common'},
        data: parameters,
      }),

    getSilenceIntervals: (
      parameters: {
        filePath: string;
      } & AutoCutState['ui']['parameters']['silence'],
    ) =>
      computeHandler<number[][]>({
        domain: {slug: 'get-silence-intervals', target: 'common'},
        data: parameters,
      }),
    bigData: () =>
      computeHandler<string>({
        domain: {slug: 'big-data', target: 'common'},
      }),
  },
  timeline: {
    render: (parameters: {
      descriptor: RenderDescriptor;
      convertToMono?: boolean;
    }) =>
      computeHandler<any>({
        domain: {
          slug: 'render',
          target: 'common',
        },
        data: parameters,
      }),
    getInfos: () =>
      computeHandler<JSONTimeline>({
        domain: {
          slug: 'get-timeline-infos',
          target: autocutStoreVanilla().ui.host,
        },
      }),
    getDisposition: () =>
      computeHandler<{maxAudioTrackId: number; maxVideoTrackId: number}>({
        domain: {
          slug: 'get-timeline-disposition',
          target: 'common',
        },
      }),
    getPreProcessStats: () =>
      computeHandler<{
        width: number;
        height: number;
        duration: number;
        trackItemsCount: number;
      }>({
        domain: {
          slug: 'get-pre-process-stats',
          target: 'common',
        },
        debug: false,
      }),
  },
  transcription: ({
    type,
    language,
    skipEmbedding = true,
  }: {
    type: TranscriptionType;
    language: {
      provider: TranscriptionProvider;
      model: string;
      value: string;
      location?: GoogleTranscriptionLocation;
    };
    skipEmbedding?: boolean;
  }) =>
    computeHandler<Utterance[]>({
      domain: {
        slug: 'get-transcript',
        target: 'common',
      },
      data: {
        type,
        language,
        skipEmbedding,
      },
    }),
  process: {
    repeat: {
      cutSequence: (parameters: {
        transcript: Utterance[][];
        silencesManagement: AutoCutState['ui']['parameters']['repeat']['silences'];
      }) =>
        computeHandler<void>({
          domain: {slug: 'repeat', target: autocutStoreVanilla().ui.host},
          data: parameters,
        }),
      getRepeatUtterances: (parameters: {
        language: {
          provider: TranscriptionProvider;
          model: string;
          value: string;
          location?: GoogleTranscriptionLocation;
        };
        type: 'utterances' | 'delimited-repetitions';
      }) =>
        computeHandler<Utterance[][]>({
          domain: {
            slug: 'get-repeat-utterances',
            target: 'common',
          },
          data: parameters,
        }),
    },
    chapters: (chaptersParameters: {
      chapters: {timecodes: string[]; title: string}[];
      color: string;
    }) =>
      computeHandler<boolean>({
        domain: {
          slug: 'chapters',
          target: 'common',
        },
        data: chaptersParameters,
      }),
    getChapters: (parameters: {
      language: {
        provider: TranscriptionProvider;
        model: string;
        value: string;
        location?: GoogleTranscriptionLocation;
      };
      skipEmbedding?: boolean;
    }) =>
      computeHandler<any>({
        domain: {
          slug: 'get-chapters',
          target: 'common',
        },
        data: parameters,
      }),
    silence: (silenceParameters: AutoCutState['ui']['parameters']['silence']) =>
      computeHandler<void>({
        domain: {
          slug: 'silences',
          target: autocutStoreVanilla().ui.host,
        },
        data: silenceParameters,
      }),
    podcast: (podcastParameters: AutoCutState['ui']['parameters']['podcast']) =>
      computeHandler<void>({
        domain: {
          slug: 'podcast',
          target: autocutStoreVanilla().ui.host,
        },
        data: podcastParameters,
      }),
    zoom: (
      zoomParameters: AutoCutState['ui']['parameters']['zoom'] & {
        adjustmentLayerPath: string | undefined;
      },
    ) =>
      computeHandler<void>({
        domain: {
          slug: 'zoom',
          target: autocutStoreVanilla().ui.host,
        },
        data: zoomParameters,
      }),
    captions: (
      data: {
        chunks: CaptionChunk[];
        parameters: CaptionsParameters;
      } & (CaptionsDavinciData | CaptionsPremiereData),
    ) =>
      onlyFor('davinci', () =>
        computeHandler<void>({
          domain: {
            slug: 'captions',
            target: autocutStoreVanilla().ui.host,
          },
          data,
        }),
      ),
    prepareCaptions: () =>
      onlyFor('ppro', () =>
        computeHandler<NeededCaptionsParameters>({
          domain: {
            slug: 'prepare-captions',
            target: autocutStoreVanilla().ui.host,
          },
        }),
      ),
    importCaptions: (data: {
      prprojFilePath: string;
      xmlName: string;
      trackIndex: number;
      secondTimes: {start: number; end: number};
    }) =>
      onlyFor('ppro', () =>
        computeHandler({
          domain: {
            slug: 'import-captions',
            target: autocutStoreVanilla().ui.host,
          },
          data,
        }),
      ),
    swearWords: (swearWordsParameters: {
      userOptions: {
        provider: TranscriptionProvider;
        model: string;
        value?: string;
        location?: GoogleTranscriptionLocation;
      };
      bleepFileName?: string;
      bleepFilePath?: string;
    }) =>
      computeHandler<void>({
        domain: {
          slug: 'swear-words',
          target: autocutStoreVanilla().ui.host,
        },
        data: swearWordsParameters,
      }),

    resize: (resizeParameters: {
      width: number;
      height: number;
      watermarkPath?: string;
      reframeSpeed: 'slower' | 'default' | 'faster' | 'none';
    }) =>
      computeHandler<void>({
        domain: {slug: 'resize', target: 'ppro'},
        data: resizeParameters,
      }),
    getViralClips: (parameters: {
      language: {
        provider: TranscriptionProvider;
        model: string;
        value: string;
        location?: GoogleTranscriptionLocation;
      };
      skipEmbedding?: boolean;
    }) =>
      computeHandler<ViralClip[]>({
        domain: {
          slug: 'get-viral-clips',
          target: 'common',
        },
        data: parameters,
      }),
    viral: (viralParameters: {viralClips: ViralClip[]}) =>
      computeHandler<void>({
        domain: {
          slug: 'viral',
          target: autocutStoreVanilla().ui.host,
        },
        data: viralParameters,
      }),
    bRolls: (bRollsParameters: {bRolls: BRoll[]}) =>
      computeHandler<void>({
        domain: {
          slug: 'brolls',
          target: autocutStoreVanilla().ui.host,
        },
        data: bRollsParameters,
      }),
    getBRolls: (parameters: {
      language: {
        provider: TranscriptionProvider;
        model: string;
        value: string;
        location?: GoogleTranscriptionLocation;
        maxWordsPerChunk?: number;
      };
      skipEmbedding?: boolean;
      brollsParameters: {
        minDuration: number;
        maxDuration: number;
      };
    }) =>
      computeHandler<BRoll[]>({
        domain: {
          slug: 'get-brolls',
          target: 'common',
        },
        data: parameters,
      }),
  },
};

export const getComputeTaskProgress = async (requestId: string) => {
  return preload().computeNodeIpc.getTaskProgress(requestId).promise;
};

export const subscribeTaskProgress = (
  taskId: ComputeRequestId,
  callback: (steps: ProgressStep[]) => void,
  pollingIntervalMs = 1000,
): {unsubscribe: () => void} => {
  const pollingInterval = setInterval(async () => {
    const steps = await getComputeTaskProgress(taskId);

    if (!Array.isArray(steps)) {
      console.warn('Steps are not an array', steps);
      return;
    }

    if (steps.every(step => step.progress.finished)) {
      clearInterval(pollingInterval);
    }

    callback(steps);
  }, pollingIntervalMs);

  return {unsubscribe: () => clearInterval(pollingInterval)};
};

import Modal from '@autocut/components/ModalLayout/BaseModal';
import {
  OldTranslatedMessage,
  OnlyOldTranslations,
} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {preload} from '@autocut/types/ElectronPreload';
import {FiAlertTriangle} from 'react-icons/fi';

export const UsageModal = () => {
  const license = useAutoCutStore(state => state.user.license);
  const isTrial = license?.type === 'trial';

  return (
    <Modal
      title={
        <p
          style={{
            color: 'white',
            fontSize: '20px',
            lineHeight: '40px',
            fontWeight: 'bold',
          }}
        >
          <OldTranslatedMessage
            id={`${license?.type}_usage_exceeded_title` as OnlyOldTranslations}
            defaultMessage="Free trial limitation reach"
          />
        </p>
      }
      icon={<FiAlertTriangle size="1.5rem" />}
      footer={
        isTrial ? (
          <Button
            onClick={async () => {
              await preload().electron.openLinkInBrowser(
                `${preload().electron.getSharedVariables().WEBSITE_URL}/app/payment?plan=ai&formula=month`,
              );
            }}
            variant="primary"
          >
            <OldTranslatedMessage
              id={`free_trial_usage_exceeded_cta`}
              defaultMessage="Subscribe"
            />
          </Button>
        ) : (
          <></>
        )
      }
    >
      <p
        style={{
          color: 'white',
          padding: '16px',
          fontSize: '13px',
          lineHeight: '23px',
          fontWeight: 'bold',
        }}
      >
        <OldTranslatedMessage
          id={`${license?.type}_usage_exceeded_text` as OnlyOldTranslations}
          defaultMessage="You have used more than 1000 minutes of AI feature. <br /> Subscribe to the AI plan to continue using AutoCut AI features."
        />
      </p>
    </Modal>
  );
};

import {Alert} from '@autocut/designSystem/components/atoms/Alert/Alert';
import {IconInfoCircle} from '@autocut/designSystem/components/atoms/Icon/general/IconInfoCircle';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {RadioForm} from '@autocut/designSystem/components/molecules/RadioForm/RadioForm';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {isHost} from '@autocut/utils/host';
import {AutoCutStateKeys, setAutocutStore} from '@autocut/utils/zustand';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';

export type TransitionStepProps = {
  modeId?: 'silence';
};

export const TransitionStep = ({modeId = 'silence'}: TransitionStepProps) => {
  const intl = useIntl();
  const {transistions: transitionParameter, silences: silenceParameter} =
    useAutoCutStore(state => state.ui.parameters[modeId]);

  const radioOptions = useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'modes_silence_steps_transition_params_default_choices_none',
          defaultMessage: 'None',
        }),
        name: 'none',
        value: 'none',
        defaultChecked: transitionParameter === 'none',
      },
      {
        title: intl.formatMessage({
          id: 'modes_silence_steps_transition_params_j_l_cut_choices_j_cut',
          defaultMessage: 'J-Cut',
        }),
        name: 'j_cut',
        value: 'j_cut',
        defaultChecked: transitionParameter === 'j_cut',
        disabled: silenceParameter !== 'delete',
      },
      {
        title: intl.formatMessage({
          id: 'modes_silence_steps_transition_params_j_l_cut_choices_l_cut',
          defaultMessage: 'L-Cut',
        }),
        name: 'l_cut',
        value: 'l_cut',
        defaultChecked: transitionParameter === 'l_cut',
        disabled: silenceParameter !== 'delete',
      },
      {
        title: intl.formatMessage({
          id: 'modes_silence_steps_transition_params_j_l_cut_choices_both',
          defaultMessage: 'J&L-Cut',
        }),
        name: 'both',
        value: 'both',
        defaultChecked: transitionParameter === 'both',
        disabled: silenceParameter !== 'delete',
      },
      ...(isHost('ppro')
        ? [
            {
              title: intl.formatMessage({
                id: 'modes_silence_steps_transition_params_premiere_pro_choices_constant_power',
                defaultMessage: 'Constant Power',
              }),
              name: 'constant_power',
              value: 'constant_power',
              defaultChecked: transitionParameter === 'constant_power',
              disabled: silenceParameter !== 'delete',
            },
          ]
        : []),
    ],
    [transitionParameter, silenceParameter, intl],
  );

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'modes_silence_steps_transition_params_title',
        defaultMessage: 'Transitions',
      })}
      description={intl.formatMessage({
        id: 'modes_silence_steps_transition_params_helper',
        defaultMessage:
          'All cuts can be J-Cut, L-Cut or both, if desired. This creates a more dynamic transition effect.',
      })}
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s3}
      >
        {silenceParameter !== 'delete' && (
          <Alert
            icon={
              <IconInfoCircle
                size={18}
                color="gray.300"
              />
            }
          >
            {intl.formatMessage({
              id: 'modes_silence_steps_transition_params_warning',
              defaultMessage:
                'You cannot use transitions without deleting silences.',
            })}
          </Alert>
        )}

        <RadioForm
          key={(silenceParameter !== 'delete').toString()}
          flexDirection="row"
          justifyContent="space-between"
          options={radioOptions}
          onChange={newValue =>
            setAutocutStore(
              `ui.parameters.${modeId}.transistions` as AutoCutStateKeys,
              newValue as typeof transitionParameter,
            )
          }
        />
      </FlexContainer>
    </FormSection>
  );
};

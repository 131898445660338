import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {SilencesFooterButton} from './Parts/SilencesFooterButton';
import {handleProcessBase} from '@autocut/utils/process/handleProcessBase';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {NoiseLevelStep} from './Parts/NoiseLevelStep/NoiseLevelStep';
import {Divider} from '@autocut/designSystem/components/atoms/Divider/Divider';
import {SilenceSizeStep} from './Parts/SilenceSizeStep/SilenceSizeStep';
import {PaddingStep} from './Parts/PaddingStep/PaddingStep';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {compute} from '@autocut/utils/compute.utils';
import {PreviewStep} from './Parts/PreviewStep/PreviewStep';
import {
  endProcessStep,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {SilenceStep} from '@autocut/pages/modes/legacy/Parts/SilenceStep/SilenceStep';
import {TransitionStep} from '@autocut/pages/modes/legacy/Parts/TransitionStep/TransitionStep';

export const SilenceCustomizationStep = () => {
  const {parameters} = useAutoCutStore(state => ({
    parameters: state.ui.parameters.silence,
  }));

  const handleClick = handleProcessBase(
    {
      executeProcess: async (_, progress) => {
        const podcastProcess = compute.process.silence(parameters);

        updateProcessStep(progress, 'silences_mainProcess', {
          progress: {
            computeTaskId: podcastProcess.requestId,
          },
          countFor: 12, // Number of steps in compute
        });

        const result = await podcastProcess;

        endProcessStep(progress, 'silences_mainProcess');

        return result;
      },
    },
    {
      processTitleNameKey: 'modes_silence_title',
      processSteps: [
        {
          id: 'silences_mainProcess',
          translationKey: 'progress_steps_silences_mainProcess',
          progress: '',
        },
      ],
    },
  );

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({buttonRef, isLoading, isDisabled}) => (
            <SilencesFooterButton
              ref={buttonRef}
              isLoading={isLoading}
              isDisabled={isDisabled}
              onClick={handleClick}
            />
          )}
        />
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s4}
      >
        <NoiseLevelStep modeId="silence" />
        <Divider />
        <SilenceSizeStep modeId="silence" />
        <Divider />
        <PaddingStep modeId="silence" />
        <Divider />
        <SilenceStep />
        <Divider />
        <TransitionStep modeId="silence" />
        <Divider />
        <PreviewStep />
      </FlexContainer>
    </ModeLayout>
  );
};

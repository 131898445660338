import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {Sorter} from '@autocut/components/Sorter/Sorter';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {ViralClipsFooterButton} from './Parts/ViralClipsFooterButton/ViralClipsFooterButton';
import {Item} from './Parts/ViralClipItem/ViralClipItem';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {usePreviewFrames} from '@autocut/hooks/usePreviewFrames';

export const ViralClipsSelector = () => {
  const intl = useIntl();

  const {viralClips} = useAutoCutStore(state => ({
    viralClips: state.onGoingProcess.viralClips,
  }));

  const {previewFrames} = usePreviewFrames(
    (viralClips ?? []).map(viralClip => viralClip.timecodes),
  );

  if (!viralClips) return null;

  const noClipSelected = viralClips.every(viralClip => !viralClip.selected);

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({buttonRef, isLoading, isDisabled}) => (
            <ViralClipsFooterButton
              ref={buttonRef}
              isLoading={isLoading}
              isDisabled={isDisabled || noClipSelected}
            />
          )}
        />
      }
    >
      <FormSection
        title={
          <TranslatedMessage
            id={'modes_viralClips_title'}
            defaultMessage="AutoViral"
          />
        }
        description={
          <TranslatedMessage
            id={'modes_viralClips_detectedClips'}
            defaultMessage={`Detected ${viralClips.length} potential viral clips`}
            values={{viralClipsLength: viralClips.length}}
          />
        }
      >
        <FlexContainer justifyContent="flex-end">
          <Sorter
            list={viralClips}
            criterias={[
              {
                label: intl.formatMessage({
                  id: 'modes_viralClips_sorter_viralScore',
                  defaultMessage: 'Viral score',
                }),
                sortFunction: (a: ViralClip, b: ViralClip) => b.score - a.score,
              },
              {
                label: intl.formatMessage({
                  id: 'modes_viralClips_sorter_start',
                  defaultMessage: 'Start',
                }),
                sortFunction: (a: ViralClip, b: ViralClip) => a.start - b.start,
              },
            ]}
            onUpdate={(sortedViralClips: ViralClip[]) =>
              setAutocutStore('onGoingProcess.viralClips', sortedViralClips)
            }
          />
        </FlexContainer>
        {viralClips.map((viralClip, index) => (
          <Item
            key={index}
            previewFrame={previewFrames[index]}
            viralClip={viralClip}
            onClick={() => {
              const clips = [...viralClips];
              clips[index].selected = !clips[index].selected;
              setAutocutStore('onGoingProcess.viralClips', clips);
            }}
          />
        ))}
      </FormSection>
    </ModeLayout>
  );
};

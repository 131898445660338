// safely handles circular references
export const safeJSONStringify = (obj: any, maxDepth = 50, indent = 2) => {
  let cache: any[] = [];

  const stringifyWithDepth = (value: any, currentDepth: number): any => {
    if (currentDepth > maxDepth) {
      return '[max depth reached]';
    }

    if (typeof value === 'object' && value !== null) {
      if (cache.includes(value)) {
        return '[Circular]'; // Duplicate reference found, discard key
      }
      cache.push(value);

      const result: any = Array.isArray(value) ? [] : {};
      for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          result[key] = stringifyWithDepth(value[key], currentDepth + 1);
        }
      }
      return result;
    }

    return value;
  };

  const retVal = JSON.stringify(stringifyWithDepth(obj, 0), null, indent);
  cache = [];
  return retVal;
};

export const logger: any = () => {
  console.warn('Do not use the logger anymore');
  return;
};

import {
  ResourceManagerKeys,
  resourcesConfigs,
} from '@autocut/enums/resources.enum';
import {LaunchStepHandler} from '@autocut/pages/LaunchManager/LaunchManager';
import {ResourceManagerElementConfig} from '@autocut/types/ResourceManager';

export const resourcesSetup: LaunchStepHandler = async setProgress => {
  const resources = resourcesConfigs();

  await setProgress?.(0);
  const initResources = Object.keys(resources).filter(
    key =>
      resources[key as ResourceManagerKeys].scope === 'global' &&
      resources[key as ResourceManagerKeys].requestOnInit,
  );
  return (
    await Promise.all(
      initResources.map(async (key, index) => {
        try {
          const config = resources[
            key as ResourceManagerKeys
          ] as ResourceManagerElementConfig;
          console.log('config', key);

          const {isExist} = await config.existCheck();
          if (isExist) return true;

          await config.downloadMethod();

          return true;
        } catch (e) {
          console.log('Error while installing resources ', key, e);
          return false;
        } finally {
          await setProgress?.(((index + 1) * 100) / initResources.length);
        }
      }),
    )
  ).reduce((acc, cur) => acc && cur, true);
};

import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {TimecodesStep} from './steps/TimecodesStep/TimecodesStep';
import {ColorStep} from './steps/ColorStep/ColorStep';
import {Divider} from '@autocut/designSystem/components/atoms/Divider/Divider';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ChaptersFooterButton} from './steps/ChaptersFooterButton/ChaptersFooterButton';
import {YoutubeButton} from './steps/youtube/YoutubeButton';

export const ChaptersEditor = () => {
  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({buttonRef, isLoading, isDisabled}) => (
            <ChaptersFooterButton
              ref={buttonRef}
              isLoading={isLoading}
              isDisabled={isDisabled}
            />
          )}
        />
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s4}
      >
        <TimecodesStep />
        <YoutubeButton />
        <Divider />
        <ColorStep />
      </FlexContainer>
    </ModeLayout>
  );
};

import {LaunchStepHandler} from '@autocut/pages/LaunchManager/LaunchManager';
import {preload, waitForPreload} from '@autocut/types/ElectronPreload';
import {initApi} from '@autocut/utils/http.utils';

export const preloadWait: LaunchStepHandler = async (
  setProgress,
): Promise<boolean> => {
  await waitForPreload('preloadWait setup');
  await setProgress?.(100);

  initApi();
  try {
    const logFilePath =
      preload()
        .electron.getSharedVariables?.()
        .LOG_FILE_PATH?.replace(/\.log$/, '.front.log') || '';
    preload().logger.init(logFilePath);
  } catch (error) {
    console.error(error);
  }
  return !!preload().os;
};

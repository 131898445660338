import {Card} from '@autocut/designSystem/components/atoms/Card/Card';

import css from './ViralClipItem.module.scss';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {LoadingRing} from '@autocut/components/LoadingRing/LoadingRing';
import {CheckBox} from '@autocut/designSystem/components/atoms/CheckBox/CheckBox';
import {ViralityScore} from '../ViralityScore/ViralityScore';

export type ItemProps = {
  viralClip: ViralClip;
  previewFrame?: string;
  onClick: () => void;
};

export const Item = ({viralClip, previewFrame, onClick}: ItemProps) => {
  return (
    <Card
      onClick={onClick}
      className={css.root}
    >
      <FlexContainer
        gap={Spacing.s2}
        alignItems="flex-start"
      >
        <FlexContainer
          flexDirection="column"
          gap={Spacing.s2}
        >
          {previewFrame ? (
            <img
              className={css.previewFrame}
              src={previewFrame}
            />
          ) : (
            <FlexContainer
              className={css.loaderContainer}
              alignItems="center"
              justifyContent="center"
            >
              <LoadingRing size={32} />
            </FlexContainer>
          )}
          <ViralityScore
            score={viralClip.score}
            reason={viralClip.reason}
          />
        </FlexContainer>

        <FlexContainer
          className={css.rightContainer}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <FlexContainer
            className={css.textContainer}
            flexDirection="column"
            justifyContent="space-between"
            gap={Spacing.s2}
          >
            <FlexContainer
              gap={Spacing.s1}
              alignItems="center"
            >
              <Card className={css.timecodeContainer}>
                <Text variant="textSm.semibold">{viralClip.timecodes[0]}</Text>
              </Card>
              <Text variant="textSm.semibold">-</Text>
              <Card className={css.timecodeContainer}>
                <Text variant="textSm.semibold">{viralClip.timecodes[1]}</Text>
              </Card>
            </FlexContainer>
            <FlexContainer
              flexDirection="column"
              gap={Spacing.s1}
            >
              <Text variant="textMd">{viralClip.title}</Text>
              <Text variant="textSm">{viralClip.text}</Text>
            </FlexContainer>
          </FlexContainer>
          <div
            style={{
              userSelect: 'none',
              pointerEvents: 'none',
            }}
          >
            <CheckBox
              size={18}
              checked={!!viralClip.selected}
            />
          </div>
        </FlexContainer>
      </FlexContainer>
    </Card>
  );
};

import Modal from '@autocut/components/ModalLayout/BaseModal';
import Stepper from '@autocut/components/Stepper/Stepper';
import {colors} from '@autocut/designSystem/colors';
import {Author} from '@autocut/designSystem/components/atoms/Author/Author';
import {getRandomAuthor} from '@autocut/designSystem/components/atoms/Author/utils';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useEffect, useMemo, useState} from 'react';
import {ONBOARDING_MODAL_STEPS} from './betaOnboardingModalSteps';

import './BetaOnboardingModal.css';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {setAutocutStore} from '@autocut/utils/zustand';

export const BetaOnboardingModal = () => {
  const [activeStepIndex, setActiveStepIndex] = useState(-1);
  const [buttonDisabledTimer, setButtonDisabledTimer] = useState<number | null>(
    3,
  );
  const currentStep = ONBOARDING_MODAL_STEPS[activeStepIndex + 1];
  const randomAuthor = useMemo(() => getRandomAuthor(), []);

  const onClose = () => {
    setAutocutStore('user.isBetaOnboarded', true);
    closeModal();
  };

  const nextStep = () => {
    if (activeStepIndex === ONBOARDING_MODAL_STEPS.length - 2) onClose();

    setActiveStepIndex(prev => {
      return prev + 1;
    });
    setButtonDisabledTimer(3);
  };

  useEffect(() => {
    if (buttonDisabledTimer === null) return;

    const interval = setInterval(() => {
      setButtonDisabledTimer(prev => {
        if (!prev) return null;

        if (prev === 1) {
          clearInterval(interval);
          return null;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [buttonDisabledTimer]);

  return (
    <Modal
      closeOnClickOutside={false}
      withCloseButton={false}
      title={currentStep.title}
      footer={
        <Button
          color={colors.primary600}
          onClick={nextStep}
          disabled={buttonDisabledTimer !== null}
        >
          {buttonDisabledTimer ?? currentStep.footerLabel}
        </Button>
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={12}
      >
        <Stepper
          activeIndex={activeStepIndex}
          steps={ONBOARDING_MODAL_STEPS}
          barHeight={4}
        />
        <FlexContainer
          flexDirection="column"
          gap={12}
        >
          <FormSection title={<Author author={randomAuthor} />}>
            <PaddedSection>
              <FlexContainer
                flexDirection="column"
                gap={8}
                style={{}}
              >
                {currentStep.body}
              </FlexContainer>
            </PaddedSection>
          </FormSection>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

import {useEffect, useState} from 'react';

import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';

import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useLocale} from '@autocut/hooks/useLocale';
import {getBadgeForLevel} from '@autocut/utils/game/game.utils';
import css from './gameHub.module.css';
import {resetAnimation} from '@autocut/utils/game/resetAnimation';

export const GameHub = () => {
  const {locale} = useLocale();
  const {currentProgress, levelupAnimation} = useAutoCutStore(state => ({
    currentProgress: state.game.level.currentProgress,
    levelupAnimation: state.game.level.levelupAnimation,
  }));

  const [xp, setXp] = useState(currentProgress.xp);

  const [oldLevel, setOldLevel] = useState(currentProgress?.level);
  const [newLevel, setNewLevel] = useState(currentProgress?.level);

  const [oldLevelBadge, setOldLevelBadge] = useState(
    getBadgeForLevel(currentProgress?.level),
  );
  const [newLevelBadge, setNewLevelBadge] = useState(
    getBadgeForLevel(currentProgress?.level),
  );

  const [isAnimated, setIsAnimated] = useState(false);
  const [levelChange, setLevelChange] = useState(false);
  const [isReverse, setIsReverse] = useState(false);

  const [hourSavedText, setHourSavedText] = useState<string>('');

  useEffect(() => {
    setOldLevel(currentProgress?.level);
    setNewLevel(currentProgress?.level);
    setOldLevelBadge(getBadgeForLevel(currentProgress?.level));
    setNewLevelBadge(getBadgeForLevel(currentProgress?.level));
  }, [currentProgress]);

  useEffect(() => {
    void animate();
  }, [levelupAnimation]);

  const animate = async () => {
    if (!levelupAnimation) return;

    const hourSaved = levelupAnimation.to.level - levelupAnimation.from.level;

    if (hourSaved > 0) {
      if (hourSaved === 1 && locale === 'EN') {
        setHourSavedText('another');
      } else {
        if (locale === 'EN') {
          setHourSavedText(`${hourSaved} more`);
        } else {
          setHourSavedText(`${hourSaved}`);
        }
      }
    }

    setIsReverse(true);
    setXp(levelupAnimation.from.xp);

    //Starting levelupAnimation
    setIsAnimated(true);
    setIsReverse(false);

    // Filling XP bar to 100% each time we level up
    for (
      let loop = levelupAnimation.from.level;
      loop < levelupAnimation.to.level;
      loop++
    ) {
      await new Promise(resolve => {
        setXp(100);

        // Resetting XP bar
        setTimeout(() => {
          setIsReverse(true);
          setXp(0);

          // Level up animation
          if (
            loop === levelupAnimation.to.level - 1 &&
            levelupAnimation.to.level > levelupAnimation.from.level
          ) {
            setOldLevel(levelupAnimation.from.level);
            setNewLevel(levelupAnimation.to.level);

            setOldLevelBadge(getBadgeForLevel(levelupAnimation.from.level));
            setNewLevelBadge(getBadgeForLevel(levelupAnimation.to.level));

            setLevelChange(true);

            // Removing level up animation
            setTimeout(() => {
              setLevelChange(false);
            }, 5000);
          }

          // Removing reverse animation
          setTimeout(() => {
            setIsReverse(false);
            resolve(undefined);
          }, 1000);
        }, 4250);
      });
    }

    // Adding remaining XP
    setXp(levelupAnimation.to.xp);
    await new Promise(resolve => {
      // Removing animation
      setTimeout(() => {
        setIsAnimated(false);
        resolve(undefined);
      }, 4250);
    });

    resetAnimation();
  };

  return (
    <div
      className={`${css.container} ${isAnimated ? css.isAnimated : ''} ${
        levelChange ? css.levelChange : ''
      } ${isReverse ? css.reverse : ''}`}
    >
      <div
        className={css.xpBar}
        style={{width: `${xp}%`}}
      >
        <span>
          <span></span>
        </span>
      </div>
      <div className={css.levelIcon}>
        <div className={css.badge}>
          <div className={css.imgContainer}>
            <img
              className={css.old}
              src={oldLevelBadge}
              alt=""
              height={'40px'}
            />
            <img
              className={css.new}
              src={newLevelBadge}
              alt=""
              height={'40px'}
            />
          </div>

          <div className={`${css.levelText} ${css.old}`}>{oldLevel}</div>
          <div className={`${css.levelText} ${css.new}`}>{newLevel}</div>
        </div>
        <div className={css.text}>
          <p>
            <TranslatedMessage
              id="gamification_savedHours"
              defaultMessage="You just saved {hourSavedText} hour(s),"
              values={{
                hourSavedText,
              }}
            />
          </p>
          <p>
            <TranslatedMessage
              id="gamification_haveABreak"
              defaultMessage="have a break you deserve it!"
            />
          </p>
        </div>
        <div className={css.firework}></div>
      </div>
    </div>
  );
};

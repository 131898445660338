import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useState} from 'react';
import {LiaSortAmountDownSolid} from 'react-icons/lia';
import {Select} from '@autocut/designSystem/components/atoms/Select/Select';

type SorterProps<T> = {
  list: T[];
  criterias: {
    label: string;
    sortFunction: (a: any, b: any) => number;
  }[];
  onUpdate?: (sortedList: T[]) => void;
};

export const Sorter = ({list, criterias, onUpdate}: SorterProps<any>) => {
  const [selectedValue, setSelectedValue] = useState({
    label: criterias[0].label,
    value: '0',
  });

  return (
    <FlexContainer
      flexDirection="row"
      gap={8}
      alignItems="center"
    >
      <LiaSortAmountDownSolid size={36} />
      <Select
        options={criterias.map((criteria, index) => ({
          label: criteria.label,
          value: `${index}`,
        }))}
        onChange={option => {
          if (!option) return null;

          const index = parseInt(option);
          const criteria = criterias[index];
          const sortedList = [...list].sort(criteria.sortFunction)
          setSelectedValue({label: criteria.label, value: option});

            onUpdate?.(sortedList);
        }}
        selected={selectedValue.value}
      />
    </FlexContainer>
  );
};

import ConfettiBackground from '@autocut/components/ConfettiBackground/ConfettiBackground';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import logLevel from '@autocut/enums/logLevel.enum';
import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useLogger} from '@autocut/hooks/useLogger';
import {preload} from '@autocut/types/ElectronPreload';
import {clipboard} from '@autocut/utils/clipboard.util';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {setAutocutStore} from '@autocut/utils/zustand';
import {Suspense, useRef, useState} from 'react';
import {IoCheckmarkSharp, IoCopyOutline} from 'react-icons/io5';
import {useIntl} from 'react-intl';

import endModalProcessCss from '../EndProcessModal/EndProcessModal.module.css';
import css from './BRollCreditsModal.module.css';

export const BRollCreditsModal = () => {
  const intl = useIntl();
  const hideTimeout = useRef<any>(null);
  const [copied, setCopied] = useState(false);

  const usersToCredit = useAutoCutStore(state => state.ui.broll.usersToCredit);

  return (
    <Modal
      fullWidth
      title={intl.formatMessage({
        id: 'modals_endProcess_title',
        defaultMessage: 'Process completed!',
      })}
      icon={
        <div className={endModalProcessCss.iconContainer}>
          <IoCheckmarkSharp
            color="white"
            strokeWidth={2}
          />
        </div>
      }
      closeModalFunction={closeModal}
      extraChildren={
        <Suspense fallback={<div />}>
          <ConfettiBackground />
        </Suspense>
      }
      footer={
        <Button
          variant="tertiary"
          fullWidth={false}
          color={colors.primary600}
          onClick={() => {
            setAutocutStore('ui.openedModalName', ModalType.Review);
          }}
        >
          <TranslatedMessage
            id="modals_endProcess_review"
            defaultMessage="Grade AutoCut !"
          />
        </Button>
      }
    >
      <p>
        <TranslatedMessage
          id="modals_endProcess_title"
          defaultMessage="Process finished successfully!"
        />
      </p>
      <FlexContainer
        justifyContent="space-around"
        gap={8}
        alignItems="center"
      >
        <p className={css.creditContainer}>
          <TranslatedMessage
            id={'modals_endProcess_broll_title'}
            defaultMessage={
              'Here are the credits for the B-Rolls used in your video : '
            }
          />
          {usersToCredit.map((user, index) => (
            <p key={user.id}>
              {index + 1} @{user.name} -{' '}
              {intl.formatMessage({
                id: 'modals_endProcess_broll_providedByPexels',
                defaultMessage: 'provided by Pexels',
              })}
              (
              <span
                className={css.link}
                onClick={async () => {
                  await preload().electron.openLinkInBrowser(user.url);
                }}
              >
                <TranslatedMessage
                  id={'modals_endProcess_broll_link'}
                  defaultMessage={'link'}
                />
              </span>
              )
            </p>
          ))}
        </p>
        <Button
          variant="secondary"
          color={colors.primary600}
          className={css.copyButton}
          onClick={async () => {
            if (hideTimeout.current) {
              clearTimeout(hideTimeout.current);
            }
            preload().electron.setClipboard(
              usersToCredit
                .map(
                  (user, index) =>
                    `${index + 1} @${user.name} - ${intl.formatMessage({
                      id: 'modals_endProcess_broll_providedByPexels',
                      defaultMessage: 'provided by Pexels',
                    })} (${user.url})`,
                )
                .join('\n'),
            );
            setCopied(true);
            hideTimeout.current = setTimeout(() => {
              setCopied(false);
            }, 1000);
          }}
        >
          <FlexContainer
            alignItems="center"
            gap="8px"
          >
            <IoCopyOutline size={16} />
            <TranslatedMessage
              id={'modals_endProcess_broll_copyToClipboard'}
              defaultMessage={'Copy to clipboard'}
            />
            <span
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                borderRadius: 8,
                height: '100%',
                backgroundColor: '#15151c',
                fontSize: '0.8em',
                opacity: copied ? 0.85 : 0,
                transition: copied ? 'opacity 0.15s' : 'opacity 0.3s',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {intl.formatMessage({
                id: 'globals_copied',
                defaultMessage: 'Copied',
              })}
            </span>
          </FlexContainer>
        </Button>
      </FlexContainer>

      <p>
        <TranslatedMessage
          id="modals_endProcess_feedback"
          defaultMessage="We value your feedback on the results:"
        />
      </p>
    </Modal>
  );
};

import type {XMLDocument} from './_XMLDocument';
import type {XMLSequence} from './_XMLSequence';

import './_prototypes';
import {XMLTrackItem} from './_XMLTrackItem';

export class XMLTrack {
  private objectUId: string;
  private parentSequence: XMLSequence;
  private tagName: 'VideoClipTrack' | 'AudioClipTrack';
  private track: Element;
  public parentDocument: XMLDocument;

  constructor(track: Element, sequence: XMLSequence) {
    this.track = track;
    this.objectUId = track.getAttribute('ObjectUID') ?? '';
    this.parentDocument = sequence.parentDocument;
    this.parentSequence = sequence;

    const tagName = track.tagName;
    if (!(tagName === 'VideoClipTrack' || tagName === 'AudioClipTrack')) {
      throw new Error(
        'Error when creating XMLTrack : the track selected is not a video or audio track',
      );
    }
    this.tagName = tagName;
  }

  public exists() {
    return !!this.parentDocument.findElementWithObjectId(
      [this.tagName],
      this.objectUId,
    );
  }

  public getClip(objectID?: string) {
    const trackItem = this.track.querySelector(
      `TrackItem[ObjectRef="${objectID}"]`,
    );

    if (!trackItem)
      throw new Error(
        `Error when getting clip : no clip found with objectID ${objectID}`,
      );

    return new XMLTrackItem({trackItem, track: this});
  }

  public getClips() {
    const trackItems = this.track.querySelectorAll('TrackItem');

    const clips = Array.from(trackItems).map(trackItem => {
      return new XMLTrackItem({trackItem, track: this});
    });

    return clips;
  }

  public getLastTrackItem() {
    const lastTrackItem = this.track.querySelector(
      'ClipTrack ClipItems TrackItems TrackItem:last-child',
    );

    const objectRef = lastTrackItem?.getAttribute('ObjectRef');

    return {lastTrackItem, objectRef};
  }
}

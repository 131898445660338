import {setAutocutStore} from '@autocut/utils/zustand';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Input} from '@autocut/designSystem/components/atoms/Input/Input';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';

export const BRollsSizeStep = () => {
  const {minimumBRollTime, maximumBRollTime} = useAutoCutStore(
    state => state.ui.parameters.broll,
  );

  return (
    <FormSection
      title={
        <TranslatedMessage
          id="modes_broll_steps_parameters_duration_title"
          defaultMessage="B-Rolls durations"
        />
      }
    >
      <Input
        label={{
          text: (
            <Text variant="textSm.semibold">
              <TranslatedMessage
                id="modes_broll_steps_parameters_duration_minimumDuration"
                defaultMessage="Minimum duration"
              />
            </Text>
          ),
          helper: (
            <TranslatedMessage
              id="modes_broll_steps_parameters_duration_minimumDurationHelper"
              defaultMessage="Each B-Roll will be at least this long."
            />
          ),
        }}
        unit={
          <TranslatedMessage
            id="modes_podcast_steps_customization_steps_cameraTime_maximumCamTime_unit"
            defaultMessage="seconds"
          />
        }
        value={minimumBRollTime}
        onChange={value => {
          const numberValue = Number(value);
          const actualValue = isNaN(numberValue) ? 0 : numberValue;
          setAutocutStore('ui.parameters.broll.minimumBRollTime', actualValue);
        }}
      />
      <Input
        label={{
          text: (
            <Text variant="textSm.semibold">
              <TranslatedMessage
                id="modes_broll_steps_parameters_duration_maximumDuration"
                defaultMessage="Maximum camera time"
              />
            </Text>
          ),
          helper: (
            <TranslatedMessage
              id="modes_broll_steps_parameters_duration_maximumDurationHelper"
              defaultMessage="Each B-Roll will be at most this long."
            />
          ),
        }}
        unit={
          <TranslatedMessage
            id="modes_podcast_steps_customization_steps_cameraTime_maximumCamTime_unit"
            defaultMessage="seconds"
          />
        }
        value={maximumBRollTime}
        onChange={value => {
          const numberValue = Number(value);
          const actualValue = isNaN(numberValue) ? 0 : numberValue;
          setAutocutStore('ui.parameters.broll.maximumBRollTime', actualValue);
        }}
      />
    </FormSection>
  );
};

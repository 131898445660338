import {CaptionsPreset} from '@autocut/validationSchemas/caption.validationSchema';
import React from 'react';
import {useHits} from 'react-instantsearch';
import {CaptionsPresetCard} from '../CaptionsPresetCard/CaptionsPresetCard';

export const PRESETS_PER_LINE = 3;

export const PresetsGrid = ({
  onSelect,
  selectedPreset,
}: {
  onSelect: (preset: CaptionsPreset | null) => void;
  selectedPreset: CaptionsPreset | null;
}) => {
  const {items} = useHits<CaptionsPreset>();

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${PRESETS_PER_LINE}, 1fr)`,
        columnGap: 8,
        rowGap: 8,
        gap: 8,

        width: '100%',
        height: 'fit-content',
      }}
    >
      {items.map((preset, index) => (
        <CaptionsPresetCard
          key={index}
          preset={preset}
          onClick={async () => {
            if (selectedPreset?.id === preset.objectID) {
              onSelect(null);
              return;
            }
            onSelect({...preset, id: preset.objectID});
          }}
          withUsageCount={true}
          isSelected={preset.objectID === selectedPreset?.id}
        />
      ))}
    </div>
  );
};

import {
  ModeTitle,
  ModeTitleProps,
} from '@autocut/components/ModeTitle/ModeTitle';
import * as React from 'react';
import {IconType} from 'react-icons';

export type ModeWrapperProps = {
  Icon?: IconType;
  text: string;
  children: React.ReactNode;
  customHomeButtonHandler?: ModeTitleProps['customHomeButtonHandler'];
};

export const ModeWrapper = ({
  Icon,
  text,
  children,
  customHomeButtonHandler,
}: ModeWrapperProps) => {
  return (
    <>
      <ModeTitle
        Icon={Icon}
        text={text}
        customHomeButtonHandler={customHomeButtonHandler}
      />
      <div className="dashboardContainer">{children}</div>
    </>
  );
};
